<template>
    <v-container style="min-height: 40vh;">
      <v-card elevation="1">
        <v-card-title class="text-h4 justify-center orange--text">Regístrate para acceder a este contenido</v-card-title>
        <v-card-text class="text-subtitle-1 text-center">
          Este contenido es exclusivo para usuarios registrados, no tiene costo, solo regístrate o inicia sesión en los siguientes enlaces:
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="orange lighten-2"
            text
            to="/registrar"
          >
            Regístrate
          </v-btn>
          <v-btn
            color="green lighten-2"
            text
            to="/login"
          >
            Iniciar sesión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    </template>
    <script>
    export default {
        name: "Bloqueo",
        data: () => ({
            placeholder: ""
        })
    }
    </script>
    