<style scoped>
</style>
<template>
  <div style="overflow-x: hidden;" >
    <div class="pa-4">
         <v-row no-gutters style="min-height: 80vh">
      <v-col
        cols="12"
        sm="12"
        class="grey--text d-flex align-center justify-center py-6"
      >
        <h1>Productos y Servicios</h1>
      </v-col>
      <v-col
        cols="12"
        md="4"
      ></v-col>
      <v-col
        cols="12"
        md="8"
        class="text-right"
      >
        <v-btn
          @click="dialog = !dialog"
          to="/misProductosServicios"
          class="white--text place-btn outlined orange mb-4"
          style="z-index: 2;"
        >
          Ver mis productos / servicios
        </v-btn>

      </v-col>
      <v-col cols="12" sm="2" class="centrar">
        <div
          v-for="(filter, ind) in filters"
          :key="`filter${ind}`"
          class="filtro"
        >
          {{ filter.name }}
          <v-btn
            icon
            color="#ff670a"
            @click="
              () => {
                filter.current += 1;
                sortProductos(filter.attr, filter.current % 3);
              }
            "
          >
            <v-icon v-if="filter.current % 3 == 0">mdi-circle-small</v-icon>
            <v-icon v-if="filter.current % 3 == 1">mdi-chevron-up</v-icon>
            <v-icon v-if="filter.current % 3 == 2">mdi-chevron-down</v-icon>
          </v-btn>
        </div>
        <br />
        <v-row class="d-flex justify-end">
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              label="Precio mínimo"
              :min="0"
              :max="maxPrice"
              v-model="minPrice"
              @change="filterPriceProductos"
              dense
            ></v-text-field>
            <v-text-field
              type="number"
              label="Precio máximo"
              :min="minPrice"
              v-model="maxPrice"
              @change="filterPriceProductos"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10">
        <v-row class="d-flex px-10" style="max-height: 80vh; overflow: scroll" v-if="productos.length > 0">
          <v-col
            v-for="(producto, n) in productos"
            :key="n"
            class="d-flex child-flex"
            cols="12"
            sm="12"
            md="2"
            lg="2"
          >
            <v-card elevation="0" tile flat >
              <router-link :to="`/productoSeleccionado/${producto.id}`">
                <v-img
                  :src="`https://agenciaweb.mx/agronome/${producto.imagenes[0].name}`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="d-inline-block text-truncate"
                      style="max-width: 200px; font-size: 14px"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ producto.producto }}
                    </span>
                  </template>
                  <span>{{ producto.producto }}</span>
                </v-tooltip>
              </v-card-title>
              <v-card-subtitle class="text-truncate">
                {{ producto.descripcion }} <br />
                <div v-if="producto.unidad != undefined && producto.precio > 1">
                  ${{ producto.precio + " /" + producto.unidad }}
                </div>
                <div v-else>
                  <div v-if="producto.precio > 1">
                    ${{ producto.precio }}
                  </div>
                  <div v-else></div>
                </div>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex px-10" style="max-height: 80vh;" v-else>
          <v-col cols="12">
            <v-card elevation="0">
            <v-card-title class="justify-center orange--text">
              No se encontraron resultados
            </v-card-title>
          </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="2" class="centrar" v-if="false"> Anuncios </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      color="green darken-1 white--text"
      absolute
      right
      top
    >
      {{ snackbar.text }}

    </v-snackbar>
    </div>
    <Footer />
  </div>
</template>
<style scoped>
.test {
  border: solid 2px #f00;
}
.centrar {
  text-align: center;
}
.filtro {
  text-align: right;
  display: block;
}
.myImg {
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
}
.titulo {
  color: #ff670a;
}
.imgDiv {
  margin: 0;
  padding: 0;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  padding: 4px;
}
.img-row {
  width: 50px;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}
.errorImagenes {
  color: #a00;
}
</style>
<script>
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
import { generateRandomId } from "../../tools/randomStrGus";
import axios from "axios";
import draggable from 'vuedraggable';
import Footer from "../Footer.vue";
export default {
  name: "Productos",
  components: {Footer, draggable,},
  data: () => ({
    valid: false,
    dialog: false,
    imagesError: false,
    idProducto: null,
    enabled: true,
    list: [],
    arrayImagenes: [],
    rules: {
      producto: [
        (v) => !!v || "El nombre del producto / servicio es requerido",
        (v) =>
          (v && v.length >= 10) ||
          "El nombre del producto / servicio debe tener un mínimo de 10 caracteres",
      ],
      proveedor: [
        (v) => !!v || "El proveedor es requerido",
      ],
      unidad: [
        (v) => !!v || "La unidad es requerida",
      ],
      precio: [
        (v) => !!v || "El precio es requerido",
      ],
      descripcion: [
        (v) => !!v || "La descripción es requerida",
        (v) =>
          (v && v.length >= 30) ||
          "La descripcion debe tener un mínimo de 30 caracteres",
      ]
    },
    items: [
      {
        src: require("../../assets/FORMULARIO-TU-PLANTA-ENFERMA.png"),
      },
    ],
    snackbar: {
      open: false,
      text: "¡Producto enviado!",
      timeout: 2000,
    },
    filters: [
      /*{
        name: "UBICACIÓN",
        attr: "ubicacion",
        current: 0,
        activo: 0,
      },
      {
        name: "TAMAÑO",
        attr: "tamano",
        current: 0,
        activo: 0,
      },
      {
        name: "CULTIVO",
        attr: "cultivo",
        current: 0,
        activo: 0,
      },*/
    ],
    minPrice: 0,
    maxPrice: 0,
    producto: {
      activo: "Activo"
    },
    productos: [
      {
        descripcion:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        ubicacion: "",
        tamano: 0,
        correo: "correo@prueba.com",
        tel: "1234567890",
        imagenes: [
          "https://picsum.photos/200",
          "https://picsum.photos/201",
          "https://picsum.photos/202",
        ],
      },
    ],
    productosBackup: [],
    responseProductos: [],
    usuarios: [],
    myUser: []
  }),
  created() {
    this.initialize();
  },
  computed: {
    userMail() {
      let nombreMail = localStorage.getItem("mail");
      return this.usuarios.length != 0 | this.usuarios.length != undefined ? this.myUser = this.usuarios.filter(user => user.correo == nombreMail) : this.myUser = [];
    },
    allow() {
      return localStorage.getItem("status");
    },
    isProvider() {
      return false;
    }
  },
  methods: {
    initialize() {
      this.getProductos();
      this.getUsuarios();
    },
    sortProductos(attr, sentido) {
      if (sentido == 0) {
        this.productos = [...this.productosBackup];
        return;
      } else if (sentido == 1) {
        this.productosBackup = [...this.productos];
        this.productos.sort(function (a, b) {
          if (a[attr] < b[attr]) {
            return -1;
          }
          if (a[attr] > b[attr]) {
            return 1;
          }
          return 0;
        });
      } else if (sentido == 2) {
        this.productos.reverse();
        return;
      }
    },
    filterPriceProductos() {
      if(this.maxPrice == "" && this.maxPrice == "") {
          this.productos = this.responseProductos;
      };
      if (parseInt(this.maxPrice) == 0) {
        this.productos = this.responseProductos;
        return;
      }
      this.productos = this.responseProductos.filter((producto) => {
        if (
          parseInt(producto.precio) >= parseInt(this.minPrice) &&
          parseInt(producto.precio) <= parseInt(this.maxPrice)
        ) {
          return true;
        }
        return false;
      });
    },
    async subirImagenes(){
      if(this.arrayImagenes.length == 0){
          //document.getElementById("zonaImagenes").innerHTML = "";
        } else {
          let formData = new FormData();
          for (let imagen of this.arrayImagenes) {
            formData.append("file", imagen);
            this.respuesta = await axios.post(
              "https://agenciaweb.mx/agronome/upload-image.php",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            ).then(function(response){
              if(response.data.image == ''){
                console.log(response.data.message);
              } else {
                console.log(response.data.image);
                console.log(response.data.oldimage);
                localStorage.setItem('oldNombreImagen', response.data.oldimage);
                localStorage.setItem('nombreImagen', response.data.image);
                //document.getElementById("imagenCargada").innerHTML = `<img src='https://www.agenciaweb.mx/agronome/${nombreImagen}' style="width: 70px;">`;
              }
            });
            this.objectImage = {
              name: localStorage.getItem('nombreImagen'),
              originalName: localStorage.getItem('oldNombreImagen'),
            }
            this.list.push(this.objectImage);
            this.imagesError = false;
            document.getElementById('errorImagenes').innerHTML = "";
          };
          this.arrayImagenes = [];
        }
    },
    async getProductos() {
      let db = firebase.firestore();
      await db
        .collection("productos")
        .where("eliminado", "==", false)
        .where("status", "==", "Activo")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) =>
            this.responseProductos.push(doc.data())
          );
        });
      this.productos = this.responseProductos;
    },
    async getUsuarios() {
      let db = firebase.firestore();
      await db
        .collection("usuarios")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) =>
            this.usuarios.push(doc.data())
          );
        });
    },
    async addProducto() {
      this.isDisabled = true;
      this.$refs.form.validate();
      if (this.valid == false) {
        this.isDisabled = false;
      }
      if (this.list.length == 0) {
        document.getElementById('errorImagenes').innerHTML = "<p style='color: #FF5252;'>Seleccione una(s) imagen(es) para la parcela</p>";
        this.imagesError = true;
        this.isDisabled = false;
      } else {
        this.producto.imagenes = this.list;
        this.producto.proveedor = this.userMail;
        this.producto.id = generateRandomId();
        this.producto.eliminado = false;
        let db = firebase.firestore();
        await db.collection("productos").add(this.producto).then(
          this.snackbar.open = true,
        );
        this.responseProductos = [];
        this.getProductos();
        this.dialog = false;
      }  
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    }
  },
};
</script>

