<template>
  <div style="overflow-x: hidden;">
    <v-snackbar 
        v-model="snackbar.open"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        fixed
        right
        top
      >
        <center>
          {{ snackbar.text }}
        </center>
    </v-snackbar>
    <v-row no-gutters style="min-height: 90vh" v-if="allow == 'permitido'">
      <v-col
        cols="12"
        sm="6"
        class="myBanner d-flex align-center justify-center"
      >
        <img :src="items[0].src" alt="Planta" class="myImg" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-container bg fill-height class="pa-0 ma-0">
          <v-layout row wrap align-center>
            <v-row>
              <v-col>
                <form method="post" action="" enctype="multipart/form-data">
                  <div class="pa-lg-16 pl-9 pr-9">
                    <h1 class="titulo">¿TU PLANTA ESTÁ ENFERMA?</h1>
                    <p class="subtitle">
                      Si crees que tu planta está enferma, envíanos una foto donde
                      se aprecien sus hojas. <br />
                      (Consejo: utiliza luz natural)
                    </p>
                    <span class="text-h5" style="font-size: 16px;">Formulario</span>
                    <v-text-field label="Nombre" class="mt-5" readonly dense v-model="userName"/>
                    <v-text-field label="Correo" readonly dense v-model="userMail"/>
                    <v-file-input
                      accept="image/*"
                      truncate-length="15"
                      label="Foto"
                      prepend-icon=""
                      append-icon="mdi-upload"
                      @click:append="upload"
                      v-model="file"
                    />
                    <center>
                      <div class="imagenes" id="imagenCargada"></div>
                    </center>
                    <v-textarea label="Comentario" dense rows="3" v-model="diagnostico.comentario"/>
                  </div>
                  <div class="d-flex justify-end px-lg-16 px-sm-4 mb-12 pr-9">
                    <v-btn tile color="#FF7100" class="white--text" @click="enviar">ENVIAR</v-btn>
                  </div>
                </form>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<style scoped>
.myImg {
  width: 80%;
  border-radius: 10px;
}
.titulo {
  color: #ff670a;
}
.imgDiv {
  margin: 0;
  padding: 0;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import axios from "axios";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "PlantaEnferma",
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    file: null,
    items: [
      {
        src: require("../../assets/FORMULARIO-TU-PLANTA-ENFERMA.png"),
      },
    ],
    busquedaCampo: "",
    diagnostico: {},
    snackbar: {
      open: false,
      text: "¡Texto copiado!",
      timeout: 7000,
    },
  }),
  computed: {
    allow() {
      return localStorage.getItem("status");
    },
    userMail() {
      return localStorage.getItem("mail");
    },
    userName() {
      return localStorage.getItem("name");
    }
  },
  methods: {
    
    openSnackbar(text, time, color) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
      this.snackbar.color = color;
    },

    async upload() {
      if(this.file == null){
        document.getElementById("imagenCargada").innerHTML = "<p style='color: #A00;'>Seleccione una imagen para cargar</p>";
      } else {
        let formData = new FormData();
        formData.append("file", this.file);
        let respuesta = await axios.post(
          "https://agenciaweb.mx/agronome/upload-image.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        ).then(function(response){
          if(response.data.image == ''){
            console.log(response.data.message);
          } else {
            console.log(response.data.image);
            let nombreImagen = response.data.image;
            localStorage.setItem('nombreImagen', response.data.image);
            document.getElementById("imagenCargada").innerHTML = `<img src='https://www.agenciaweb.mx/agronome/${nombreImagen}' style="width: 70px;">`;
          }
        });
      }
    },

    async enviar(){
      //envío de mail
      this.nombreImagen = localStorage.getItem("nombreImagen");
      if(this.file == null || this.nombreImagen == ""){
        console.log("Cargue una imagen para el diagnóstico");
        this.openSnackbar('Cargue una imagen para el diagnóstico, haciendo click en el campo, seleccionando el archivo y haciendo click en el icono de flecha', 7000, 'red darken-1 white--text');;
      } else {
        let formData = new FormData();
        formData.append("nombre", this.userName);
        formData.append("correo", this.userMail);
        formData.append("comentario", this.diagnostico.comentario);
        formData.append("adjunto", this.nombreImagen);
        formData.append("file", this.file);

        let respuesta = await axios.post(
          "https://agenciaweb.mx/agronome/send-mail.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        ).then(function(response){
          if(response.data.image == ''){
            console.log(response.data.message);
          } else {
            console.log(response.data.message);
          }
        });
        localStorage.setItem("nombreImagen", "");
        this.openSnackbar('¡Enviado!', 7000, 'green darken-1 white--text');
        this.file = null;
        document.getElementById("imagenCargada").innerHTML = "";
        this.diagnostico.comentario = "";
        let db = firebase.firestore();
        let user = firebase.auth().currentUser;
        let oldBusqueda = localStorage.getItem("busquedaCampo");
        let query = "Nombre: " + this.userName + " Email: " + this.userMail + " Comentarios: " + this.diagnostico.comentario;
        if(query != "" && query != oldBusqueda){
          let record = {
          usuario: this.userMail,
          operacionRealizada: "Envío de formulario diagnóstico",
          origen: "Sección: Planta enferma",
          busquedaCompleta: query
        };
        this.busquedaCampo = localStorage.setItem("busquedaCampo", query);
        db.collection("operaciones").add(record);
      }
      }
    }
  }
};
</script>
