<template>
  <div style="overflow-x: hidden;">
    <v-row no-gutters style="min-height: 100vh">
      <v-col cols="12" sm="6" style="background-color: #fff">
        <v-container bg fill-height grid-list-md>
          <v-layout row wrap align-center>
            <v-row>
              <div class="pa-lg-16 pa-4">
                <h1>¡BIENVENIDO A TU PORTAL!</h1>
                <p class="subtitle">
                  El sector agropecuario es base fundamental para los países, por eso queremos que tengas la información que necesitas para tomar mejores decisiones.  
                  <br/><br/>Te invitamos a registrarte y a que revises nuestra información y contenido.  
                  Estamos esforzándonos para seguir consiguiendo más información relevante para ti, si necesitas algún dato en especial, escríbenos <a href="mailto:hola@agronome.red">aquí</a> y nosotros te lo buscamos.
                  <br/><br/> 
                  Pronto contaremos con mapas de temperaturas, lluvias y de productividad para que no te olvides de registrar la <u>geo-referencia</u> de tus parcelas
                </p>
              </div>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="white--text myBanner"
        :style="{
          'background-image': 'url(' + items[0].src + `)`,
        }"
      ></v-col>
    </v-row>
  </div>
</template>
<style scoped>
.imgDiv {
  margin: 0;
  padding: 0;
}
.test {
  border: solid 1px #f00;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
export default {
  name: "Informacion",
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-2.png"),
      },
    ],
  }),
};
</script>