<style scoped>
.titleGrey {
  color: #959595;
}
</style>
<template>
  <div>
    <v-snackbar 
        v-model="snackbar.open"
        :timeout="snackbar.timeout"
        color="red darken-1 white--text"
        absolute
        right
        top
      >
        <center>
          {{ snackbar.text }}
        </center>
    </v-snackbar>
    <v-container v-if="allow == 'permitido'">
      <h1 class="titleGrey text-center">Precios Agrícolas</h1>
      <br />
      <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
                v-model="headers[0].search"
                :items="productosOrdenados"
                dense
                label="Producto"
              ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-autocomplete
                v-model="headers[2].search"
                :items="estadosOrdenados"
                dense
                label="Origen (Requerido)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="4" class="text-center">
          <v-btn
          min-width="200px"
          class="orange lighten-2 white--text"
          @click="cultivosFiltrados"
          >
            Filtrar
          </v-btn>
        </v-col>
        </v-row>
      </v-container>
        <v-data-table
        :headers="headers"
        :items="filtrados"
        :items-per-page="5"
        fixed-header
        >
          <template slot="no-data">
            <span style="color: #FF5252;">No hay registros disponibles para tu búsqueda</span>
          </template>
        </v-data-table>
        </v-card
      ><br />
    </v-container>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import { matchStrings } from "../../tools/proStrings";
import moment from "moment";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "CostosCultivos",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    newItem: "",
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      password: [
        (v) => !!v || "El contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener un mínimo de 8 caracteres",
      ],
      phone: [],
      municipality: [],
      city: [],
      state: [],
      ocupacion: [],
      ganado: [],
      cultivos: [],
    },
    productosSinOrdenar: [],
    estadosSinOrdenar: [],
    busquedaCampo: "",
    snackbar: {
      open: false,
      text: "¡Texto copiado!",
      timeout: 2000,
    },
    filtrados: [],
    headers: [
      { text: "PRODUCTO", value: "producto", search: "", class: "orange--text" },
      { text: "TIPO PRODUCTO", value: "categoria", search: "", class: "orange--text" },
      { text: "ORIGEN", value: "origen", search: "", class: "orange--text" },
      {
        text: "PRESENTACIÓN COMERCIAL",
        value: "presentacion",
        search: "",
        class: "orange--text"
      },
      { text: "PRECIO MÁXIMO", value: "precioMax", search: "", class: "orange--text" },
      { text: "PRECIO MÍNIMO", value: "precioMin", search: "", class: "orange--text" },
      { text: "PRECIO FRECUENTE", value: "precioFrec", search: "", class: "orange--text" },
      { text: "FECHA", value: "fecha", search: "", class: "orange--text" },
    ],
    commodities: [],
    commoditiesHoy: [],
  }),
  computed: {
    cultivosFiltrados1() {
      this.commodities.map((item, i) => {
        if (this.commodities[i].IngresoProbable != undefined)
          this.commodities[i].IngresoProbable =
            this.commodities[i].IngresoProbable.toFixed(2);
      });
      this.filtrados = this.commodities;
      this.filtrados = this.filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      return this.filtrados;
    },
    allow() {
      return localStorage.getItem("status");
    },
    productosOrdenados(){
      if(this.commoditiesHoy.length > 0) {
        this.productosSinOrdenar.push("");
        return this.productosSinOrdenar.sort();
      } else {
        return this.commodities;
      }
    },
    estadosOrdenados(){
      if(this.commoditiesHoy.length > 0) {
        this.estadosSinOrdenar.push("");
        return this.estadosSinOrdenar.sort();
      } else {
        return this.commodities;
      }
    }
  },

  methods: {
    initialize() {
      this.getCommodities();
    },
    cultivosFiltrados() {
      this.filtrados = this.commoditiesHoy;
      if(this.headers[0].search == "" ){
        this.filtrados = [];
        this.openSnackbar('Ingrese el cultivo ', 2000);
      } else {
        this.filtrados = this.filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.value] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      let db = firebase.firestore();
      let user = firebase.auth().currentUser;
      let oldBusqueda = localStorage.getItem("busquedaCampo");
      let query = this.headers[0].search + " en " + this.headers[2].search;
      let myTime = moment().format("DD-MM-YYYY HH:mm:ss");
      if(query != "" && query != oldBusqueda){
        let record = {
        usuario: user.email,
        operacionRealizada: "Busqueda por filtrado",
        origen: "Tabla: Commodities",
        busquedaCompleta: query,
        timestamp: myTime
      };
      this.busquedaCampo = localStorage.setItem("busquedaCampo", query);
      db.collection("operaciones").add(record);
      }
      return this.filtrados;
      }
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
    async getCommodities() {
      // Day name
          let todaysName = moment().format('dddd');
          let todaysDate = "";
          if (todaysName != "Saturday" && todaysName != "Sunday"){
              todaysDate = moment().format("DD/MM/YYYY");
          } else {
            if (todaysName == "Saturday"){
              //Un día atrás
              let filterDate = moment();
              filterDate = filterDate.subtract(1, "days");
              todaysDate = filterDate.format("DD/MM/YYYY");
            } else {
              // Dos días atrás
              let filterDate = moment();
              filterDate = filterDate.subtract(2, "days");
              todaysDate = filterDate.format("DD/MM/YYYY");
            }
          }
      let db = firebase.firestore();
      let commoditiesAlmacenados = sessionStorage.getItem('commodities');
      if((commoditiesAlmacenados != "" && commoditiesAlmacenados != undefined) && commoditiesAlmacenados != "[]") {
        this.commoditiesHoy = JSON.parse(sessionStorage.getItem('commodities'));
        if(this.commoditiesHoy[0].fecha == todaysDate) {
          this.commoditiesHoy.forEach( item => this.productosSinOrdenar.push(item.producto));
          this.commoditiesHoy.forEach( item => this.estadosSinOrdenar.push(item.origen));
        } else {
          await db
        .collection("commodities")
        .where("fecha", "==", todaysDate)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.commoditiesHoy.push(doc.data())); 
          this.commoditiesHoy.forEach( item => this.productosSinOrdenar.push(item.producto));
          this.commoditiesHoy.forEach( item => this.estadosSinOrdenar.push(item.origen));
        });
        let stringPreciosDiarios = JSON.stringify(this.commoditiesHoy);
        sessionStorage.setItem('commodities', stringPreciosDiarios);
        }
      } else {
          await db
          .collection("commodities")
          .where("fecha", "==", todaysDate)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => this.commoditiesHoy.push(doc.data())); 
            this.commoditiesHoy.forEach( item => this.productosSinOrdenar.push(item.producto));
            this.commoditiesHoy.forEach( item => this.estadosSinOrdenar.push(item.origen));
          });
          let stringPreciosDiarios = JSON.stringify(this.commoditiesHoy);
          sessionStorage.setItem('commodities', stringPreciosDiarios);

          if(this.commoditiesHoy[0] == undefined){
            let filterDate = moment();
            filterDate = filterDate.subtract(1, "days");
            todaysDate = filterDate.format("DD/MM/YYYY");

            await db
            .collection("commodities")
            .where("fecha", "==", todaysDate)
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((doc) => this.commoditiesHoy.push(doc.data())); 
              this.commoditiesHoy.forEach( item => this.productosSinOrdenar.push(item.producto));
              this.commoditiesHoy.forEach( item => this.estadosSinOrdenar.push(item.origen));
            });
            let stringPreciosDiarios = JSON.stringify(this.commoditiesHoy);
            sessionStorage.setItem('commodities', stringPreciosDiarios);
          }
       }   
    },
  },
};
</script>

