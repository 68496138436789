<style scoped>
    .city {
        font-size: 45px;
        vertical-align: top;
    }
    .instrucciones {
        width: 95%; 
        max-width: 600px; 
        margin: 0px auto;
    }
</style>
<template>
    <div>
        <div v-if="allow == 'permitido'">
            <v-row style="margin: 5px;" v-if="weatherPrecise.main">
            <v-col cols="12" lg="4" md="4" sm="12">
                <v-card>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="text-h4">
                                {{ weatherPrecise.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle> <span>{{ date | moment  }}</span> {{ weatherPrecise.weather[0].main }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-card-text>
                        <v-row align="center">
                            <v-col
                            class=" city"
                            cols="6"
                            >
                            {{ Math.round(weatherPrecise.main.temp) }}°C
                            </v-col>
                            <v-col cols="6">
                            <v-img
                                :src="`${this.imageUrlBase}${this.weatherPrecise.weather[0].icon}@2x.png`"
                                alt="Weather"
                                width="92"
                            ></v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="8" md="8" sm="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" lg="3" md="3" sm="12" v-for="(day, index) in weather.daily" :key="index">
                                <v-card-title v-if="day">{{ day.temp.nombreDia }}</v-card-title>
                                <v-card-text v-if="day">
                                    <p>Máx: {{ Math.round(day.temp.max) }} °C</p>
                                    <p>Min: {{ Math.round(day.temp.min) }} °C</p>
                                    <p v-if="day.rain">Prob. lluvia: {{ Math.round(day.rain) }} %</p>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" >
                    <v-container>
                        <v-card min-height="35vh">
                            <v-card-title class="text-h4 justify-center orange--text">El clima no pudo ser obtenido</v-card-title>
                            <v-card-text class="text-subtitle-1 text-align-center">
                                <div class="instrucciones" v-if="isChrome && !isAndroid">
                                    Para acceder a esta función es necesario conceder tu ubicación a este sitio.
                                    <br>
                                    Cómo cambiar la configuración de tu ubicación predeterminada
                                    <br>
                                    1. En la computadora, abre <v-icon>mdi-google-chrome</v-icon> 
                                    <br>
                                    2. En la esquina superior derecha, haz clic en Más <v-icon>mdi-dots-vertical</v-icon> <v-icon>mdi-chevron-right</v-icon> Configuración.
                                    <br>
                                    3. En "Privacidad y seguridad", haz clic en Configuración de sitios y elige www.agronome.red.
                                    <br>
                                    4. Haz clic en Ubicación.
                                    <br>
                                    5. Selecciona la opción que deseas como tu configuración predeterminada.
                                    <br>
                                    6. Crl y click en recargar el sitio.
                                </div>
                                <div class="instrucciones" v-if="isAndroid && isChrome">
                                    <br>
                                    1. En tu teléfono o tablet Android, abre la app de Chrome <v-icon>mdi-google-chrome</v-icon> 
                                    <br>
                                    2. A la derecha de la barra de direcciones, presiona Más <v-icon>mdi-dots-vertical</v-icon> <v-icon>mdi-chevron-right</v-icon> Configuración.
                                    <br>
                                    3.Presiona Configuración del sitio <v-icon>mdi-chevron-right</v-icon> Ubicación.
                                    <br>
                                    4. Activa o desactiva la Ubicación.
                                    <br>
                                    5. Selecciona la opción que deseas como tu configuración predeterminada.
                                    <br>
                                    6. Recargar el sitio.
                                </div>

                                <div class="instrucciones" v-if="isIOS">
                                    <br>
                                    1. En tu iPhone o iPad, abre la app de Configuración.
                                    <br>
                                    2. Busca y presiona Chrome/Safari.
                                    <br>
                                    3. Presiona Ubicación.
                                    <br>
                                    4. Selecciona la opción que deseas como tu configuración predeterminada.
                                    <br>
                                    5. Recargar el sitio.
                                </div>

                            </v-card-text>                            
                        </v-card>
                    </v-container>
                </v-col>
            </v-row>
        <div v-if="typeof weather.main != 'undefined'">
        </div>
        </div>
        <Bloqueo v-else/>
        <Footer />
    </div>
</template>
<script>
import moment from "moment";
import Bloqueo from "../Bloqueo.vue";
import Footer from "../Footer.vue";
export default {
    name: "Clima",
    data: () => ({
        apiKey: "981f9ed11f354ec37864394a18c6dd99",
        urlBase: "https://api.openweathermap.org/data/2.5/",
        imageUrlBase: "http://openweathermap.org/img/wn/",
        query: "Mérida",
        lat:"20.9667",
        lon:"-89.6167",
        date: "",
        diaHoy: "",
        weather: {},
        weatherPrecise: {}    
    }),
    created() {
        this.initialize()
    },
    components: {
        Footer,
        Bloqueo
  },
    computed:{
        dia(){
            return moment().locale('es-ES').format('dddd')
        },
        latitude(){
            return sessionStorage.getItem('latitude');
        },
        longitude(){
            return sessionStorage.getItem('longitude');
        },
        allow() {
            return localStorage.getItem("status");
        },
        isChrome() {
            if(navigator.userAgent.toLowerCase().indexOf('chrome') > -1){
                return true;
            } else {
                return false;
            }
        },
        isAndroid() {
            if(navigator.userAgent.match(/Android/i)) {
                return true;
            } else {
                return false;
            }
        },
        isIOS() {
            if((navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) || navigator.userAgent.match(/iPod/i)) {
                return true;
            } else {
                return false;
            }
        },

        },
    methods: {
        fetchWeather () {
            if (this.latitude != null && this.longitude != null) {
                fetch(`${this.urlBase}onecall?lat=${this.latitude}&lon=${this.longitude}&units=metric&lang=es&APPID=${this.apiKey}`)
                .then(res => {
                    return res.json();
                }).then(this.setResults);
            }
        },
        setResults(results){
            this.weather = results;
            for(var i = 0; i < this.weather.daily.length; i++){
                let index = i + 1;
                this.weather.daily[i].temp.nombreDia = moment().locale('es-Es').add(index, 'days').format('dddd');
            }
   
        },
        preciseWeather () {
            if (this.latitude != null && this.longitude != null) {
                fetch(`${this.urlBase}weather?lat=${this.latitude}&lon=${this.longitude}&units=metric&APPID=${this.apiKey}`)
                .then(res => {
                    return res.json();
                }).then(this.setResultsPrecise);
            }
        },
        createDays(){
            let counter = 1;
            forE
            this.diaHoy = moment().locale('es-Es').add(counter, 'days').format('dddd');
            this.arrayDias.push(diaHoy);
        },
        setResultsPrecise(results){
            this.weatherPrecise = results;
        },
        initialize(){
            this.fetchWeather();
            this.preciseWeather();
        }
    },
    filters: {
    moment: function (date) {
    return moment().locale('es-ES').format('dddd DD MMMM YYYY, h:mm:ss a');
  }
}
}
</script>
