<style scoped>
  .titleGrey{
    color: #959595;
  }
</style>
<template>
    <div>
      <v-snackbar 
        v-model="snackbar.open"
        :timeout="snackbar.timeout"
        color="red darken-1 white--text"
        absolute
        right
        top
      >
        <center>
          {{ snackbar.text }}
        </center>
    </v-snackbar>
      <v-container v-if="allow == 'permitido'">
        <h1 class="titleGrey text-center">Ingresos por Cultivos</h1><br/>
        <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete
                v-model="headers[0].search"
                :items="cultivosOrdenados"
                dense
                label="Cultivo (Requerido)"
              ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
                v-model="headers[1].search"
                :items="estadosOrdenados"
                item-text="estado"
                item-value="estado"
                dense
                label="Ubicación (Requerido)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
                v-model="headers[2].search"
                :items="tecnologiaOrdenados"
                item-text="tecnologia"
                item-value="tecnologia"
                dense
                label="Tecnología"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3" class="text-center">
          <v-btn
          min-width="200px"
          class="orange lighten-2 white--text"
          @click="cultivosFiltrados"
          >
            Filtrar
          </v-btn>
        </v-col>
        </v-row>
      </v-container>
        <v-data-table
        :headers="headers"
        :items="filtrados"
        :items-per-page="5"
        fixed-header
        >
          <template slot="no-data">
            <span style="color: #FF5252;">No hay registros disponibles para tu búsqueda</span>
          </template>
        </v-data-table>
        </v-card
      ><br />
    </v-container>
    <Bloqueo v-else/>
    <Footer />
    </div>
</template>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import moment from "moment";
import { matchStrings } from "../../tools/proStrings";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "IngresosCultivos",
  created() {
    this.initialize();
  },
  components:{
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    newItem: "",
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      password: [
        (v) => !!v || "El contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener un mínimo de 8 caracteres",
      ],
      phone: [],
      municipality: [],
      city: [],
      state: [],
      ocupacion: [],
      ganado: [],
      cultivos: []
    },
    busquedaCampo: "",
    cultivosSinOrdenar: [],
    estadosSinOrdenar: [],
    tecnologiaSinOrdenar: [],
    snackbar: {
      open: false,
      text: "¡Texto copiado!",
      timeout: 2000,
    },
    filtrados: [],
    headers: [
      { text: "CULTIVO", value: "cultivo", search: "", class: "orange--text" },
      { text: "UBICACIÓN", value: "estado", search: "", class: "orange--text" },
      { text: "TECNOLOGÍA", value: "tecnologia", search: "", class: "orange--text" },
      { text: "ZONA", value: "zona", search: "", class: "orange--text" },
      { text: "MODALIDAD", value: "modalidad", search: "", class: "orange--text" },
      { text: "CICLO AÑO", value: "cicloAno", search: "", class: "orange--text" },
      { text: "RENDIMIENTO EN TON.", value: "rendimientoProbableTon", search: "", class: "orange--text" },
      { text: "INGRESO PROBABLE", value: "ingresoProbable", search: "", class: "orange--text" }
    ],
    agrocostos: []
  }),
  computed:{
   cultivosFiltrados1() {
      this.agrocostos.map((item, i) => {
          if(this.agrocostos[i].IngresoProbable != undefined)
          this.agrocostos[i].IngresoProbable = this.agrocostos[i].IngresoProbable.toFixed(2);
      });
      let filtrados = this.agrocostos;
      filtrados = filtrados.filter(hoja => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map(header => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      return filtrados;
    },
   allow() {
      return localStorage.getItem("status");
    },
    cultivosOrdenados(){
      if(this.agrocostos.length > 0) {
        this.cultivosSinOrdenar.push("");
        return this.cultivosSinOrdenar.sort();
      } else {
        return this.cultivosSinOrdenar;
      }
    },
    estadosOrdenados(){
      if(this.agrocostos.length > 0) {
        this.estadosSinOrdenar.push("");
        return this.estadosSinOrdenar.sort();
      } else {
        return this.estadosSinOrdenar;
      }
    },
    tecnologiaOrdenados(){
      if(this.agrocostos.length > 0) {
        this.tecnologiaSinOrdenar.push("");
        return this.tecnologiaSinOrdenar.sort();
      } else {
        return this.tecnologiaSinOrdenar;
      }
    },
  },
  methods: {
    initialize(){
      this.getAgrocostos();
    },
    cultivosFiltrados() {
      this.filtrados = this.agrocostos;
      if(this.headers[0].search == "" || this.headers[1].search == ""){
        this.filtrados = [];
        this.openSnackbar('Ingrese el cultivo y la ubicación', 2000);
      } else {
        this.filtrados = this.filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.value] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      let db = firebase.firestore();
      let user = firebase.auth().currentUser;
      let oldBusqueda = localStorage.getItem("busquedaCampo");
      let query = this.headers[0].search + " en " + this.headers[1].search + " con " + this.headers[2].search;
      let myTime = moment().format("DD-MM-YYYY HH:mm:ss");
      if(query != "" && query != oldBusqueda){
        let record = {
        usuario: user.email,
        operacionRealizada: "Busqueda por filtrado",
        origen: "Tabla: Ingresos cultivos",
        busquedaCompleta: query,
        timeStamp: myTime
      };
      this.busquedaCampo = localStorage.setItem("busquedaCampo", query);
      db.collection("operaciones").add(record);
      }
      return this.filtrados;
      }
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
    async getAgrocostos() {
      let agrocostosAlmacenado = sessionStorage.getItem('agrocostos');
      if(agrocostosAlmacenado != "" && agrocostosAlmacenado != undefined) {
            this.agrocostos = JSON.parse(sessionStorage.getItem('agrocostos'));
            this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
            this.agrocostos.forEach( item => this.estadosSinOrdenar.push(item.estado));
            this.agrocostos.forEach( item => this.tecnologiaSinOrdenar.push(item.tecnologia));
      } else {
        let db = firebase.firestore();
        await db.collection("newAgrocostos").get().then( snapshot => {
          snapshot.docs.forEach(doc => this.agrocostos.push(doc.data()))
        });
        this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
        this.agrocostos.forEach( item => this.estadosSinOrdenar.push(item.estado));
        this.agrocostos.forEach( item => this.tecnologiaSinOrdenar.push(item.tecnologia));
        let parsed = JSON.stringify(this.agrocostos);
        sessionStorage.setItem('agrocostos', parsed);
      }
    }
   }
}
</script>

