<style scoped>
.miBoton {
  border-radius: 10px;
  border: solid #fea100 2px;
  font-size: 14px;
  height: 200px;
}
</style>
<template>
  <div>
    <v-row justify="center">
    <v-dialog
      v-model="isRecoveryModalOpen"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Recuperar contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
              <p class="text-align: center;">
                Si has olvidado tu contraseña de tu cuenta, 
                escribe en el campo siguiente tu correo y te notificaremos a esa 
                dirección para el reestablecimiento de tu contraseña.</p>
                <v-text-field
                  label="Correo de la cuenta"
                  v-model="correoCuenta"
                  required
                ></v-text-field>
                <span id="errorText">{{ mensajeCuenta }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="isRecoveryModalOpen = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="checarCuenta"
          >
            Recuperar contraseña
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    <v-row>
      <v-col lg="5" md="5" sm="12">
        <div class="container">
          <v-form ref="form" v-model="valid">
            <v-col cols="8">
              <h1>COMIENZA</h1>
              <h1>HOY MISMO</h1>

              <p>
                En nuestro sitio encontrarás toda la información que necesitas.
              </p>

              <h2>Inicia sesión</h2>
              <v-text-field
                class="pt-3"
                v-model="user.name"
                label="Correo electrónico"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="user.password"
                type="password"
                label="Contraseña"
                outlined
                required
              ></v-text-field>

              <a class="ma-2" href="javascript:void(0)" @click="isRecoveryModalOpen = !isRecoveryModalOpen">
                ¿Olvidaste tu contraseña? Recuperala aquí</a><br><br>

              <v-btn class="miBoton" @click="logIn">Iniciar sesión</v-btn>
            </v-col>
          </v-form>
        </div>
      </v-col>
      <v-col lg="7" md="7" sm="12">
        <v-img :src="require('../../assets/loginimg.jpg')" height="100vh" />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      fixed
      right
      top
    >
      {{ snackbar.text }}

    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

export default {
  name: "Login",
  data: () => ({
    drawer: null,
    valid: false,
    isRecoveryModalOpen: false,
    newItem: "",
    correoCuenta: "",
    mensajeCuenta: "",
    user: {
      name: "",
      password: "",
    },
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
    },
    snackbar: {
      open: false,
      text: "",
      color: "green darken-1 white--text",
      timeout: 3500,
    },
    items: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
  methods: {
    async logIn() {
      try {
        const val = await firebase
          .auth()
          .signInWithEmailAndPassword(this.user.name, this.user.password);
        //console.log(val);
        localStorage.setItem("isLogged", "Loged");
        this.$router.replace({ name: "Home" });
      } catch (err) {
        this.snackbar.color = "red darken-1 white--text";
        if(err.code == "auth/user-not-found") {
          this.snackbar.text = "El usuario no existe o ha sido eliminado";
        } else if (err.code == "auth/wrong-password") {
          this.snackbar.text = "La contraseña de este usuario es incorrecta";
        } else if(err.code == "auth/network-request-failed") {
          this.snackbar.text = "Error de conexión, es posible que su conexión sea baja o haya exedido el tiempo limite de espera";
        }
        this.snackbar.open = true;
      }
    },
    async checarCuenta() {
      try {
        const val = await firebase
          .auth()
          .signInWithEmailAndPassword(this.correoCuenta, "-");
        //console.log(val);
      } catch (err) {
        if(err.code == "auth/user-not-found") {
          this.mensajeCuenta = "El usuario no existe o ha sido eliminado";
        } else if(err.code == "auth/network-request-failed") {
          this.mensajeCuenta = "Error de conexión, es posible que su conexión sea baja o haya exedido el tiempo limite de espera";
        } else if (err.code == "auth/wrong-password") {
          this.mensajeCuenta = "Enviaremos un correo de verificación a esta dirección";
          this.sendMail(this.correoCuenta);
        }
      }
    },
    sendMail(correoCuenta) {
      const auth = firebase.auth();
      firebase.auth().languageCode = 'es-MX';
      auth.sendPasswordResetEmail(correoCuenta).then(() => {
        //console.log("Email sent");
      }).catch((error) => {
        console.log(error);
      });
    }
  },
};
</script>
