<template>
  <div style="overflow-x: hidden;">
    <v-row no-gutters style="min-height: 100vh">
      <v-col
        cols="12"
        sm="6"
        class="white--text myBanner"
        :style="{
          'background-image': 'url(' + items[0].src + `)`,
        }"
      ></v-col>
      <v-col cols="12" sm="6" style="background-color: #fff">
        <v-container bg fill-height grid-list-md>
          <v-layout row wrap align-center>
            <v-row>
              <div class="pa-lg-16 pa-4">
                <h1>TODA LA INFORMACIÓN QUE NECESITAS SOBRE CULTIVOS</h1>
                <p class="subtitle">
                  Aquí encuentras el costo, el nivel de producción y el precio promedio de tus cultivos.  Esto te sirve para poder comparar lo que genera tu parcela.  
                  <br/><br/>Si tus niveles de producción o tus costos son menores, <a href="https://forms.gle/gCXAJDotzWM7Kixw7" target="_blank" style="color: #fff; font-weight: bold;">mándanos más información para que podamos asesorarte de manera gratuita.</a>
                  <br/><br/>
                  Te damos información de precios, proveedores y logística para que tomes mejores decisiones.  
                  Incluso te asesoramos para que puedas conseguir el seguro que se adapta mejor a tus posibilidades.
                </p>
                <router-link class="orange--text" to="/costosCultivos">Leer más</router-link>
              </div>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.imgDiv {
  margin: 0;
  padding: 0;
}
.test {
  border: solid 1px #f00;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
export default {
  name: "Informacion",
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-4.png"),
      },
    ],
  }),
};
</script>