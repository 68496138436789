<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  padding: 4px;
}
.img-row {
  width: 50px;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}
.errorImagenes {
  color: #a00;
}
</style>
<template>
    <div class="mt-10">
      <v-snackbar 
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      fixed
      right
      top
    >
      <center>
        {{ snackbar.text }}
      </center>
    </v-snackbar>

        <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
    >
      <v-card>
        <v-form ref="form" v-model="valid">
        <v-card-title>
          <span class="text-h5">Agregar Producto / Servicio</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Nombre del producto / Servicio (Requerido)"
                  v-model="producto.producto"
                  :rules="rules.producto"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Proveedor"
                  v-model="myUser[0].nombre"
                  :rules="rules.proveedor"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-file-input
                  id="campoImagenes"
                  accept=".jpg,.jpeg,.png"
                  small-chips
                  multiple
                  label="Subir imágenes"
                  v-model="arrayImagenes"
                  prepend-icon="mdi-camera"
                  :error="imagesError"
                  @change="subirImagenes()"
              ></v-file-input>
              <div id="zonaImagenes">
                <v-row>
                  <div class="col-12">
                    <draggable
                      :list="list"
                      :disabled="!enabled"
                      class="list-group"
                      ghost-class="ghost"
                      :move="checkMove"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <div
                        class="list-group-item"
                        v-for="element in list"
                        :key="element.name"
                        :id="element.name"
                      >
                      <img :src="'https://www.agenciaweb.mx/agronome/' + element.name" class="img-row" alt="">
                        {{ element.originalName}}
                      <span >
                          <v-icon
                            medium
                            color="error"
                            @click="deleteImage(element.name)"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </div>
                    </draggable>
                  </div>
                </v-row>
              </div>
              <div id="errorImagenes" class="errorImagenes">
                <p style='color: #FF5252;'>{{ textImagesError }}</p>
              </div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  :items="['Producto', 'Servicio']"
                  label="Tipo de registro (Requerido)"
                  v-model="producto.tipo"
                  :rules="rules.tipo"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                v-if="producto.tipo == 'Producto'"
              >
                <v-text-field
                  label="Cantidad (Requerido)"
                  type="number"
                  v-model="producto.cantidad"
                  :min="1"
                  :rules="rules.cantidad"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="producto.tipo == 'Producto'"
              >
                <v-select
                  :items="[' ', 'cm', 'gr', 'kg', 'l', 'm', 'm²', 'm³', 'ml', 'pieza']"
                  label="Unidad (Requerido)"
                  v-model="producto.unidad"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Precio (Requerido)"
                  type="number"
                  v-model="producto.precio"
                  :rules="rules.precio"
                  :min="0"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Activo', 'Inactivo']"
                  v-model="producto.status"
                  label="Status"
                  :rules="rules.status"
                ></v-autocomplete>
              </v-col>
              
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Teléfono"
                  type="number"
                  v-model="producto.telefono"
                  :rules="rules.telefono"
                  :min="0"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Descripción (Requerido)"
                  height="90px"
                  v-model="producto.descripcion"
                  :rules="rules.descripcion"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="() => {
              dialog = false;
              this.textImagesError = '';
              this.imagesError = false;
            }"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click=" () => {  
              addProducto();
            }"
            v-if="!isEditing"
          >
            Registrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click=" () => {  
              editar(producto.rId);
            }"
            v-else
          >
            Editar
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
        <v-container v-if="allow == 'permitido'">
            <h1 class="titleGrey text-center">Mis productos / servicios</h1>
            <br />
            <v-row>
                <v-col cols="12" md="9"></v-col>
                <v-col cols="12" md="3">
                    <v-btn
                        @click="preAddProducto"
                        class="white--text place-btn outlined orange text-right ma-4"
                        >
                        Agregar producto / servicio
                    </v-btn>
                </v-col>
            </v-row>
            <v-card>
                <v-data-table
                :headers="headers"
                :items="productosUser"
                :items-per-page="5"
                fixed-header
                >
                <template v-slot:item.imagenes[0].name ="{ item }">
                    <div class="pa-2">
                        <v-img :src="`https://www.agenciaweb.mx/agronome/${item.imagenes[0].name}`" :alt="item.imagenes[0].name" width="80px"></v-img>
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        medium
                        color="info"
                        class="mr-2"
                        @click=" () => { dialog = !dialog; abrirEditar(item.rId); isEditing = true; }"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        medium
                        color="error"
                        @click="() => { 
                          deleteIndex = item.rId;
                          deleteOpen(item);
                        }"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                </v-data-table>
            </v-card>
            <v-dialog
                      v-model="doubleCheck"
                      persistent
                      max-width="600"
                    >
                      <v-card>
                        <v-card-title class="text-h5">
                          ¿Estás seguro de eliminar esta producto?
                        </v-card-title>
                        <v-card-text>Una vez eliminado este registro ya no aparecerá en tu panel de administración.</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="doubleCheck = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="red darken-1"
                            text
                            @click="deleteProducto()"
                          >
                            Eliminar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
        </v-container>
        <Footer />
    </div>
</template>
<script>
import Footer from "../Footer.vue";
import firebase from "firebase/app";
import { generateRandomId } from "../../tools/randomStrGus";
import axios from "axios";
import draggable from 'vuedraggable';
import moment from 'moment';
export default {
    name: "AdministrarParcelas",
    data: () => ({
        valid: false,
        imagesError: false,
        isDisabled: false,
        dialog: false,
        doubleCheck: false,
        isEditing: false,
        enabled: true,
        textImagesError: "",
        deleteIndex: "",
        producto: {
          imagenes: [],
          status: "Activo",
          unidad: ""
        },
        rules: {
          cantidad: [
            (v) => v > 0 || "La cantidad es requerida",
          ],
          producto: [
            (v) => !!v || "El nombre del producto / servicio es requerido",
            (v) =>
              (v && v.length >= 10) ||
              "El nombre del producto / servicio debe tener un mínimo de 10 caracteres",
          ],
          proveedor: [
            (v) => !!v || "El proveedor es requerido",
          ],
          tipo: [
            (v) => !!v || "El tipo de registro es requerido",
          ],
          precio: [
            (v) => !!v || "El precio es requerido",
          ],
          telefono: [
            (v) => !!v || "El teléfono es requerido",
            (v) => /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(v) || 'El teléfono no es válido, Ej. (999)-000-0000',
          ],
          descripcion: [
            (v) => !!v || "La descripción es requerida",
            (v) =>
              (v && v.length >= 30) ||
              "La descripcion debe tener un mínimo de 30 caracteres",
          ],
          status: [
            (v) => !!v || "El status es requerido",
          ]
        },
        snackbar: {
          open: false,
          text: "",
          timeout: 2000,
          color: "green darken-1 white--text",
        },
        headers: [
            { text: "IMAGEN", value: "imagenes[0].name", search: "", class: "orange--text" },
            { text: "FECHA", value: "fecha", search: "", class: "orange--text" },
            { text: "TÍTULO", value: "producto", search: "", class: "orange--text" },
            { text: "DESCRIPCIÓN", value: "descripcion", search: "", class: "orange--text" },
            { text: "TIPO", value: "tipo", search: "", class: "orange--text", width: "130px" },
            { text: "STATUS", value: "status", search: "", class: "orange--text", width: "130px" },
            { text: "PRECIO", value: "precio", search: "", class: "orange--text" },
            { text: "ACCIONES", value: "actions", sortable: false, search: "", class: "orange--text" },
        ],
        productosUser: [],
        productosUserS: [],
        arrayImagenes: [],
        productos: [],
        list: [],
        myUser: [
          {
            nombre: ""
          }
        ],
        registro: [],
        usuarios:[], 
        productos: [],
        responseProductos: []
    }),
    created() {
        this.initialize();
    },
    components: {
        Footer,
        draggable
    },
    computed: {
        allow() {
            return localStorage.getItem("status");
        },
        userMail() {
            return localStorage.getItem("mail");
        },
        coordenadas() {
            return (localStorage.getItem("latitude")) && (localStorage.getItem("longitude")) ? localStorage.getItem("latitude") + ", " + localStorage.getItem("longitude") : "sin datos";
        },
        cultivosOrdenados(){
            if(this.agrocostos.length > 0) {
                return this.cultivosSinOrdenar.sort();
            } else {
                return this.cultivosSinOrdenar;
            }
        },
    },
    methods: {
        abrirEditar(id) {
            console.log(id);
            this.registro = [];
            this.registro = this.productosUserS.filter(producto => producto.rId == id);
            this.producto = this.registro[0];
            this.list = this.producto.imagenes;
        },
        async editar(id) {
          if (this.valid == false) {
          } else {
            let db = firebase.firestore();
            await db.collection('productos').doc(id).update(this.producto).then(
                this.dialog = false,
                this.isEditing = false,
                this.snackbar.text = "Producto editado",
                this.snackbar.color = "green darken-1 white--text",
                this.snackbar.timeout = 3500,
                this.snackbar.open = true,
            );
            this.productosUserS = [];
            this.productosUser = [];
            this.getProductos(); 
          }
        },
    async subirImagenes(){
      if(this.arrayImagenes.length == 0){
          //document.getElementById("zonaImagenes").innerHTML = "";
        } else {
          let formData = new FormData();
          for (let imagen of this.arrayImagenes) {
            formData.append("file", imagen);
            this.respuesta = await axios.post(
              "https://agenciaweb.mx/agronome/upload-image.php",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            ).then(function(response){
              if(response.data.image == ''){
              } else {
                sessionStorage.setItem('oldNombreImagen', response.data.oldimage);
                sessionStorage.setItem('nombreImagen', response.data.image);
                //document.getElementById("imagenCargada").innerHTML = `<img src='https://www.agenciaweb.mx/agronome/${nombreImagen}' style="width: 70px;">`;
              }
            });
            this.objectImage = {
              name: sessionStorage.getItem('nombreImagen'),
              originalName: sessionStorage.getItem('oldNombreImagen'),
            };
            this.list.push(this.objectImage);
            this.imagesError = false;
            this.textImagesError = "";
          };
          this.producto.imagenes = this.list;
          this.arrayImagenes = [];
        }
    },
      async addProducto() {
      this.isDisabled = true;
      this.$refs.form.validate();
      if (this.valid == false) {
        this.isDisabled = false;
      }
      if (this.list.length == 0) {
        this.textImagesError = "Seleccione una(s) imagen(es) para la parcela"
        this.imagesError = true;
        this.isDisabled = false;
      } else if (this.valid == true && this.list.length != 0) {
        this.producto.imagenes = this.list;
        this.producto.nombreProveedor = this.myUser[0].nombre;
        this.producto.proveedor = this.userMail;
        this.producto.id = generateRandomId();
        this.producto.eliminado = false;
        let fechaString = moment().format("YYYY-MM-DD HH:mm:ss");
        this.producto.fecha = fechaString;
        let db = firebase.firestore();
        await db.collection("productos").add(this.producto).then(
          this.dialog = false,
          this.isEditing = false,
          this.snackbar.text = "Producto / servicio registrado",
          this.snackbar.color = "green darken-1 white--text",
          this.snackbar.timeout = 3500,
          this.snackbar.open = true,
        );
        this.responseProductos = [];
        this.getProductos();
        this.dialog = false;
      }  
    },
        deleteImage(name) {
          if(this.producto.imagenes.length == 1) {
            this.textImagesError = "El producto debe incluir al menos una imagen, no es posible eliminar";
          } else {
            this.producto.imagenes = this.producto.imagenes.filter(imagen => imagen.name != name);
            document.getElementById(name).style.display = "none";
          }

        },
        preAddProducto() {
          this.dialog = true;
          this.isEditing = false;
          if(this.$refs.form != undefined) {
            this.$refs.form.resetValidation();
          }
          this.list = [];
          this.producto = {
            dueno: this.userMail,
            georreferencia: this.coordenadas,
            status: "Activo"
          };
        },
        async getProductos() {
          this.productosUser = [];
          this.productosUserS = [];
          let db = firebase.firestore();
          await db
            .collection("productos")
            .where("dueno", "==", this.userMail)
            .where("eliminado", "==", false)
            .orderBy("fecha", "desc")
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((doc, i) => {
                this.responseProductos.push(doc.data());
                this.productosUser.push(doc.data());
                this.productosUser[i].rId = doc.id;
                this.productosUserS.push(doc.data());
                this.productosUserS[i].rId = doc.id;
              }
              );
            });
          this.productos = this.responseProductos;
          console.log(this.productos);
        },
        async getUsuarios() {
          let db = firebase.firestore();
          await db
            .collection("usuarios")
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((doc) =>
                this.usuarios.push(doc.data())
              );
            });
          let nombreMail = localStorage.getItem("mail");
          this.usuarios.length != 0 | this.usuarios.length != undefined ? this.myUser = this.usuarios.filter(user => user.correo == nombreMail) : this.myUser = [{ name: "" }];
        },
        deleteOpen(item) {
          this.doubleCheck = true;
          this.producto = item;
        },
        async deleteProducto() {
          let db = firebase.firestore();
          this.producto.eliminado = true;
          await db.collection('productos').doc(this.deleteIndex).update(this.producto).then(
            this.doubleCheck = false,
            this.snackbar.text = "Producto eliminado",
            this.snackbar.color = "red darken-1 white--text",
            this.snackbar.timeout = 3500,
            this.snackbar.open = true,
          );
          this.getProductos();
          location.reload();
        },
        checkMove: function(e) {
            //window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        initialize() {
            this.getProductos();
            this.getUsuarios();
        }
    }
}
</script>
