<template>
  <div>
    <div v-if="allow == 'permitido'">
        <Cultivos />
        <Oportunidades v-if="false">
        <h1 class="titulo">OPORTUNIDADES <br /><b>PARA TODOS</b></h1>
        </Oportunidades>
        <ImageNText v-if="false"/>
        <ImageNText :imageFirst="false" v-if="false"/>
    </div>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<style scoped>
.titulo {
  font-weight: lighter;
  font-size: 5rem;
}
</style>
<script>
import Footer from "../Footer.vue";
import Cultivos from "./textosSections/Cultivos.vue";
import Oportunidades from "./textosSections/Oportunidades.vue";
import ImageNText from "./textosSections/ImageNText.vue";
import Bloqueo from "../Bloqueo.vue";

export default {
  name: "textos",
  components: {
    Footer,
    Cultivos,
    Oportunidades,
    ImageNText,
    Bloqueo
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    allow() {
      return localStorage.getItem("status");
    }
  }
};
</script>