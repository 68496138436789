<style scoped>
    .text-h3 {
        font-size: 28px !important;
    }
    .place-btn {
        position: fixed;
        bottom: 25px;
        right: 25px;
    }
    .inline {
        display: inline-block !important;
    }
    .links {
        text-decoration: none;
    }
</style>
<template>
<div>
  <v-snackbar 
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      fixed
      right
      top
    >
      <center>
        {{ snackbar.text }}
      </center>
  </v-snackbar>
  <v-container v-if="allow == 'permitido'">
        <h1 class="center">Cadenas productivas</h1>
        <br/>
        <v-row>
           <v-col cols="12" md="8">
           </v-col>
          <v-col cols="12" md="4" class="text-right">
            <v-btn class="orange lighten-2 white--text mb-2"  @click="dialog = !dialog">
              Registrar cadena productiva
            </v-btn>
            <div v-if="false" class="mb-3">
              Ya estás registrado como cadena productiva
            </div>
          </v-col>
        </v-row>
        <v-card>
            <v-container>
        <v-row>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete
                v-model="headers[1].search"
                :items="actividadesProductos"
                dense
                label="Actividad / Producto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
            </v-col>
            <v-col cols="12" md="3" sm="3">
            </v-col>
            <v-col cols="12" md="3" sm="3" class="text-center">
              <v-btn
              min-width="200px"
              class="orange lighten-2 white--text"
              @click="actividadesFiltradas"
              >
                Filtrar
              </v-btn>
            </v-col>
        </v-row>
      </v-container>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="proveedoresFiltrados"
                :items-per-page="5"
                fixed-header
              >
              <template slot="no-data">
                <span style="color: #FF5252;"> No hay registros disponibles para tu búsqueda </span>
              </template>
              <!--v-if="item.dueno == userMail"-->
              <template v-slot:item.actions="{ item }">
                <div v-if="item.dueno == userMail">
                  <v-icon
                    medium
                    color="info"
                    class="mr-2"
                    @click=" () => { dialog = !dialog; abrirEditar(item.rId); isEditing = true; }"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      medium
                      color="error"
                      @click=" () => { 
                      deleteIndex = item.rId;
                      deleteOpen(item);
                      }"
                    >
                      mdi-delete
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            fullscreen
          >
            <v-card>
              <v-form ref="form" v-model="valid">
              <v-card-title>
                <span class="text-h5" v-if="!isEditing">Agregar cadena productiva</span>
                <span class="text-h5" else>Editar cadena productiva</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Razon social"
                        v-model="cadena.razonSocial"
                        :rules="rules.producto"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Nombre del contacto"
                        v-model="cadena.contacto"
                        :rules="rules.nombre"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Actividad / producto"
                        v-model="cadena.actividadInsumo"
                        :rules="rules.actividad"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Correo"
                        v-model="userMail"
                        :rules="rules.correo"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        label="Teléfono"
                        type="number"
                        v-model="cadena.telefono"
                        :rules="rules.telefono"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Cerrar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click=" () => {  
                    addItem();
                  }"
                  v-if="isEditing == false"
                >
                  Registrar
                </v-btn>
                <v-btn 
                  color="blue darken-1"
                  text
                  v-else
                  @click=" () => {  
                    editar(cadena.rId);
                  }"
                >
                  Editar
                </v-btn>
              </v-card-actions>
              </v-form>
            </v-card>
        </v-dialog>

        <v-dialog
          v-model="doubleCheck"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title class="text-h5">
              ¿Estás seguro de eliminar esta parcela?
            </v-card-title>
              <v-card-text>Una vez eliminado este registro ya no aparecerá.</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="doubleCheck = false"
                  >
                  Cancelar
                </v-btn>
                <v-btn
                color="red darken-1"
                text
                @click="deleteCadena()"
                >
                  Eliminar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  </v-row>
    </v-container>
    <Bloqueo v-else/>
    <Footer/>
</div>
</template>
<script>
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore'; 
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import { matchStrings } from "../../tools/proStrings";
import moment from "moment";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "Proveedores",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    dialog: false,
    valid: false,
    isDisabled: false,
    isEditing: false,
    doubleCheck: false,
    rolSearch: "Clientes-cadenas", 
    user: {
      name: "",
      phone: "",
      municipality: "",
      city: "",
      state: "",
      ocupacion: "",
      tipoCultivo: "",
      tipoGanado: "",
      noGanado: "",
      producto: "",
      tamanoParcela: "",
      georreferencia: "",
    },
    newItem: "",
    rules: {
      producto: [
        (v) => !!v || "La razón social es requerida",
        (v) =>
          (v && v.length >= 10) ||
          "La razón social debe tener un mínimo de 10 caracteres",
      ],
      nombre: [
        (v) => !!v || "El nombre de contacto es requerido",
        (v) =>
          (v && v.length >= 15) ||
          "El nombre de contacto debe tener un mínimo de 15 caracteres",
      ],
      actividad: [
        (v) => !!v || "El nombre de contacto es requerido",
        (v) =>
          (v && v.length >= 15) ||
          "El nombre de contacto debe tener un mínimo de 15 caracteres",
      ],
      correo: [
        (v) => !!v || "El correo electrónico es requerido",
      ],
      telefono: [
        (v) => !!v || "El número telefónico es requerido",
        v => /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(v) || 'El teléfono no es válido, Ej. (999)-000-0000',
      ],
      rol: [
        (v) => !!v || "El rol es requerido",
      ]
    },
    snackbar: {
          open: false,
          text: "",
          timeout: 2000,
          color: "green darken-1 white--text",
    },
    headers: [
      { text: "RAZON SOCIAL", value: "razonSocial", search: "", class: "orange--text" },
      { text: "ACTIVIDAD / PRODUCTO", value: "actividadInsumo", search: "", class: "orange--text" },
      { text: "CONTACTO", value: "contacto", search: "", class: "orange--text" },
      { text: "CORREO", value: "correo", search: "", class: "orange--text" },
      { text: "TELEFONO", value: "telefono", search: "", class: "orange--text", width: "150px"},
      { text: "ACCIONES", value: "actions", sortable: false, search: "", class: "orange--text" },
    ],
    opcionesOcupacion: [
      "Productor agrícola",
      "Agroindustria",
      "Ganadero",
      "Proveedor",
      "Parafinanciera",
      "Sofom",
      "Sofotipo",
      "Caja de ahorro",
      "Otros",
    ],
    opcionesGanado: [
      "Bovino",
      "Ovino",
      "Porcino",
      "Aviar",
      "Caprino",
      "Equino",
      "Cunicultura",
      "Avicultura",
      "Apicultura",
    ],
    proveedores: [],
    proveedoresFiltrados: [],
    filtrados: [],
    cadena: {},
    actividadesSinOrdenar: [],
    myProveedor: []
  }),
  computed: {
    tipoOcupacion() {
      return this.user.ocupacion;
    },
    allow() {
      return localStorage.getItem("status");
    },
    actividadesProductos(){
      if(this.proveedores.length > 0) {
        return this.actividadesSinOrdenar.sort();
      } else {
        return this.actividadesSinOrdenar;
      }
    },
    userMail() {
      return localStorage.getItem("mail");
    },
    isProvider() {
      if (this.myProveedor.length > 0){
      return true
    } else {
      return false
    }
    }
  },
  methods: {
    initialize() {
    this.getProveedores();
    this.getMyProveedor();
    },
    async addItem() {
      this.isDisabled = true;
      this.$refs.form.validate();
      if(this.valid == false){
        this.isDisabled = false;
      } else {
        this.cadena.correo = this.userMail;
        this.cadena.dueno = this.userMail;
        this.cadena.eliminado = false;
        let db = firebase.firestore();
        await db.collection("newProveedores").add(this.cadena).then(
          this.snackbar.text = "Registro agregado",
          this.snackbar.color = "green darken-1 white--text",
          this.snackbar.timeout = 3500,
          this.snackbar.open = true,
        );
        sessionStorage.setItem('proveedores', '')
        this.proveedores = [];
        this.getProveedores();
        this.dialog = false;
      }
    },
    actividadesFiltradas() {
      this.proveedoresFiltrados = this.proveedores;
      if(this.headers[1].search == ""){
        //this.openSnackbar('Ingrese una actividad / producto', 2000);
      } else {
        this.proveedoresFiltrados = this.proveedoresFiltrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.value] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      let db = firebase.firestore();
      let user = firebase.auth().currentUser;
      let oldBusqueda = localStorage.getItem("busquedaCampo");
      let query = this.headers[0].search + " en " + this.headers[1].search + " con " + this.headers[2].search;
      let myTime = moment().format("DD-MM-YYYY HH:mm:ss");
      if(query != "" && query != oldBusqueda){
        let record = {
        usuario: user.email,
        operacionRealizada: "Busqueda por filtrado",
        origen: "Tabla: Costos cultivos",
        busquedaCompleta: query,
        timeStamp: myTime
      };
      this.busquedaCampo = localStorage.setItem("busquedaCampo", query);
      db.collection("operaciones").add(record);
      }
      return this.filtrados;
      }
    },
    abrirEditar(id) {
            this.registro = [];
            this.registro = this.proveedores.filter(proveedor => proveedor.rId == id);
            this.cadena = this.registro[0];
    },
    async editar(id) {
          if (this.valid == false) {
          } else {
            let db = firebase.firestore();
            await db.collection('newProveedores').doc(id).update(this.cadena).then(
                this.dialog = false,
                this.isEditing = false,
                this.snackbar.text = "Registro editado",
                this.snackbar.color = "green darken-1 white--text",
                this.snackbar.timeout = 3500,
                this.snackbar.open = true,
            );
            this.proveedores = [];
            this.getProveedores(); 
          }
    },
    deleteOpen(item) {
      this.doubleCheck = true;
      this.parcela = item;
    },
    async deleteCadena() {
      let db = firebase.firestore();
      this.cadena.eliminado = true;
      await db.collection('newProveedores').doc(this.deleteIndex).update(this.cadena).then(
      this.doubleCheck = false,
      this.snackbar.text = "Registro eliminado",
      this.snackbar.color = "red darken-1 white--text",
      this.snackbar.timeout = 3500,
      this.snackbar.open = true,
      );
      this.getProveedores();
      location.reload();
    },
    async getProveedores() {
      /*let proveedoresAlmacenado = sessionStorage.getItem('proveedores');
      if(proveedoresAlmacenado != "" && proveedoresAlmacenado != undefined) {
        this.proveedores = JSON.parse(sessionStorage.getItem('proveedores'));
        this.proveedoresFiltrados = this.proveedores;
        this.proveedores.forEach( item => this.actividadesSinOrdenar.push(item.actividadInsumo));
      } else { */
        let db = firebase.firestore();
        await db
        .collection("newProveedores")
        .where("eliminado", "==", false)
        .orderBy("razonSocial")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc, i) => { 
            this.proveedores.push(doc.data());
            this.proveedores[i].rId = doc.id;
          });
        });
        this.proveedores.forEach( item => this.actividadesSinOrdenar.push(item.actividadInsumo));
        this.proveedoresFiltrados = this.proveedores;
        let parsed = JSON.stringify(this.proveedores);
        sessionStorage.setItem('proveedores', parsed);
      /*}*/
    },

    async getMyProveedor() {
      let db = firebase.firestore();
      await db
        .collection("newProveedores")
        .where("correo", "==", this.userMail)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.myProveedor.push(doc.data()));
        });
      
    },

    deleteItem(id) {
      db.collection("usuarios").doc(id).delete();
    },
  },
};
</script>

