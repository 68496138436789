<style scoped>
  .secciones {
    display: none;
  }
</style>
<template>
  <div>
    <Carousel />
    <Bienvenido />
    <Encontraras />
    <Informacion />
    <Enfermedad />
    <Producto />
    <Consulta />
    <Revisa />
    <Footer />
  </div>
</template>

<script>
import Footer from "../Footer.vue";
import Carousel from "./homeSections/Carousel.vue";
import Bienvenido from "./homeSections/Bienvenido.vue";
import Encontraras from "./homeSections/Encontraras.vue";
import Informacion from "./homeSections/Informacion.vue";
import Enfermedad from "./homeSections/Enfermedad.vue";
import Producto from "./homeSections/Producto.vue";
import Consulta from "./homeSections/Consulta.vue";
import Revisa from "./homeSections/Revisa.vue";
export default {
  name: "Home",
  components: {
    Footer,
    Carousel,
    Bienvenido,
    Encontraras,
    Informacion,
    Enfermedad,
    Producto,
    Consulta,
    Revisa,
  },
  data: () => ({
    drawer: null,
  }),
};
</script>
