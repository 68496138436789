<template>
  <v-container>
    <v-row align="center" class="pt-4">
      <v-col cols="12" sm="6" v-if="imageFirst">
        <v-row>
          <v-col>
            <img :src="items[0].src" alt="imagen random" />
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        {{ text }}
      </v-col>
      <v-col cols="12" sm="6" v-if="!imageFirst">
        <v-row>
          <v-spacer />
          <v-col>
            <img :src="items[0].src" alt="imagen random" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.test {
  border: solid 2px #f00;
}
</style>
<script>
export default {
  name: "ImageNText",
  props: {
    text: {
      type: String,
      required: false,
      default: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel`,
    },
    imageFirst: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    items: [
      {
        src: require("../../../assets/SECCION-CULTIVOS-MAS-RENTABLES.png"),
      },
    ],
  }),
};
</script>