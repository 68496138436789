<style scoped>
.line-green {
  border-top: solid #6d9f29 6px !important;
}
.footer-padding {
  padding-top: 30px;
  padding-bottom: 20px;
}
.titleFooter {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
}
.links {
  color: #4d4d4d;
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  text-decoration: none;
}
.links:hover,
.linksSmall:hover {
  color: #ff7000;
}
.linksSmall {
  color: #4d4d4d;
  font-size: 15px;
  margin-bottom: 8px;
  text-decoration: none;
  margin-right: 10px;
}
.footerPrimary {
  background-color: #f7f7f7 !important;
}
.footerSecondary {
  background-color: #f0f0f0 !important;
}
</style>
<template>
  <div>
    <div class="footerPrimary">
      <v-container class="footer-padding" style="min-height: 40vh; margin-top: 5vh;">
        <v-row>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" md="4">
                <div class="titleFooter">AGRONOME</div>
                <router-link to="/" class="links">Inicio</router-link>
                <router-link to="/productos" class="links">Productos y servicios</router-link>
                <router-link to="/parcelas" class="links">Parcelas en renta</router-link>
              </v-col>
              <v-col cols="12" md="8">
                <div class="titleFooter">
                  REGÍSTRATE PARA DISFRUTAR DE LOS SIGUIENTES BENEFICIOS:
                </div>
                  <v-row>
                    <v-col cols="12" md="4">
                      <router-link to="/login" class="links" v-if="!loggedIn">Login</router-link>
                      <router-link to="/costosCultivos" class="links">Costos de cultivos</router-link>
                      <router-link to="/ingresosCultivos" class="links">Ingresos de cultivos</router-link>
                      <router-link to="/preciosCommodities" class="links">Precios agrícolas</router-link>
                      <router-link to="/proveedores" class="links">Cadenas productivas</router-link>
                    </v-col>
                    <v-col cols="12" md="4">
                      <router-link to="/plantaEnferma" class="links">Planta enferma</router-link>
                      <router-link to="/textos" class="links">Cultivo más rentable</router-link>
                      <router-link to="/seguros" class="links">Seguros Agrícolas</router-link>
                    </v-col>
                    <v-col cols="12" md="4">
                      <router-link to="/logistica" class="links">Logística</router-link>
                      <router-link to="/clima" class="links">Clima</router-link>
                      <router-link to="/apoyos" class="links"
                        >Apoyos gubernamentales</router-link
                      >
                    </v-col>
                  </v-row>
                  </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" v-if="false">
            <v-img
              :src="require('../assets/logotipo.png')"
              class="my-3"
              contain
              width="80%"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footerSecondary">
      <v-container>
        <v-row>
          <v-col cols="12" md="10">
            <a href="https://www.agenciaweb.mx/" class="linksSmall"
              >Desarrollado por Grupo Digiart</a
            >
            | <a href="/avisoPrivacidad" class="linksSmall">Políticas de privacidad</a> |
            <a href="/terminos" class="linksSmall">Términos y condiciones</a>
          </v-col>
          <v-col cols="12" md="2" class="d-flex justify-space-around">
            <v-btn icon
              ><v-icon large color="brown darken-4">
                mdi-facebook
              </v-icon></v-btn
            >
            <v-btn icon
              ><v-icon large color="brown darken-4">
                mdi-twitter
              </v-icon></v-btn
            >
            <v-btn icon
              ><v-icon large color="brown darken-4">
                mdi-instagram
              </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
require("firebase/auth");
export default {
  name: "Footer",
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.loggedIn = !!user;
    });
  },
  data: () => ({
    loggedIn: false
  }),
  methods: {}
};
</script>
