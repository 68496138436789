<template>
  <div style="width: 95%; margin-left: 2.5%;" class="text-xs-center">
    <br/>
    <h1 style="font-size: 45px;">Administrador</h1>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-card>
          <v-list>
          <v-list-item-group
            v-model="model"
            color="orange"
          >
              <v-list-item
                v-for="(item, i) in sidebar"
                :key="i + 1 "
                @click="setModule(item.module)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9">
        <v-card>
            <div v-if="module == 'operacionesUsuarios'">
              <v-container>
                <h1 class="titleGrey text-center">Operaciones de usuarios</h1>
                <br />
                <v-card elevation="0">
                  <v-simple-table style="max-width='700px'" height="350px" fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            v-for="(header, ind) in headers"
                            :key="ind"
                          >
                            <v-text-field
                              :placeholder="header['name']"
                              append-icon="mdi-table-search"
                              dense
                              v-model="header.search"
                              class="shrink"
                            ></v-text-field>
                          </th>
                        </tr>
                        <tr>
                          <th
                            class="text-left orange--text"
                            v-for="(header, ind) in headers"
                            :key="ind"
                          >
                            {{ header["name"] }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in operacionesFiltradas" :key="item.name">
                          <td>{{ item.usuario }}</td>
                          <td>{{ item.origen }}</td>
                          <td>{{ item.operacionRealizada }}</td>
                          <td>{{ item.busquedaCompleta }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table> </v-card
                ><br />
        </v-container>
      </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
import { matchStrings } from "../../tools/proStrings";

export default {
  name: "Admin",
  data: () => ({
    model: 1,
    module: "operacionesUsuarios",
    sidebar: [
      { name: "Operaciones usuarios", icon: "mdi-account-search", module: "operacionesUsuarios" }
    ],
    headers: [
      { name: "USUARIO", attr: "usuario", search: "" },
      { name: "OPERACIÓN REALIZADA", attr: "operacionRealizada", search: "" },
      { name: "ORIGEN", attr: "origen", search: "" },
      { name: "DESCRIPCIÓN COMPLETA", attr: "busquedaCompleta", search: "" },
    ],
    headersUsuarios: [
      { name: "USUARIO", attr: "cosa", search: "" },
      { name: "OPERACIÓN REALIZADA", attr: "cosa", search: "" },
      { name: "ORIGEN", attr: "cosa", search: "" },
      { name: "DESCRIPCIÓN COMPLETA", attr: "cosa", search: "" },
    ],
    operaciones: [],
  }),
  created(){
    this.initialize();
  },
  computed: {
    operacionesFiltradas() {
      let filtrados = this.operaciones;
      filtrados = filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      return filtrados;
    },
  }, 
  methods: {
    initialize(){
      //this.getOperaciones();
    },
    setModule(a){
      this.module = a;
    },
    async getOperaciones() {
      let db = firebase.firestore();
      await db
        .collection("operaciones")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.operaciones.push(doc.data()));
        });
    },
  }
};
</script>
