<template>
  <v-carousel hide-delimiter height="90vh" width="100vw">
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
      <v-container bg fill-height grid-list-md>
        <v-layout row wrap align-center>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="white--text text-left">
              <div></div>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="text-center">
              <div class="text-h3 fontTitle white--text">{{ item.titleLn }}</div>
              <div class="text-h2 mb-8 fontTitle white--text">{{ item.titleLn2 }}</div>
              <div class="text-h5 my-8 fontBody white--text">
                <span>{{ item.content }}</span><span class="negritas">{{ item.contentBold }}</span>
              </div>
              <v-btn :to="item.btnLink" x-large rounded class="myBtn">
                <span class="negritas">{{ item.txtBtnBold }}</span> {{ item.txtBtn }}
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>
<style scoped>
.myBtn {
  background-color: #fff;
  border: solid #fea100 2px;
  text-transform: none;
}
.negritas {
  margin-right: 4px;
  font-weight: bold;
}
</style>
<script>
export default {
  name: "Carousel",
  data: () => ({
    items: [
      {
        titleLn: "OPORTUNIDADES",
        titleLn2: "PARA TODOS",
        content: " En nuestro sitio encontrarás toda la información sobre el campo completamente ",
        contentBold: "Gratis",
        src: require("../../../assets/home-principal.png"),
        txtBtnBold: "Cotiza ",
        txtBtn: "tu cultivo",
        btnLink: "/costosCultivos"
      },
      {
        titleLn: "TODOS SOMOS",
        titleLn2: "EL CAMPO",
        content: " Encuentra costos, rendimientos, precios y todos los apoyos del gobierno que te corresponden.",
        contentBold: "",
        src: require("../../../assets/home-principal2.png"),
        txtBtnBold: "Cultivo ",
        txtBtn: "más rentable",
        btnLink: "/textos"
      },
    ],
  }),
};
</script>