<style scoped>
.miBoton {
  border-radius: 10px;
  border: solid #fea100 2px;
  font-size: 14px;
  height: 200px;
}
</style>
<template>
  <div>
    <Logeado v-if="allow == 'permitido'" />
    <div class="container" v-else>
      <v-form ref="form" v-model="valid">
        <h1 class="center">¡REGISTRATE!</h1>
        <p>
          ¡Completa este formulario para crear tu usuario y acceder a nuestros
          servicios gratuitos!
        </p>

        <span class="text-h5" style="font-size: 16px;">Información General</span>
        <br />
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="user.name"
              label="Nombre (Requerido)"
              :rules="rules.nombre"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="user.apellido"
              label="Apellido (Requerido)"
              :rules="rules.apellido"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="user.email"
              label="Correo electrónico (Requerido)"
              :rules="rules.correo"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="user.password"
              type="password"
              label="Contraseña (Requerido)"
              :rules="rules.contrasena"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="user.phone"
              type="number"
              label="Teléfono (Requerido)"
              :rules="rules.telefono"
              dense
              outlined
            ></v-text-field
          ></v-col>
          <v-col cols="4"
            ><v-autocomplete
              v-model="user.state"
              label="Estado (Requerido)"
              :items="nombresEstados"
              item-text="nombre"
              item-value="nombre"
              :rules="rules.estado"
              dense
              outlined
            ></v-autocomplete></v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="user.municipality"
              label="Alcaldía / Municipio (Requerido)"
              :items="municipiosFiltrados"
              :rules="rules.municipio"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          </v-row>
        <span class="text-h5" style="font-size: 16px;">Datos de Ocupación</span>
        <br />
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              label="Ocupación (Requerido)"
              color="info"
              :items="opcionesOcupacion"
              v-model="user.ocupacion"
              :rules="rules.ocupacion"
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="4" v-if="tipoOcupacion == 'Otros'">
            <v-text-field
              v-model="user.otros"
              label="Especifique"
              :rules="rules.especifique"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="4" v-if="tipoOcupacion == 'Productor agrícola'">
            <v-autocomplete
              label="Cultivos que produce"
              color="info"
              :items="cultivosOrdenados"
              v-model="user.tipoCultivo"
              :rules="rules.cultivos"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="4" v-if="tipoOcupacion == 'Ganadero'">
            <v-autocomplete
              label="Tipo de ganado"
              color="info"
              :items="opcionesGanado"
              v-model="user.tipoGanado"
              :rules="rules.tipoGanado"
              outlined
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="4" v-if="tipoOcupacion == 'Ganadero' && user.tipoGanado == 'Apicultura'">
            <v-text-field
              label="Número de colmenas"
              color="info"
              type="number"
              v-model="user.noColmenas"
              :rules="rules.ganado"
              :min="1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4" v-if="tipoOcupacion == 'Ganadero' && user.tipoGanado != 'Apicultura'">
            <v-text-field
              v-model="user.noGanado"
              type="number"
              label="Número de Cabezas de Ganado"
              :rules="rules.noGanado"
              dense
              outlined
            ></v-text-field
          ></v-col>
          <v-col cols="4" v-if="tipoOcupacion == 'Agroindustria'"
            ><v-text-field
              v-model="user.producto"
              label="Producto"
              :rules="rules.producto"
              dense
              outlined
            ></v-text-field
          ></v-col>
          <v-col cols="4" v-if="tipoOcupacion == 'Productor agrícola'"
            ><v-text-field
              v-model="user.tamanoParcela"
              type="number"
              label="El tamaño de la parcela"
              suffix="Ha"
              :rules="rules.tamanoParcela"
              dense
              outlined
            ></v-text-field></v-col
          ><v-col cols="4" style="display: none;"
            ><v-text-field
              v-model="coordenadas"
              label="Georreferencia"
              :rules="rules.georreferencia"
              hint="En caso de que nos incluya su georeferencia podemos hacerle ofertas de seguros, créditos, así como entregarle la información satelital de su parcela*"
              dense
              outlined
            ></v-text-field
          ></v-col>
        </v-row>

        <v-btn class="miBoton" @click="addItem" :disabled="isDisabled"
          >Registrar</v-btn
        >
      </v-form>
    </div>
    <br />
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      absolute
      right
      top
    >
      {{ snackbar.text }}

    </v-snackbar>
    <Footer />
  </div>
</template>

<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
require("firebase/auth");
require("firebase/firestore");
import { estados } from '../../tools/estados';
import { municipios } from '../../tools/municipios';
import Logeado from "../Logeado.vue";


export default {
  name: "Register",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Logeado
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    user: {
      name: "",
      phone: "",
      municipality: "",
      city: "",
      state: "",
      ocupacion: "",
      tipoCultivo: "",
      tipoGanado: "",
      noGanado: "",
      producto: "",
      tamanoParcela: "",
      georreferencia: "",
      numeroColmenas: ""
    },
    newItem: "",
    snackbar: {
      open: false,
      text: "",
      color: "green darken-1 white--text",
      timeout: 3500,
    },
    opcionesOcupacion: [
      "Agroindustria",
      "Caja de ahorro",
      "Ganadero",
      "Otros",
      "Parafinanciera",
      "Productor agrícola",
      "Proveedor",
      "Sofom",
      "Sofotipo",
    ],
    opcionesGanado: [
      "Apicultura",
      "Aviar",
      "Avicultura",
      "Bovino",
      "Caprino",
      "Cunicultura",
      "Equino",
      "Ovino",
      "Porcino",
    ],
    agrocostos: [],
    cultivosNoOrdenados: [],
    rules: {
      nombre:[
        (v) => !!v || "El nombre es requerido",
        (v) =>
          (v && v.length >= 5) ||
          "El nombre debe tener un mínimo de 5 caracteres",
      ],
      apellido:[
        (v) => !!v || "El apellido es requerido",
        (v) =>
          (v && v.length >= 2) ||
          "El apellido debe tener un mínimo de 2 caracteres",
      ],
      correo: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido (Ej. correo@nombredominio.com)",
      ],
      contrasena: [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener un mínimo de 8 caracteres",
      ],
      telefono: [
        (v) => !!v || "El teléfono es requerido",
        (v) =>
          (v && v.length >= 10) ||
          "La contraseña debe tener un mínimo de 10 dígitos",
      ],
      estado: [
        (v) => !!v || "El estado es requerido",
      ],
      municipio: [
        (v) => !!v || "La alcaldía / municipio es requerido",
      ],
      ocupacion: [
        (v) => !!v || "La ocupación es requerida",
      ],
      producto: [
        (v) => !!v || "El producto es requerido",
        (v) =>
          (v && v.length >= 5) ||
          "El nombre debe tener un mínimo de 5 dígitos",
      ],
      tipoGanado: [
        (v) => !!v || "El tipo de ganado requerido",
      ],
      noGanado: [
        (v) => !!v || "El número de cabezas de ganado requerido",
      ],
      especifique: [
        (v) => !!v || "Especifique su ocupación",
        (v) =>
          (v && v.length >= 10) ||
          "El descripción debe tener un mínimo de 10 dígitos",
      ],
      cultivos: [
        (v) => !!v || "El nombre del cultivo es requerido",
      ],
      tamanoParcela: [
        (v) => !!v || "El tamaño de la parcela es requerido",
      ]
    },
  }),
  computed: {
    tipoOcupacion() {
      return this.user.ocupacion;
    },
    coordenadas(){
      return (localStorage.getItem("latitude")) && (localStorage.getItem("longitude")) ? localStorage.getItem("latitude") + ", " + localStorage.getItem("longitude") : "sin datos";
    },
    latitud() {
      localStorage.getItem("latitude") ? localStorage.getItem("latitude") : "" ;
    },
    longitude() {
      localStorage.getItem("longitude") ? localStorage.getItem("longitude") : "" ;
    },
    municipiosFiltrados() {
      let filtrado = [];
      this.user.state != "" && this.user.state != undefined ?  filtrado = this.nombresMunicipios[this.user.state] : filtrado = [];
      return filtrado
    },
    cultivosOrdenados() {
      return this.cultivosNoOrdenados.sort();
    },
    allow() {
      return localStorage.getItem("status");
    }
  },
  methods: {
    initialize() {
      this.nombresEstados = estados;
      this.nombresMunicipios = municipios;
      this.getAgrocostos();
    },
    async addItem() {
      this.isDisabled = true;
      this.$refs.form.validate();
      let db = firebase.firestore();
      console.log(this.valid);
      if (this.valid == false) {
          this.isDisabled = false;
          return;
      } else {
        //await db.collection("usuarios").add({ name: this.name });
        this.isDisabled = true;
        try {
          const user = firebase
            .auth()
            .createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then((cred) => {
            cred.user.updateProfile({
                displayName: this.user.name
            })
              return cred;
            })
            .then((cred) => {
              return db.collection("usuarios").doc(cred.user.uid).set({
                nombre: this.user.name,
                apellido: this.user.apellido,
                telefono: this.user.phone,
                municipio: this.user.municipality,
                estado: this.user.state,
                ocupacion: this.user.ocupacion,
                tipoCultivo: this.user.tipoCultivo,
                tipoGanado: this.user.tipoGanado,
                noColmenas: this.user.numeroColmenas,
                noGanado: this.user.noGanado,
                producto: this.user.producto,
                tamanoParcela: this.user.tamanoParcela,
                correo: this.user.email,
                georreferencia: this.coordenadas,
              });
            })
            .then(() => {
              console.log(user);
              this.isDisabled = false;
              localStorage.setItem("isLogged", "Loged");
              this.newItem = "";
              this.snackbar.open = true,
              this.snackbar.text = "Usuario registrado exitosamente";
              this.snackbar.color = "green darken-1 white--text";
              this.$router.replace({ name: "Home" });
            }).then(() => {
              this.$router.go();
            }).catch( err => {
              if (err.message == "The email address is already in use by another account.") 
                this.snackbar.text = "Este email ya fue usado, regístrese con otro email";
              else 
                this.snackbar.text = "Ocurrió un error al registrarte";
              
              console.log(err);
              this.snackbar.color = "red darken-1 white--text";
              this.snackbar.open = true,
              this.isDisabled = false;
            });
        } catch (err) {
          console.log(err);
        }
      }
    },
    async uploadDatabase(){
      let myRecords = [];
      let db = firebase.firestore();
      myRecords.forEach(record => db.collection("").add(record));
    },
    async getAgrocostos() {
      let agrocostosAlmacenado = sessionStorage.getItem('agrocostos');
      if((agrocostosAlmacenado !== "" && agrocostosAlmacenado !== undefined) && (agrocostosAlmacenado !== "[]" && agrocostosAlmacenado !== null)) {
          this.agrocostos = JSON.parse(sessionStorage.getItem('agrocostos'));
          this.agrocostos.forEach( item => this.cultivosNoOrdenados.push(item.cultivo));
      } else {
          let db = firebase.firestore();
          await db
            .collection("newAgrocostos")
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((doc) => this.agrocostos.push(doc.data()));
              this.agrocostos.forEach( item => {
                this.cultivosNoOrdenados.push(item.cultivo)
              });
            });
          let parsed = JSON.stringify(this.agrocostos);
          console.log(this.agrocostos);
          sessionStorage.setItem('agrocostos', parsed);
      }
    },

    deleteItem(id) {
      db.collection("usuarios").doc(id).delete();
    },
  },
};
</script>
