<template>
  <div style="overflow-x: hidden;">
    <v-row no-gutters style="min-height: 100vh">
      <v-col
        cols="12"
        sm="6"
        class="white--text myBanner"
        :style="{
          'background-image': 'url(' + items[0].src + `)`,
        }"
      ></v-col>
      <v-col cols="12" sm="6" style="background-color: #fff">
        <v-container bg fill-height grid-list-md>
          <v-layout row wrap align-center>
            <v-row>
              <div class="pa-lg-16 pa-4">
                <h1>
                  ENCUENTRA NUEVAS CADENAS PRODUCTIVAS PARA VENDER MÁS, EN NUESTRA SECCIÓN DE NUEVOS CLIENTES
                </h1>
                <p class="subtitle">
                  Todos siempre estamos buscando nuevos clientes.  En esta sección te presentamos algunos, y si tú quieres sumarte a la lista <a href="https://forms.gle/ykqoaAiRZKs8mDzC6" target="_blank" style="color: #4b4b4b; font-weight: bold;">llena esta información.</a>
                  Seguimos trabajando para conseguir más clientes y para que puedas calificar a los que ya conoces. 
                  Queremos formar una comunidad segura de clientes y proveedores.

                </p>
                <router-link class="orange--text" to="/proveedores">Leer más</router-link>
              </div>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.imgDiv {
  margin: 0;
  padding: 0;
}
.test {
  border: solid 1px #f00;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
export default {
  name: "Informacion",
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-7.png"),
      },
    ],
  }),
};
</script>