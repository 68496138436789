<style scoped>
.titleGrey {
  color: #959595;
}
</style>
<template>
  <div>
    <v-snackbar 
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      color="red darken-1 white--text"
      absolute
      right
      top
    >
      <center>
        {{ snackbar.text }}
      </center>
    </v-snackbar>
    <v-container v-if="allow == 'permitido'">
      <h1 class="titleGrey text-center">Costos Cultivos</h1>
      <br />
      <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete
                v-model="headers[0].search"
                :items="cultivosOrdenados"
                dense
                label="Cultivo (Requerido)"
              ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
                v-model="headers[1].search"
                :items="estadosOrdenados"
                dense
                label="Estado (Requerido)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <v-autocomplete
                v-model="headers[2].search"
                :items="tecnologiaOrdenados"
                dense
                label="Tecnología"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3" class="text-center">
          <v-btn
          min-width="200px"
          class="orange lighten-2 white--text"
          @click="cultivosFiltrados"
          >
            Filtrar
          </v-btn>
        </v-col>
        </v-row>
      </v-container>
        <v-data-table
        :headers="headers"
        :items="filtrados"
        :items-per-page="5"
        fixed-header
        >
          <template slot="no-data">
            <span style="color: #FF5252;"> No hay registros disponibles para tu búsqueda </span>
          </template>
        </v-data-table>
        </v-card
      ><br />
    </v-container>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import { matchStrings } from "../../tools/proStrings";
import moment from "moment";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "CostosCultivos",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    newItem: "",
    busquedaCampo: "",
    snackbar: {
      open: false,
      text: "¡Texto copiado!",
      timeout: 2000,
    },
    filtrados: [],
    headers: [
      { text: "CULTIVO", value: "cultivo", search: "", class: "orange--text" },
      { text: "ESTADO", value: "estado", search: "", class: "orange--text" },
      { text: "TECNOLOGÍA", value: "tecnologia", search: "", class: "orange--text" },
      { text: "ZONA", value: "zona", search: "", class: "orange--text" },
      { text: "MODALIDAD", value: "modalidad", search: "", class: "orange--text" },
      { text: "CICLO AÑO", value: "cicloAno", search: "", class: "orange--text" },
      { text: "COSTO POR TON.", value: "costoUnitarioTon", search: "", class: "orange--text" },
      { text: "RENDIMIENTO EN TON.", value: "rendimientoProbableTon", search: "", class: "orange--text" },
    ],
    agrocostos: [],
    agrocostosFiltrados: [],
    cultivosSinOrdenar: [],
    estadosSinOrdenar: [],
    tecnologiaSinOrdenar: [],
  }),
  computed: {
    allow() {
      return localStorage.getItem("status");
    },
    cultivosOrdenados(){
      if(this.agrocostos.length > 0) {
        this.cultivosSinOrdenar.push("");
        return this.cultivosSinOrdenar.sort();
      } else {
        return this.cultivosSinOrdenar;
      }
    },
    estadosOrdenados(){
      this.estadosSinOrdenar.push("");
      if(this.agrocostos.length > 0) {
        return this.estadosSinOrdenar.sort();
      } else {
        return this.estadosSinOrdenar;
      }
    },
    tecnologiaOrdenados(){
      if(this.agrocostos.length > 0) {
        this.tecnologiaSinOrdenar.push("");
        return this.tecnologiaSinOrdenar.sort();
      } else {
        return this.tecnologiaSinOrdenar;
      }
    },
  },
  methods: {
    initialize() {
      this.getAgrocostos();
    },
    cultivosFiltrados() {
      this.filtrados = this.agrocostos;
      if(this.headers[0].search == "" || this.headers[1].search == ""){
        this.filtrados = [];
        this.openSnackbar('Ingrese el cultivo y el estado', 2000);
      } else {
        this.filtrados = this.filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.value] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      let db = firebase.firestore();
      let user = firebase.auth().currentUser;
      let oldBusqueda = localStorage.getItem("busquedaCampo");
      let query = this.headers[0].search + " en " + this.headers[1].search + " con " + this.headers[2].search;
      let myTime = moment().format("DD-MM-YYYY HH:mm:ss");
      //console.log("oldBusqueda " + oldBusqueda);
      //console.log("search " + query);
      if(query != "" && query != oldBusqueda){
        let record = {
        usuario: user.email,
        operacionRealizada: "Busqueda por filtrado",
        origen: "Tabla: Costos cultivos",
        busquedaCompleta: query,
        timeStamp: myTime
      };
      this.busquedaCampo = localStorage.setItem("busquedaCampo", query);
      db.collection("operaciones").add(record);
      }
      return this.filtrados;
      }
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
    async getAgrocostos() {
      let agrocostosAlmacenado = sessionStorage.getItem('agrocostos');
      if(agrocostosAlmacenado != "" && agrocostosAlmacenado != undefined) {
        this.agrocostos = JSON.parse(sessionStorage.getItem('agrocostos'));
        this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
        this.agrocostos.forEach( item => this.estadosSinOrdenar.push(item.estado));
        this.agrocostos.forEach( item => this.tecnologiaSinOrdenar.push(item.tecnologia));
        this.agrocostosFiltrados = this.agrocostos;
      } else { 
        let db = firebase.firestore();
        await db
        .collection("newAgrocostos")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.agrocostos.push(doc.data()));
        });
        this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
        this.agrocostos.forEach( item => this.estadosSinOrdenar.push(item.estado));
        this.agrocostos.forEach( item => this.tecnologiaSinOrdenar.push(item.tecnologia));
        this.agrocostosFiltrados = this.agrocostos;
        let parsed = JSON.stringify(this.agrocostos);
        sessionStorage.setItem('agrocostos', parsed);
      }
    },
    registrarBusqueda(origen, search) {
      let filtrados = this.agrocostos;
      filtrados = filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      this.agrocostosFiltrados = filtrados;
      let db = firebase.firestore();
      let user = firebase.auth().currentUser;
      //console.log(user.email);
      let oldBusqueda = localStorage.getItem("busquedaCampo");
      //console.log("oldBusqueda " + oldBusqueda);
      //console.log("search " + search);
      if(search != "" && search != oldBusqueda){
        let record = {
        usuario: user.email,
        operacionRealizada: "Busqueda por filtrado",
        origen: "Campo de: " + origen,
        busquedaCompleta: search
      };
      this.busquedaCampo = localStorage.setItem("busquedaCampo", search);
      //console.log(localStorage.getItem("busquedaCampo"));
      db.collection("operaciones").add(record);
      }
    },
  },
};
</script>

