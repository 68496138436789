<style scoped>
.titleGrey {
  color: #959595;
}
</style>
<template>
  <div>
    <v-container  v-if="allow == 'permitido'">
      <h1 class="titleGrey text-center">Seguros Agrícolas</h1>
      <br />
      <v-card>
        <v-col cols="12" lg="3" md="3" sm="12">
          <br/>
          <v-autocomplete 
            :items="['Agrícola', 'Ganadero',  'Otro', 'Paramétricos']"
            label="Categoría"
            v-model="categoria"
            dense
        ></v-autocomplete>
        </v-col>
        <v-simple-table style="max-width='700px'" height="450px" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left orange--text"
                  v-for="(header, ind) in headers"
                  :key="ind"
                >
                  {{ header["name"] }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cultivosFiltrados" :key="item.name">
                <td>{{ item.seguro }}</td>
                <td v-if="item.objetivo">{{ item.objetivo }}</td>
                <td v-else>N/A</td>
                <td v-if="item.definicion">{{ item.definicion }}</td>
                <td v-else>N/A</td>
                <td>{{ item.sumaAsegurada }}</td>
                <td v-if="item.garantía">{{ item.garantía }}</td>
                <td v-else>N/A</td>
                <td v-if="item.vigencia">{{ item.vigencia }}</td>
                <td v-else>N/A</td>
                <td v-if="item.riesgos">{{ item.riesgos }}</td>
                <td v-else>N/A</td>
                <td v-if="item.deducible">{{ item.deducible }}</td>
                <td v-else>N/A</td>
                <td v-if="item.indemnizacion">{{ item.indemnizacion }}</td>
                <td v-else>N/A</td>
                <td v-if="item.asegurables">{{ item.asegurables }}</td>
                <td v-else>N/A</td>
                <td v-if="item.funcionZootecnica">{{ item.funcionZootecnica }}</td>
                <td v-else>N/A</td>
                <td v-if="item.franquicia">{{ item.franquicia }}</td>
                <td v-else>N/A</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> </v-card
      ><br />
    </v-container>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import Bloqueo from "../Bloqueo.vue";
import { matchStrings } from "../../tools/proStrings";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "CostosCultivos",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    newItem: "",
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      password: [
        (v) => !!v || "El contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener un mínimo de 8 caracteres",
      ],
      phone: [],
      municipality: [],
      city: [],
      state: [],
      ocupacion: [],
      ganado: [],
      cultivos: [],
    },
    headers: [
      { name: "SEGURO", attr: "seguro", search: "" },
      { name: "OBJETIVO", attr: "objetivo", search: "" },
      { name: "DEFINICION", attr: "definicion", search: "" },
      { name: "SUMA ASEGURADA", attr: "sumaAsegurada", search: "" },
      {
        name: "GARANTIA",
        attr: "PresentacionComercial",
        search: "",
      },
      { name: "VIGENCIA", attr: "vigencia", search: "" },
      { name: "RIESGOS", attr: "riesgos", search: "" },
      { name: "DEDUCIBLE", attr: "deducible", search: "" },
      { name: "Indemnizacion", attr: "comoAplicaIndemnizacion", search: "" },
      { name: "ASEGURABLES", attr: "asegurables", search: "" },
      { name: "FUNCION ZOOTECNICA", attr: "funcionZootecnica", search: "" },
      { name: "FRANQUICIA", attr: "franquicia", search: "" },
    ],
    seguros: [],
    categoria: "Agrícola"
  }),
  computed: {
    cultivosFiltrados() {
      let filtrados = this.seguros.filter( seguro => {
        return this.categoria != "" ? seguro.categoria == this.categoria : "" 
      });
      filtrados = filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      return filtrados;
    },
    allow() {
      return localStorage.getItem("status");
    }
  },
  methods: {
    initialize() {
      this.getSeguros();
    },
    async getSeguros() {
      let db = firebase.firestore();
      await db
        .collection("seguros")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.seguros.push(doc.data()));
        });
    },
  },
};
</script>
