
import Home from "../components/paginas/Home.vue";
//import Edit from "../components/Pages/Edit.vue";
import Login from "../components/paginas/Login.vue";
import Register from "../components/paginas/Register.vue";
import PlantaEnferma from "../components/paginas/PlantaEnferma.vue";
import ParcelaSeleccionada from "../components/paginas/ParcelaSeleccionada.vue";
import Parcelas from "../components/paginas/Parcelas.vue";
import CostosCultivos from "../components/paginas/CostosCultivos.vue";
import IngresosCultivos from "../components/paginas/IngresosCultivos.vue";
import Commodities from "../components/paginas/Commodities.vue";
import Textos from "../components/paginas/Textos.vue";
import Productos from "../components/paginas/Productos.vue";
import ProductoSeleccionado from "../components/paginas/ProductoSeleccionado.vue";
import Seguros from "../components/paginas/Seguros.vue";
import Proveedores from "../components/paginas/Proveedores.vue";
import Admin from "../components/paginas/Admin.vue";
import Logistica from "../components/paginas/Logistica.vue";
import Clima from "../components/paginas/Clima.vue";
import Apoyos from "../components/paginas/Apoyos.vue";
import Politicas from "../components/paginas/Politicas.vue";
import Terminos from "../components/paginas/Terminos.vue";
import AdminParcelas from "../components/paginas/AdministrarParcelas.vue";
import AdminProductos from "../components/paginas/AdministrarProductos.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import firebase from 'firebase';
require('firebase/auth');

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: true }
  },
  {
    path: "/registrar",
    name: "Register",
    component: Register,
    meta: { requiresAuth: true }
  },
  {
    path: "/plantaEnferma",
    name: "PlantaEnferma",
    component: PlantaEnferma,
    meta: { requiresAuth: true }
  },
  {
    path: "/parcelaSeleccionada/:id",
    name: "ParcelaSeleccionada",
    component: ParcelaSeleccionada,
  },
  {
    path: "/parcelas",
    name: "Parcelas",
    component: Parcelas,
  },
  {
    path: "/textos",
    name: "Textos",
    component: Textos,
    meta: { requiresAuth: true }
  },
  {
    path: "/costosCultivos",
    name: "CostosCultivos",
    component: CostosCultivos,
    meta: { requiresAuth: true }
  },
  {
    path: "/ingresosCultivos",
    name: "IngresosCultivos",
    component: IngresosCultivos,
    meta: { requiresAuth: true }
  },
  {
    path: "/preciosCommodities",
    name: "Commodities",
    component: Commodities,
    meta: { requiresAuth: true }
  },
  {
    path: "/productos",
    name: "Productos",
    component: Productos,
    meta: { requiresAuth: true }
  },
  {
    path: "/productoSeleccionado/:id",
    name: "ProductoSeleccionado",
    component: ProductoSeleccionado,
  },
  {
    path: "/proveedores",
    name: "Proveedores",
    component: Proveedores,
    meta: { requiresAuth: true }
  },
  {
    path: "/seguros",
    name: "Seguros",
    component: Seguros,
    meta: { requiresAuth: true }
  },
  {
    path: "/logistica",
    name: "Logistica",
    component: Logistica,
    meta: { requiresAuth: true }
  },
  {
    path: "/clima",
    name: "Clima",
    component: Clima,
    meta: { requiresAuth: true }
  },
  {
    path: "/apoyos",
    name: "Apoyos",
    component: Apoyos,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: "Admin",
    component: Admin,
    meta: { requiresAdmin: true }
  },
  {
    path: "/avisoPrivacidad",
    name: "Politicas",
    component: Politicas
  },
  {
    path: "/terminos",
    name: "Terminos",
    component: Terminos
  },
  {
    path: "/misParcelas",
    name: "administrarParcelas",
    component: AdminParcelas
  },
  {
    path: "/misProductosServicios",
    name: "administrarProductos",
    component: AdminProductos
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  //const isAuthenticated = firebase.auth().currentUser;
  const isLogged = localStorage.getItem("isLogged");
    if (requiresAuth && isLogged != "Loged") {
      localStorage.setItem("status", "prohibido");
    } else {
      localStorage.setItem("status", "permitido");
    }
    next();
})
export default router;

