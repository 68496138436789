<template>
    <v-container>
      <v-card elevation="1">
        <v-card-title class="text-h4 justify-center orange--text">Ya has iniciado sesión</v-card-title>
        <v-card-text class="text-subtitle-1 text-center">
          Has iniciado sesión exitosamente, ya puedes navegar en la plataforma:
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green lighten-2"
            text
            to="/"
          >
            Home
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    </template>
    <script>
    export default {
        name: "Bloqueo",
        data: () => ({
            placeholder: ""
        })
    }
    </script>
    