<template>
  <v-row
    justify="center"
    align="center"
    class="white--text text-center myBanner"
    :style="{
      'background-image': 'url(' + image + `)`,
    }"
  >
    <slot></slot>
  </v-row>
</template>
<style scoped>
.myBtn {
  background-color: #fff;
  border: solid 2px #df8e04;
  text-transform: none;
}
.negritas {
  margin-right: 4px;
  font-weight: bold;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
}
</style>
<script>
export default {
  name: "Oportunidades",
  props: {
    image: {
      type: String,
      required: false,
      default: require("../../../assets/HOME-6.png"),
    },
  },
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-6.png"),
      },
    ],
  }),
};
</script>