<template>
  <v-container>
    <v-row justify="center" align="start" class="text-center myBanner">
      <v-col cols="12" class="pt-lg-16 pb-lg-16" v-if="latitude">
        <h1 class="font-weight-bold titulo naranja">CULTIVO MÁS RENTABLE</h1>
        <br/>
        <p class="text-justify-center">
          El cultivo más rentable según tu ubicación.
        </p>
        <center>
          <v-img
            :src="require('../../../assets/plant.png')"
            class="ma-3 text-center"
            contain
            width="160"
            max-width="120"
          />
        </center>

        <p> Ubicación de registro: {{ usuario.municipio + ", " + usuario.estado }}</p>
        <p> Cultivo recomendado: {{ cultivoEficiente.cultivo }}</p>
        <p> Ingreso promedio: ${{ cultivoEficiente.ingresoProbable }} MXN</p>
      </v-col>
      <v-col cols="12">
        <small>* Esta información esta disponible para los usuarios que compartieron sus datos de ubicación en el navegador.</small>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.titulo {
  font-size: 2.2rem;
}
.naranja {
  color: #e36900;
}
.myBtn {
  background-color: #fff;
  border: solid #fea100 2px;
  text-transform: none;
}
.negritas {
  margin-right: 4px;
  font-weight: bold;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
export default {
  name: "Cultivos",
  created() {
    this.initialize();
  },
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-6.png"),
      },
    ],
    cultivoEficiente: {},
    usuario: [],
    usuarios: [],
    agrocostos: [],
    filtrados: []
  }),
  computed: {
    latitude(){
      return sessionStorage.getItem('latitude');
    },
    longitude(){
      return sessionStorage.getItem('longitude');
    },
    mail(){
      return localStorage.getItem("mail");
    }
  },
  methods: {
    initialize(){
      this.getUser();
    },
    async getUser() {
      let db = firebase.firestore();
      await db.collection("usuarios").where("correo", "==", this.mail).get().then( snapshot => {
        snapshot.docs.forEach(doc => this.usuarios.push(doc.data()))
      });
      this.usuario = this.usuarios[0];
      let estadoUser = this.usuario.estado;
      console.log(estadoUser);
      this.getAgrocostos();
      this.filtrados = this.agrocostos.filter( costo => costo.estado.toLowerCase() == estadoUser.toLowerCase() );
      let numMayor = 0;
      this.cultivoEficiente = {};
      this.filtrados.map(cultivo => {
        if (cultivo.rendimientoProbableTon > numMayor) {
          numMayor = cultivo.rendimientoProbableTon;
          this.cultivoEficiente = cultivo;
        }
      });
      console.log(this.cultivoEficiente);
    },
    async getAgrocostos() {
      let agrocostosAlmacenado = sessionStorage.getItem('agrocostos');
      if(agrocostosAlmacenado != "" && agrocostosAlmacenado != undefined) {
        this.agrocostos = JSON.parse(sessionStorage.getItem('agrocostos'));
      } else {
          let db = firebase.firestore();
          await db
          .collection("newAgrocostos")
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => this.agrocostos.push(doc.data()));
          });
          let parsed = JSON.stringify(this.agrocostos);
          sessionStorage.setItem('agrocostos', parsed);
      }
    },
  }
};
</script>