
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import store from "./store";
//import Toasted from "vue-toasted";
//import { firestorePlugin } from "vuefire";

//Vue.prototype.$axios = axios;
//Vue.use(Toasted);
//Vue.use(firestorePlugin);
Vue.config.productionTip = false;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBNIlM2hRirNCyiNtjNxJohzwCpmZpfa8",
  authDomain: "agronome-59ef8.firebaseapp.com",
  projectId: "agronome-59ef8",
  storageBucket: "agronome-59ef8.appspot.com",
  messagingSenderId: "85453064304",
  appId: "1:85453064304:web:4368d0e8450c4ee2ee0cb5"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
db.settings({ timestapsInSnapshots: true});
//export const db = firebase.initializeApp(firebaseConfig).firestore();

new Vue({
  vuetify,
  render: (h) => h(App),
  router: router,
  store: store,
}).$mount("#app");
