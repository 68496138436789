<template>
  <div>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">
      <center>
        {{ snackbar.text }}
      </center>
    </v-snackbar>
    <v-row no-gutters style="min-height: 90vh">
      <v-col
        cols="12"
        sm="12"
        class="grey--text d-flex align-end justify-center"
      >
        <h1 style="margin-top: 8px;">{{ parcelaObtenida.parcela }}</h1>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center justify-center">
        <v-container class="pa-16">
          <v-carousel>
            <v-carousel-item
              v-for="(item, i) in parcelaObtenida.imagenes"
              :key="i"
              :src="`https://agenciaweb.mx/agronome/${item.name}`"
            ></v-carousel-item>
          </v-carousel>
        </v-container>
      </v-col>
      <v-col cols="12" sm="6">
        <v-container bg fill-height class="pa-0 ma-0">
          <v-layout row wrap align-center>
            <v-row>
              <v-col>
                <div class="pa-lg-16 pa-sm-4">
                  <h1 class="titulo">DATOS DEL TERRENO</h1>
                  <p class="subtitle">
                    {{ parcelaObtenida.descripcion }}
                  </p>
                  <h1 class="titulo">Contacto</h1>
                  <v-text-field
                    label="Correo"
                    v-model="parcelaObtenida.dueno"
                    outlined
                    readonly
                    append-icon="mdi-content-copy"
                    @click:append="
                      () => {
                        copyText(parcelaObtenida.dueno);
                        openSnackbar('¡Correo copiado al portapapeles!', 2000);
                      }
                    "
                  ></v-text-field>
                  <v-text-field
                    label="Teléfono"
                    v-model="parcelaObtenida.telefono"
                    outlined
                    readonly
                    append-icon="mdi-content-copy"
                    @click:append="
                      () => {
                        copyText(parcelaObtenida.telefono);
                        openSnackbar(
                          '¡Teléfono copiado al portapapeles!',
                          2000
                        );
                      }
                    "
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>
<style scoped>
.myImg {
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
}
.titulo {
  color: #ff670a;
}
.imgDiv {
  margin: 0;
  padding: 0;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
import Footer from "../Footer.vue";
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
export default {
  name: "ParcelaSeleccionada",
  components: {Footer},
  created() {
    this.initialize();
  },
  data: () => ({
    idParcela: null,
    items: [
      {
        src: require("../../assets/FORMULARIO-TU-PLANTA-ENFERMA.png"),
      },
    ],
    resultado: [],
    snackbar: {
      open: false,
      text: "Parcela registrada",
      timeout: 2000,
    },
    parcelaObtenida: {},
    parcela: {
      descripcion:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      correo: "correo@prueba.com",
      tel: "1234567890",
      precio: "200 500.00",
      imagenes2: [
        "https://picsum.photos/200",
        "https://picsum.photos/201",
        "https://picsum.photos/202",
      ],
    },
  }),
  methods: {
    initialize() {
      this.getParcela();
    },
    async getParcela() {
      // hacemos una consulta para obtener la parcela
      let db = firebase.firestore();
      await db
        .collection("parcelas")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) =>
            doc.data().id == this.$route.params.id ? this.resultado.push(doc.data()) : null );
          //this.getProveedor();
        });
      this.parcelaObtenida = this.resultado[0];
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
  },
};
</script>
