export default {
    namespaced: true,
    state: {
        agrocostosState: []
    },
    mutations: {
        agregarAgrocostos(state, payload) {
            state.agrocostosState = payload;
        }
    },
    actions: {

    },
    getters: {
        listAgrocostos: state => {
            return state.agrocostosState.length;
        }
    }
}