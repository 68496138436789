<template>
  <div style="overflow-x: hidden;">
    <v-row no-gutters style="min-height: 100vh">
      <v-col cols="12" sm="12" md="6" style="background-color: #e1edba">
        <v-container bg fill-height class="pa-0 ma-0">
          <v-layout row wrap align-center>
            <v-row>
              <div class="pa-lg-16 pa-9">
                <h1>CONSULTA LA ENFERMEDAD DE TUS CULTIVOS</h1>
                <p class="subtitle">
                  Tus plantas se enfermaron? Mándanos una foto y nosotros te recomendamos los productos y cuidados que necesitan para sanar.
                  Recuerda que todos nuestros servicios son completamente Gratis.

                </p>
                <router-link class="orange--text" to="/plantaEnferma">Envíanos un mensaje</router-link>
              </div>
            </v-row>
          </v-layout>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        sm="12" 
        md="6"
        class="white--text myBanner"
        :style="{
          'background-image': 'url(' + items[0].src + `)`,
        }"
      ></v-col>
    </v-row>
  </div>
</template>
<style scoped>
.imgDiv {
  margin: 0;
  padding: 0;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
export default {
  name: "Bienvenido",
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-5.png"),
      },
    ],
  }),
};
</script>