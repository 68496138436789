var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.open),callback:function ($$v) {_vm.$set(_vm.snackbar, "open", $$v)},expression:"snackbar.open"}},[_c('center',[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1),_c('v-row',{staticStyle:{"min-height":"90vh"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"grey--text d-flex align-end justify-center",attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticStyle:{"margin-top":"8px"}},[_vm._v(_vm._s(_vm.producto.producto))])]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","sm":"6"}},[_c('v-container',{staticClass:"pa-10"},[_c('v-carousel',_vm._l((_vm.producto.imagenes),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":("https://agenciaweb.mx/agronome/" + (item.name))}})}),1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"bg":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"pa-lg-16 pa-sm-4"},[(_vm.producto.descripcion != '')?_c('div',[_c('h1',{staticClass:"titulo"},[_vm._v("DATOS")]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.producto.descripcion)+" ")]),(_vm.producto.tipo = 'Producto')?_c('p',{staticClass:"subtitle"},[_vm._v(" Cantidad: "+_vm._s(_vm.producto.cantidad + " " + _vm.producto.unidad)+" ")]):_vm._e(),_c('p',{staticClass:"subtitle"},[_vm._v(" Precio: "+_vm._s(_vm.producto.precio)+" ")])]):_vm._e(),_c('h1',{staticClass:"titulo"},[_vm._v("Contacto")]),_c('v-text-field',{attrs:{"label":"Correo","outlined":"","readonly":"","append-icon":"mdi-content-copy"},on:{"click:append":function () {
                      _vm.copyText(_vm.producto.proveedor);
                      _vm.openSnackbar('¡Correo copiado al portapapeles!', 2000);
                    }},model:{value:(_vm.producto.proveedor),callback:function ($$v) {_vm.$set(_vm.producto, "proveedor", $$v)},expression:"producto.proveedor"}}),_c('v-text-field',{attrs:{"label":"Teléfono","outlined":"","readonly":"","append-icon":"mdi-content-copy"},on:{"click:append":function () {
                      _vm.copyText(_vm.producto.telefono);
                      _vm.openSnackbar(
                        '¡Teléfono copiado al portapapeles!',
                        2000
                      );
                    }},model:{value:(_vm.producto.telefono),callback:function ($$v) {_vm.$set(_vm.producto, "telefono", $$v)},expression:"producto.telefono"}})],1)])],1)],1)],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }