<style scoped>
.titleGrey {
  color: #959595;
}
.myContainer{
    margin: 15px;
}
</style>
<template>
  <div>
    <div class="myContainer"  v-if="allow == 'permitido'">
      <h1 class="titleGrey text-center">Logística</h1>
      <br />
      <v-card>
        <v-data-table
          :headers="headers"
          :items="empresas"
          :items-per-page="5"
          fixed-header
        ></v-data-table>
      </v-card
      >
      <br />
    </div>
    <Bloqueo v-else/>
    <Footer />
  </div>
</template>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import { matchStrings } from "../../tools/proStrings";
import Bloqueo from "../Bloqueo.vue";
require("firebase/auth");
require("firebase/firestore");

export default {
  name: "CostosCultivos",
  created() {
    this.initialize();
  },
  components: {
    Footer,
    Bloqueo
  },
  data: () => ({
    drawer: null,
    valid: false,
    isDisabled: false,
    newItem: "",
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      password: [
        (v) => !!v || "El contraseña es requerida",
        (v) =>
          (v && v.length >= 8) ||
          "La contraseña debe tener un mínimo de 8 caracteres",
      ],
      phone: [],
      municipality: [],
      city: [],
      state: [],
      ocupacion: [],
      ganado: [],
      cultivos: [],
    },
    headers: [
      { text: "Nombre empresa", value: "nombreEmpresa", class: "orange--text" },
      { text: "Actividad", value: "actividad", search: "", class: "orange--text" },
      { text: "Origen", value: "estado", search: "", class: "orange--text" },
      {
        text: "Dirección",
        value: "direccion",
        search: "",
        class: "orange--text"
      },
      { text: "Teléfono", value: "telefono", search: "", class: "orange--text" },
      { text: "Servicio", value: "servicio", search: "", class: "orange--text" },
      { text: "Especificaciones", value: "especificaciones", search: "", width: "180px", class: "orange--text" },
      { text: "Correo", value: "correo", search: "", class: "orange--text" },
      { text: "Página", value: "pagina", search: "", class: "orange--text" },
    ],
    empresas: [],
  }),
  computed: {
    cultivosFiltrados() {
      this.empresas.map((item, i) => {
        if (this.empresas[i].IngresoProbable != undefined)
          this.empresas[i].IngresoProbable =
            this.empresas[i].IngresoProbable.toFixed(2);
      });
      let filtrados = this.empresas;
      filtrados = filtrados.filter((hoja) => {
        let isIn = false;
        let countMatches = 0;
        this.headers.map((header) => {
          if (matchStrings({ s: header.search, str: hoja[header.attr] }))
            countMatches++;
          else if (header.search == "") {
            countMatches++;
          }
        });
        if (countMatches == this.headers.length) isIn = true;
        return isIn;
      });
      return filtrados;
    },
    allow() {
      return localStorage.getItem("status");
    }
  },
  methods: {
    initialize() {
      this.getEmpresas();
    },
    async getEmpresas() {
      let db = firebase.firestore();
      await db
        .collection("logistica")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => this.empresas.push(doc.data()));
        });
    },
  },
};
</script>

