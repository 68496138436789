<style>
@font-face {
  font-family: "MontserratB";
  src: local("MontserratB"),
   url(./fonts/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "MontserratR";
  src: local("MontserratR"),
   url(./fonts/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "KelsonB";
  src: local("KelsonB"),
   url(./fonts/KelsonSansBG-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Kelson";
  src: local("Kelson"),
   url(./fonts/KelsonSansBG-Normal.ttf) format("truetype");
}
.v-application .text-h2, .text-h1 {
  font-family: "KelsonB" !important;
}
.v-application .text-h3 {
  font-family: "Kelson" !important;
}
.v-application .text-h5 {
  font-family: "MontserratR" !important;
}
.v-application h1{
  font-family: "MontserratB" !important;
  color: #4d4d4d;
}
.v-application h1.secondOption{
  font-family: "KelsonB" !important;
}
.v-application p{
  font-family: "MontserratR" !important;
}
</style>
<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";

export default {
  name: "App",

  components: {
    Navbar,
  },

  data: () => ({
    drawer: null,
    usuarioAdmin: {
      nombre: "Placeholder"
    }
  }),
};
</script>