<style scoped>
.line-green {
  border-top: solid #6d9f29 6px !important;
}
.linkNav {
  display: block;
}
.red_list .v-list-item-group .v-list-item--active{
  background-color: red;
  color: white;
}
@media only screen and (max-width: 700px) {
  .mob {
    display: none;
  }
}
@media only screen and (min-width: 701px) {
  .mob {
    display: inline-block;
  }
}
</style>
<template>
  <div style="" v-if="this.$route.name !== 'Admin'">
    <v-app-bar app color="grey lighten-2" class="line-green" flat >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row justify="space-between" align="center">
        <v-col cols="4">
          <router-link to="/">
          <v-img
            :src="require('../assets/logotipo.png')"
            class="ma-3"
            contain
            width="160"
            max-height="40"
            max-width="120"
          />
          </router-link>
        </v-col>
        <v-col cols="8" class="text-right">
          <div 
            style=" vertical-align: middle; margin-right: 5px;" 
            class="mob"
            v-if="this.weatherPrecise.weather[0].icon != ''"
            >
            <v-img
              :src="`${this.imageUrlBase}${this.weatherPrecise.weather[0].icon}@2x.png`"
              alt="Weather"
              width="48px"
            />
          </div>
          <div style=" margin-right: 5px;" class="mob">
            {{ weatherPrecise.name }} | {{ Math.round(weatherPrecise.main.temp) }}°
          </div>
          <div style="display: inline-block;" v-if="loggedIn">
            {{ displayName }}
          </div>
          <v-btn icon @click="drawer2 = !drawer2" style="display: inline-block;">
            <v-icon color="orange">mdi-account-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list>
        <v-list-item-group color="orange">
        <v-list-item v-for="(link, n) in links" :key="`link${n}`" :to="link.to">
          {{ link.name }}
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawer2" fixed temporary right>
      <v-list>
        <v-list-item-group color="orange">
          <v-list-item to="/Login" v-if="!loggedIn">
            Iniciar sesión
          </v-list-item>
          <v-list-item to="/registrar" v-if="!loggedIn">
            Regístrate
          </v-list-item>
          <v-list-item to="/misParcelas" v-if="loggedIn">
            Mis parcelas
          </v-list-item>
          <v-list-item to="/misProductosServicios" v-if="loggedIn">
            Mis productos / servicios
          </v-list-item>
          <v-list-item @click="signOut" v-if="loggedIn">
            Cerrar sesión
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style scoped>
.miBoton {
  border: solid #fea100 2px;
}
</style>
<script>
import firebase from "firebase";
require("firebase/auth");
export default {
  name: "NavBar",
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.loggedIn = !!user;
      user != null ?  localStorage.setItem("mail", user.email) : localStorage.setItem("mail", "") ;
      user != null ?  localStorage.setItem("displayName", user.displayName) : localStorage.setItem("displayName", "") ;
      user != null ?  localStorage.setItem("uId", user.uid) : localStorage.setItem("uId", "") ;
      if(this.loggedIn) {
        this.getUserData();
      }
    });
    this.getPosition();
    this.initialize();
  },
  data: () => ({
    drawer: null,
    drawer2: null,
    loggedIn: false,
    newItem: "",
    myUser: "",
    options: {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    },
    ubicacion: "",
    user: {
      email : ""
    }, 
    valid: false,
    links: [
      { to: "/", name: "Inicio" },
      { to: "/costosCultivos", name: "Costos de cultivos" },
      { to: "/ingresosCultivos", name: "Ingresos por cultivos" },
      { to: "/preciosCommodities", name: "Precios agrícolas" },
      { to: "/proveedores", name: "Cadenas productivas" },
      { to: "/productos", name: "Productos y servicios" },
      { to: "/parcelas", name: "Parcelas" },
      { to: "/plantaEnferma", name: "Planta enferma" },
      { to: "/textos", name: "Cultivo más rentable" },
      { to: "/seguros", name: "Seguros agrícolas" },
      { to: "/logistica", name: " Logistica" },
      { to: "/clima", name: "Clima" },
      { to: "/apoyos", name: "Apoyos Gubernamentales" },
    ],
    user: {
      name: "",
      password: "",
    },
    usuario: [],
    usuarios:[],
    rules: {
      user: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
    },
    apiKey: "981f9ed11f354ec37864394a18c6dd99",
    urlBase: "https://api.openweathermap.org/data/2.5/",
    imageUrlBase: "https://openweathermap.org/img/wn/",
    query: "Mérida",
    lat:"20.9667",
    lon:"-89.6167",
    date: "",
    diaHoy: "",
    weather: {},
    weatherPrecise: {
      main: {
        temp: 0
      },
      weather: [{
        icon: "",
      }]
    },
  }),
  computed: {
    userMail() {
      return localStorage.getItem("mail");
    },
    displayName() {
      return localStorage.getItem("displayName");
    },
    latitude(){
      let myLatitude = sessionStorage.getItem('latitude');
      if(myLatitude != "" && myLatitude != undefined) {
        return sessionStorage.getItem('latitude');
      } else {
        return "";
      }
    },
    longitude(){
      let myLongitude = sessionStorage.getItem('longitude');
      if(myLongitude != "" && myLongitude != undefined) {
        return sessionStorage.getItem('longitude');
      } else {
        return "";
      }
    },
  },
  methods: {
    async signOut() {
      try {
        const data = await firebase.auth().signOut();
        //console.log(data);
        localStorage.setItem("isLogged", "");
        localStorage.setItem("mail", "");
        console.log(this.$route.name);
        if(this.$route.name == "Home"){
          this.$router.go();
        } else {
          this.$router.replace({ name: "Home" });
        }
      } catch (err) {
        console.log(err);
      }
    },
    success(pos) {
      var crd = pos.coords;
      sessionStorage.setItem('latitude', crd.latitude);
      sessionStorage.setItem('longitude', crd.longitude);
      sessionStorage.setItem('accuracy', crd.accuracy);
    },
    async getUserData() {
      let db = firebase.firestore();
      await db.collection("usuarios").get().then( snapshot => {
        snapshot.docs.forEach(doc => this.usuarios.push(doc.data()))
      });
      this.usuario = this.usuarios.filter( usuario => usuario.correo == this.userMail);
      //localStorage.setItem("name", this.usuario[0].nombre);
    },
    error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    },
    getPosition() {
      this.ubicación = navigator.geolocation.getCurrentPosition(this.success, this.error, this.options);
    },
    preciseWeather () {
      setTimeout(() => {
        if (this.latitude != "" && this.longitude != "") {
        fetch(`${this.urlBase}weather?lat=${this.latitude}&lon=${this.longitude}&units=metric&APPID=${this.apiKey}`)
        .then(res => {
        return res.json();
        }).then(this.setResultsPrecise);
      }
      }, 2000);
    },
    setResultsPrecise(results){
      if(results.cod == '400') {
      } else {
         this.weatherPrecise = results;
      }
    },
    initialize(){
      this.preciseWeather();
    }
  },
};
</script>
