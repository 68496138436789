<template>
    <div>
        <v-row style="margin: 5px;" v-if="allow == 'permitido'">
            <v-col cols="12">
                <h1 class="text-center">Apoyos gubernamentales</h1><br>
                <p class="text-subtitle-1 text-center">
                    Descubre los planes de apoyos que se ajustan más al ámbito en el que te encuentras.
                    <br>
                    Contesta las siguientes preguntas que ayudarán a perfilar las recomendaciones.
                </p>
                <v-card >
                    <v-row style="margin: 5px;">
                        <v-col cols="12" md="3" sm="12">
                            <v-autocomplete
                                label="¿Cuál es su producto / cultivo?"
                                dense
                                :items="listaProductos"
                                item-text="producto"
                                item-value="producto"
                                @change="setClass()"
                                v-model="pregunta1"
                                return-object
                                outlined
                                id="pregunta1"
                            ></v-autocomplete>
                        </v-col> 
                        <v-col cols="12" md="3" sm="12">
                            <v-autocomplete
                              label="Seleccione el tamaño de productor al que pertenece"
                              dense
                              :items="opcionesPregunta2"
                              v-model="pregunta2"
                              outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <v-autocomplete
                              label="Estado"
                              dense
                              :items="nombresEstados"
                              item-text="nombre"
                              item-value="nombre"
                              v-model="pregunta3"
                              outlined
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <v-btn @click="filtrarResultados" color="orange" class="white--text" elevation="0">
                                Filtrar
                            </v-btn>
                        </v-col>

                    <v-col cols="12">
                        <v-data-table
                        :headers="headers"
                        :items="filtrados"
                        :items-per-page="5"
                        fixed-header
                        >
                        <template slot="no-data">
                            <span style="color: #FF5252;"> No hay registros disponibles para tu búsqueda </span>
                        </template>
                        <template v-slot:item.avisosDocumentos="{ item }">
                            <a :href="item.avisosDocumentos" style="text-decoration: none;" target="_blank">
                                <v-btn
                                medium
                                class="mr-2 orange--text"
                                elevation="0"
                                >
                                    ir al enlace
                                </v-btn>
                            </a>
                        </template>
                        <template v-slot:item.reglasOperacion="{ item }">
                            <a :href="item.reglasOperacion" style="text-decoration: none;" target="_blank">
                                <v-btn
                                medium
                                class="mr-2 orange--text"
                                elevation="0"
                                >
                                    ir al enlace
                                </v-btn>
                            </a>
                        </template>
                        </v-data-table>
                    </v-col>

                    </v-row>
            </v-card>
            </v-col>
        </v-row>
        <Bloqueo v-else/>
        <Footer />
    </div>
</template>
<script>
import Bloqueo from "../Bloqueo.vue";
import Footer from "../Footer.vue";
import firebase from "firebase/app";
import { matchStrings } from "../../tools/proStrings";
import { estados } from '../../tools/estados';
require("firebase/auth");
require("firebase/firestore");

export default {
    data: () => ({
        apoyos: [],
        pregunta1: {},
        pregunta2: "",
        pregunta3: "",
        isGanaderia: false,
        isAgro: false,
        isPesca: false,
        filtrados: [],
        opcionesPregunta2: [],
        listaProductos: [
          { producto: "AGAVE", clase: "agro" },
          { producto: "AGUACATE", clase: "agro" },
          { producto: "AJO", clase: "agro" },
          { producto: "AJONJOLÍ", clase: "agro" },
          { producto: "ALFALFA", clase: "agro" },
          { producto: "ALGODÓN", clase: "agro" },
          { producto: "AMARANTO", clase: "agro" },
          { producto: "ARROZ", clase: "agro" },
          { producto: "ARÁNDANO", clase: "agro" },
          { producto: "AVENA", clase: "agro" },
          { producto: "AVICULTURA", clase: "ganaderia" },
          { producto: "BOVINO", clase: "ganaderia" },
          { producto: "BRÓCOLI", clase: "agro" },
          { producto: "CACAHUATE", clase: "agro" },
          { producto: "CACAO", clase: "agro" },
          { producto: "CAFÉ CEREZA", clase: "agro" },
          { producto: "CAMARON", clase: "pesca" },
          { producto: "CAPRINO", clase: "ganaderia" },
          { producto: "CAÑA", clase: "agro" },
          { producto: "CEBADA", clase: "agro" },
          { producto: "CHIA", clase: "agro" },
          { producto: "CHILE ANCHO", clase: "agro" },
          { producto: "CHILE JALAPEÑO", clase: "agro" },
          { producto: "CHILE PIMIENTO", clase: "agro" },
          { producto: "CHILE SECO", clase: "agro" },
          { producto: "CHILE", clase: "agro" },
          { producto: "CUNICULTURA", clase: "ganaderia" },
          { producto: "CÁRTAMO", clase: "agro" },
          { producto: "DURAZNO", clase: "agro" },
          { producto: "DÁTIL", clase: "agro" },
          { producto: "EQUINO", clase: "ganaderia" },
          { producto: "ESPÁRRAGO", clase: "agro" },
          { producto: "FRAMBUESA", clase: "agro" },
          { producto: "FRESA", clase: "agro" }, 
          { producto: "FRIJOL", clase: "agro" },
          { producto: "GARBANZO", clase: "agro" },
          { producto: "GIRASOL", clase: "agro" },
          { producto: "GRANADA ROJA", clase: "agro" },
          { producto: "HIGUERILLA", clase: "agro" },
          { producto: "JITOMATE", clase: "agro" },
          { producto: "LECHE", clase: "leche" },
          { producto: "LIMÓN MEXICANO", clase: "agro" },
          { producto: "LIMÓN PERSA", clase: "agro" },
          { producto: "LIMÓN", clase: "agro" },
          { producto: "MAIZ", clase: "agro" },
          { producto: "MANGO", clase: "agro" },
          { producto: "MANZANO", clase: "agro" },
          { producto: "MELON", clase: "agro" },
          { producto: "MIEL", clase: "miel" },
          { producto: "NARANJO", clase: "agro" },
          { producto: "NOGAL", clase: "agro" },
          { producto: "OSTION", clase: "ostion" },
          { producto: "OVINO", clase: "ganaderia" },
          { producto: "PALMA ACEITERA", clase: "agro" },
          { producto: "PALMA DE COCO", clase: "agro" },
          { producto: "PAPA", clase: "agro" },
          { producto: "PAPAYA", clase: "agro" },
          { producto: "PIÑA", clase: "agro" },
          { producto: "PLATANO", clase: "agro" },
          { producto: "PORCINO", clase: "ganaderia" },
          { producto: "SANDIA", clase: "agro" },
          { producto: "SORGO", clase: "agro" },
          { producto: "SOYA", clase: "agro" },
          { producto: "TILAPIA", clase: "pesca" },
          { producto: "TOMATE", clase: "agro" },
          { producto: "TRIGO", clase: "agro" },
          { producto: "TRUCHA", clase: "pesca" },
          { producto: "VID", clase: "agro" },
          { producto: "ZARZAMORA", clase: "agro" },
        ],

        preguntas: [
            { text: "Pregunta 1", value: "beneficiario", search: "", class: "orange--text" },
            { text: "Pregunta 2", value: "escalaProductor", search: "", class: "orange--text" },
            { text: "Pregunta 3", value: "estado", search: "", class: "orange--text" }
        ],
        headers: [
            { text: "NOMBRE DEL APOYO", value: "nombreApoyo", search: "", class: "orange--text" },
            { text: "TAMAÑO DEL PRODUCTOR", value: "tamanoProductor", search: "", class: "orange--text" },
            { text: "UNIDADES", value: "unidades", search: "", class: "orange--text" },
            { text: "TIPO DE APOYO", value: "tipoApoyo", search: "", class: "orange--text" },
            { text: "CANTIDAD", value: "cantidad", search: "", class: "orange--text" },
            { text: "Valor", value: "valor", search: "", class: "orange--text" },
            { text: "AVISOS DE DOCUMENTOS", value: "avisosDocumentos", search: "", class: "orange--text" },
            { text: "REGLAS DE OPERACIÓN", value: "reglasOperacion", search: "", class: "orange--text" },
        ],
    }),
    created(){
        this.initialize();
    },
    components: {
        Footer,
        Bloqueo
    },
    methods: {
        async getApoyos() {
            let db = firebase.firestore();
            await db
                .collection("apoyosGubernamentales")
                .get()
                .then((snapshot) => {
                snapshot.docs.forEach((doc) => this.apoyos.push(doc.data()));
                });
        },
        setClass() {
            if(this.pregunta1.clase == "agro") {
                this.opcionesPregunta2 = [
                    "Pequeño (0-5 Ha)",
                    "Mediano (5-20 Ha)",
                    "Grande (> 20 Ha)",
                    "NA",
                    "Grupos",
                    "Comités Nacionales",
                    "Personas físicas",
                    "Todos"
                ]
            }
            if(this.pregunta1.clase == "ganaderia") {
                this.opcionesPregunta2 = [
                    "Pequeño (5-30 Cabezas)",
                    "Comités Nacionales",
                    "Todos"
                ]
            }
            if(this.pregunta1.clase == "pesca") {
                this.opcionesPregunta2 = [
                    "Personas fisicas",
                    "Pequeño (10 toneladas)",
                    "Comités Nacionales",
                    "Todos"
                ]
            }
            if(this.pregunta1.clase == "ostion") {
                this.opcionesPregunta2 = [
                    "Pequeño (500,000 piezas)",
                ]
            }
            if(this.pregunta1.clase == "leche") {
                this.opcionesPregunta2 = [
                    "Pequeño (25 litros x vaca)",
                    "Personas fisicas",
                    "Comités Nacionales",
                    "Todos"
                ]
            }
            if(this.pregunta1.clase == "miel") {
                this.opcionesPregunta2 = [
                    "Pequeño (0-35 colmenas)",
                    "Mediano (35-100 colmenas)",
                    "Comités Nacionales",
                    "Todos"
                ]
            }

        },
        filtrarResultados() {
            this.resultados = this.apoyos;
            if((this.pregunta1 != "" && this.pregunta2 != "") && this.pregunta3 != "") {
                //pregunta 1
                let clase = this.pregunta1.clase;
                let nombre = this.pregunta1.producto.toLowerCase();
                if (clase == "agro") {
                    this.filtrados = this.apoyos.filter( apoyo => (apoyo.producto.includes(nombre) || apoyo.producto == "agro") || apoyo.producto == "global");
                }
                else if (clase == "ganaderia") {
                    this.filtrados = this.apoyos.filter( apoyo => apoyo.producto == "ganaderia" || apoyo.producto == "global");
                }
                else if (clase == "pesca") { 
                    this.filtrados = this.apoyos.filter( apoyo => (apoyo.producto.includes(nombre) || apoyo.producto == "pesca") || apoyo.producto == "global");
                }
                else if (clase == "ostion") {
                    this.filtrados = this.apoyos.filter( apoyo => ( apoyo.producto == "ostion") || apoyo.producto == "global");
                }
                else if (clase == "leche") { 
                    this.filtrados = this.apoyos.filter( apoyo => ( apoyo.producto == "leche") || apoyo.producto == "global");
                }
                else if (clase == "miel") { 
                    this.filtrados = this.apoyos.filter( apoyo => ( apoyo.producto == "miel") || apoyo.producto == "global");
                }
                let entidad = this.pregunta3.toLowerCase();
                this.filtrados = this.filtrados.filter(filtrado => filtrado.filtrarTamano == this.pregunta2 && (filtrado.entidad.includes(entidad) ||  filtrado.entidad == "todas"));

            } else {
                console.log("Llenar los 3 campos de preguntas");
            }
        },
        initialize(){
            this.nombresEstados = estados;
            this.getApoyos();
        },
    },
    computed: {
        allow() {
            return localStorage.getItem("status");
        },
        apoyosFiltrados() {
            let filtrados = this.apoyos;
            if((this.preguntas[0].search == "" && this.preguntas[1].search == "") && this.preguntas[2].search == ""){
                filtrados = [];
            } else {
                filtrados = filtrados.filter((hoja) => {
                let isIn = false;
                let countMatches = 0;
                this.preguntas.map((pregunta) => {
                if (matchStrings({ s: pregunta.search, str: hoja[pregunta.value] }))
                    countMatches++;
                else if (pregunta.search == "") {
                    countMatches++;
                }
                });
                if (countMatches == this.preguntas.length) isIn = true;
                return isIn;
            });
            }
            return filtrados;
        },
    }
}
</script>
