<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  padding: 4px;
}
.img-row {
  width: 50px;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}
.errorImagenes {
  color: #a00;
}
</style>
<template>
    <div class="mt-10">
      <v-snackbar 
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      fixed
      right
      top
    >
      <center>
        {{ snackbar.text }}
      </center>
    </v-snackbar>

        <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
    >
      <v-card>
        <v-form ref="form" v-model="valid">
        <v-card-title>
          <span class="text-h5" v-if="!isEditing">Agregar Parcela</span>
          <span class="text-h5" v-else>Editar Parcela</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Titulo (Requerido)"
                  v-model="parcela.parcela"
                  :rules="rules.parcela"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Dirección (Requerido)"
                  v-model="parcela.direccion"
                  :rules="rules.direccion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-file-input
                  id="campoImagenes"
                  accept=".jpg,.jpeg,.png"
                  small-chips
                  multiple
                  label="Subir imágenes (Mínimo requerido 1 imagen de hasta 800 KB)"
                  v-model="arrayImagenes"
                  :show-size="1000"
                  prepend-icon="mdi-camera"
                  :error="imagesError"
                  @change="subirImagenes()"
              ></v-file-input>
              <div id="zonaImagenes">
                <v-row>
                  <div class="col-12">
                    <draggable
                      :list="list"
                      :disabled="!enabled"
                      class="list-group"
                      ghost-class="ghost"
                      :move="checkMove"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <div
                        class="list-group-item"
                        v-for="element in list"
                        :key="element.name"
                        :id="element.name"
                      >
                      <img :src="'https://www.agenciaweb.mx/agronome/' + element.name" class="img-row" alt="">
                        {{ element.originalName}}
                      <span >
                          <v-icon
                            medium
                            color="error"
                            @click="deleteImage(element.name)"
                          >
                            mdi-delete
                          </v-icon>
                        </span>
                      </div>
                    </draggable>
                  </div>
                </v-row>
              </div>
              <div id="errorImagenes" class="errorImagenes">
                <p style='color: #FF5252;'>{{ textImagesError }}</p>
              </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="Descripción"
                  v-model="parcela.descripcion"
                  :rules="rules.descripcion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  label="Estado"
                  :items="this.nombresEstados"
                  item-text="nombre"
                  item-value="nombre"
                  v-model="parcela.estado"
                  :rules="rules.estado"
                  ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="display: none;"> 
                <v-text-field
                  label="(Georeferencia)"
                  v-model="parcela.georreferencia"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Área del predio"
                  type="number"
                  v-model="parcela.area"
                  :min="0"
                  :rules="rules.area"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6" 
                md="6"
              >
                <v-select
                  :items="['Ha', 'm²']"
                  label="Unidad"
                  v-model="parcela.unidad"
                  :rules="rules.unidad"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6" 
                md="6"
              >
                <v-select
                  :items="['En venta', 'En renta']"
                  label="Status de Venta o renta (Requerido)"
                  v-model="parcela.ventaRenta"
                  :rules="rules.ventaRenta"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Precio de compra / renta"
                  type="number"
                  v-model="parcela.precio"
                  :rules="rules.precio"
                  :min="0"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6" 
                md="6"
              >
                <v-select
                  label="Vocación del predio"
                  :items="cultivosOrdenados"
                  v-model="parcela.vocacion"
                  :rules="rules.vocacion"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6" 
                md="6"
              >
                <v-select
                  :items="['Riego artificial', 'Temporal']"
                  label="Tipo de riego"
                  v-model="parcela.tipoRiego"
                  :rules="rules.tipoRiego"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Teléfono de contacto"
                  v-model="parcela.telefono"
                  :rules="rules.telefono"
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Correo electrónico"
                  v-model="parcela.dueno"
                  :rules="rules.correo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click=" () => { 
              dialog = false;
              this.textImagesError = '';
              this.imagesError = false;
            }"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="isDisabled"
            @click=" () => {  
              addParcela();
            }"
            v-if="!isEditing"
          >
            Registrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="isDisabled"
            @click=" () => {  
              editar(parcela.rId);
            }"
            v-else
          >
            Editar
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
        <v-container v-if="allow == 'permitido'">
            <h1 class="titleGrey text-center">Mis parcelas</h1>
            <br />
            <v-row>
                <v-col cols="12" md="10"></v-col>
                <v-col cols="12" md="2">
                    <v-btn
                        @click="preAddParcela"
                        class="white--text place-btn outlined orange text-right ma-4"
                        >
                        Agregar parcela
                    </v-btn>
                </v-col>
            </v-row>
            <v-card>
                <v-data-table
                :headers="headers"
                :items="parcelasUser"
                :items-per-page="5"
                fixed-header
                >
                <template v-slot:item.imagenes[0].name ="{ item }">
                    <div class="pa-2">
                        <v-img :src="`https://www.agenciaweb.mx/agronome/${item.imagenes[0].name}`" :alt="item.imagenes[0].name" width="80px"></v-img>
                    </div>
                </template>
                <template v-slot:item.extension = "{ item }">
                  <div style="min-width: 130px;">
                    {{ item.area + " " + item.unidad }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        medium
                        color="info"
                        class="mr-2"
                        @click=" () => { dialog = !dialog; abrirEditar(item.rId); isEditing = true; }"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        medium
                        color="error"
                        @click=" () => { 
                          deleteIndex = item.rId;
                          deleteOpen(item);
                        }"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                </v-data-table>
            </v-card>
            <v-dialog
              v-model="doubleCheck"
              persistent
              max-width="600"
            >
              <v-card>
              <v-card-title class="text-h5">
                ¿Estás seguro de eliminar esta parcela?
              </v-card-title>
                        <v-card-text>Una vez eliminado este registro ya no aparecerá en tu panel de administración.</v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="doubleCheck = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="red darken-1"
                            text
                            @click="deleteParcela()"
                          >
                            Eliminar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
        </v-container>
        <Footer />
    </div>
</template>
<script>
import Footer from "../Footer.vue";
import firebase from "firebase/app";
import { estados } from '../../tools/estados';
import { generateRandomId } from "../../tools/randomStrGus";
import axios from "axios";
import draggable from 'vuedraggable';
import moment from 'moment';
export default {
    name: "AdministrarParcelas",
    data: () => ({
        valid: false,
        imagesError: false,
        isDisabled: false,
        dialog: false,
        doubleCheck: false,
        isEditing: false,
        enabled: true,
        textImagesError: "",
        deleteIndex: "",
        rules: {
          parcela: [
            (v) => !!v || "El titulo es requerido",
            (v) =>
              (v && v.length >= 10) ||
              "El título debe tener un mínimo de 10 caracteres",
          ],
          direccion: [
            (v) => !!v || "La dirección es requerida",
            (v) =>
              (v && v.length >= 30) ||
              "La dirección debe tener un mínimo de 30 caracteres",
          ],
          descripcion: [
            (v) => !!v || "La descripción es requerida",
            (v) =>
              (v && v.length >= 30) ||
              "La descripcion debe tener un mínimo de 30 caracteres",
          ],
          estado: [
            (v) => !!v || "El estado es requerido",
          ],
          area: [
            (v) => !!v || "El área es requerida",
          ],
          unidad: [
            (v) => !!v || "La unidad es requerida",
          ],
          ventaRenta: [
            (v) => !!v || "El status es requerida",
          ],
          precio: [
            (v) => !!v || "El precio es requerido",
          ],
          vocacion: [
            (v) => !!v || "La vocación del predio es requerida",
          ],
          tipoRiego: [
            (v) => !!v || "El tipo de riego es requerido",
          ],
          telefono: [
            (v) => !!v || "El teléfono es requerido",
            v => /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(v) || 'El teléfono no es válido, Ej. (999)-000-0000',
            
          ],
          correo: [
            (v) => !!v || "El correo es requerido",
            v => /.+@.+/.test(v) || 'El email no es válido, Ej. correo@dominio.com',
          ]
        },
        parcela: {
          imagenes: []
        },
        snackbar: {
          open: false,
          text: "",
          timeout: 2000,
          color: "green darken-1 white--text",
        },
        headers: [
            { text: "IMAGEN", value: "imagenes[0].name", search: "", class: "orange--text" },
            { text: "FECHA", value: "fecha", search: "", class: "orange--text" },
            { text: "TÍTULO", value: "parcela", search: "", class: "orange--text" },
            { text: "DESCRIPCIÓN", value: "descripcion", search: "", class: "orange--text" },
            { text: "EXTENSIÓN", value: "extension", search: "", class: "orange--text", style: "min-width: 400px" },
            { text: "STATUS", value: "ventaRenta", search: "", class: "orange--text", width: "130px" },
            { text: "PRECIO", value: "precio", search: "", class: "orange--text" },
            { text: "ESTADO", value: "estado", search: "", class: "orange--text" },
            { text: "ACCIONES", value: "actions", sortable: false, search: "", class: "orange--text" },
        ],
        parcelasUser: [],
        parcelasUserS: [],
        arrayImagenes: [],
        list: [],
        nombresEstados: [],
        agrocostos:[],
        cultivosSinOrdenar: [],
        registro: []
    }),
    created() {
        this.initialize();
    },
    components: {
        Footer,
        draggable
    },
    computed: {
        allow() {
            return localStorage.getItem("status");
        },
        userMail() {
            return localStorage.getItem("mail");
        },
        coordenadas() {
            return (localStorage.getItem("latitude")) && (localStorage.getItem("longitude")) ? localStorage.getItem("latitude") + ", " + localStorage.getItem("longitude") : "sin datos";
        },
        cultivosOrdenados(){
            if(this.agrocostos.length > 0) {
                return this.cultivosSinOrdenar.sort();
            } else {
                return this.cultivosSinOrdenar;
            }
        },
    },
    methods: {
        async getMyParcelas() {
          this.parcelasUser = [];
          this.parcelasUserS = [];
            let db = firebase.firestore();
            await db
            .collection("parcelas")
            .where("dueno", "==", this.userMail)
            .where("eliminado", "==", false)
            .orderBy("fecha", "desc")
            .get()
            .then((snapshot) => {
            snapshot.docs.forEach((doc, i) => { 
              this.parcelasUser.push(doc.data());
              this.parcelasUser[i].rId = doc.id;
              this.parcelasUserS.push(doc.data());
              this.parcelasUserS[i].rId = doc.id;
            });
            });
        },
        async getAgrocostos() {
          let agrocostosAlmacenado = sessionStorage.getItem('agrocostos');
          if(agrocostosAlmacenado != "" && agrocostosAlmacenado != undefined) {
            this.agrocostos = JSON.parse(sessionStorage.getItem('agrocostos'));
            this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
          } else {
            let db = firebase.firestore();
            await db
                .collection("newAgrocostos")
                .get()
                .then((snapshot) => {
                snapshot.docs.forEach((doc) => this.agrocostos.push(doc.data()));
                });
            this.agrocostos.forEach( item => this.cultivosSinOrdenar.push(item.cultivo));
            let parsed = JSON.stringify(this.agrocostos);
            sessionStorage.setItem('agrocostos', parsed);
          }
        },
        async addParcela() {
          this.isDisabled = true;
          this.$refs.form.validate();
          
          if (this.valid == false) {
            console.log("no es valido");
            this.isDisabled = false;
          } 
          if (this.list.length == 0) {
            console.log("sin fotos");
            this.textImagesError = "Seleccione una(s) imagen(es) para la parcela"
            this.imagesError = true;
            this.isDisabled = false;
          } else if (this.valid == true && this.list.length != 0) {
            console.log("Bracket de registro");
            let fechaString = moment().format("YYYY-MM-DD HH:mm:ss");
            this.parcela.fecha = fechaString;
            this.parcela.id = generateRandomId();
            this.parcela.imagenes = this.list;
            this.parcela.eliminado = false;
            let db = firebase.firestore();
            await db.collection("parcelas").add(this.parcela).then(
                this.dialog = false,
                this.isEditing = false,
                this.snackbar.text = "Parcela registrada",
                this.snackbar.color = "green darken-1 white--text",
                this.snackbar.timeout = 3500,
                this.snackbar.open = true,
            );
            this.responseParcelas = [];
            this.getMyParcelas();
            location.reload();
          }
        },
        abrirEditar(id) {
            this.registro = [];
            this.registro = this.parcelasUserS.filter(parcela => parcela.rId == id);
            this.parcela = this.registro[0];
            this.list = this.parcela.imagenes;
        },
        async editar(id) {
          if (this.valid == false) {
          } else {
            let db = firebase.firestore();
            await db.collection('parcelas').doc(id).update(this.parcela).then(
                this.dialog = false,
                this.isEditing = false,
                this.snackbar.text = "Parcela editada",
                this.snackbar.color = "green darken-1 white--text",
                this.snackbar.timeout = 3500,
                this.snackbar.open = true,
            );
            this.parcelasUserS = [];
            this.parcelasUser = [];
            this.getMyParcelas(); 
          }
        },
    async subirImagenes(){
      if(this.arrayImagenes.length == 0){
          //document.getElementById("zonaImagenes").innerHTML = "";
        } else {
          let formData = new FormData();
          for (let imagen of this.arrayImagenes) {
            formData.append("file", imagen);
            this.respuesta = await axios.post(
              "https://agenciaweb.mx/agronome/upload-image.php",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            ).then(function(response){
              if(response.data.image == ''){
              } else {
                sessionStorage.setItem('oldNombreImagen', response.data.oldimage);
                sessionStorage.setItem('nombreImagen', response.data.image);
                //document.getElementById("imagenCargada").innerHTML = `<img src='https://www.agenciaweb.mx/agronome/${nombreImagen}' style="width: 70px;">`;
              }
            });
            this.objectImage = {
              name: sessionStorage.getItem('nombreImagen'),
              originalName: sessionStorage.getItem('oldNombreImagen'),
            };
            this.list.push(this.objectImage);
            this.imagesError = false;
            this.textImagesError = "";
          };
          this.parcela.imagenes = this.list;
          this.arrayImagenes = [];
        }
    },
        deleteImage(name) {

          if(this.parcela.imagenes.length == 1) {
            this.textImagesError = "La parcela debe incluir al menos una imagen, no es posible eliminar"
          } else {
            this.parcela.imagenes = this.parcela.imagenes.filter(imagen => imagen.name != name);
            document.getElementById(name).style.display = "none";
          }

        },
        preAddParcela() {
          this.dialog = true;
          this.isEditing = false; 
          if(this.$refs.form != undefined) {
            this.$refs.form.resetValidation();
          }
          this.list = [];
          this.producto = {
            dueno: this.userMail,
            georreferencia: this.coordenadas,
          };
        },
        deleteOpen(item) {
          this.doubleCheck = true;
          this.parcela = item;
        },
        async deleteParcela() {
          let db = firebase.firestore();
          this.parcela.eliminado = true;
          await db.collection('parcelas').doc(this.deleteIndex).update(this.parcela).then(
            this.doubleCheck = false,
            this.snackbar.text = "Parcela eliminada",
            this.snackbar.color = "red darken-1 white--text",
            this.snackbar.timeout = 3500,
            this.snackbar.open = true,
          );
          this.getMyParcelas();
          location.reload();
        },
        checkMove: function(e) {
            //window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        initialize() {
            this.getMyParcelas();
            this.getAgrocostos();
            this.nombresEstados = estados;
        }
    }
}
</script>
