<template>
  <v-row justify="center" align="center" class="text-center myBanner">
    <v-col cols="12" class="pt-lg-16 pb-lg-16">
      <h1 class="font-weight-bold titulo">
        REVISA LA OFERTA DE 
      </h1>
      <h1 class="font-weight-bold titulo naranja">
        TERRENOS DISPONIBLES
      </h1>
      <br/>
      <p class="subtitle">
        Encuentra tu parcela ideal para compra o renta. También si tú tienes un terreno que quieres poner a la renta o a la venta,<br/> anúncialo con nosotros completamente GRATIS.
      </p>

    </v-col>
    <v-col v-for="(parcela, i) in parcelas" :key="i" cols="12" sm="4" class="pa-lg-16 pt-lg-1 pa-sm-4">
      <v-img
        :lazy-src="parcela.imagenLazy"
        max-width="100%"
        aspect-ratio="1.7"
        :src="`https://agenciaweb.mx/agronome/${parcela.imagenes[0].name}`"
        alt="smartphone"
      ></v-img>
      <p class="text-left orange--text pt-2" >
        {{ parcela.estado }}
      </p>
      <h3>{{ parcela.parcela }}</h3>
      <br />
      <p class="text-justify">
        {{ parcela.descripcion }}
      </p>
    </v-col>
    <v-col cols="12">
      <v-btn to="/parcelas" x-large rounded class="myBtn">
        <span class="negritas">Ver todos</span> los terrenos
      </v-btn>
    </v-col>
    <v-col cols="12">
      <br />
    </v-col>
  </v-row>
</template>
<style scoped>
.titulo {
  font-size: 2.2rem;
}
.naranja {
  color: #e36900;
}
.myBtn {
  background-color: #fff;
  border: solid #fea100 2px;
  text-transform: none;
}
.negritas {
  margin-right: 4px;
  font-weight: bold;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  margin: 5px;
}
</style>
<script>
import firebase from "firebase/app";
require("firebase/auth");
require("firebase/firestore");
export default {
  name: "Encontraras",
  created(){
    this.initialize();
  },
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-10.png"),
      },
    ],
    parcelas: [],
    responseParcelas: []
  }),
  methods: {
    async getParcelas() {
      let db = firebase.firestore();
      await db
        .collection("parcelas")
        .where("eliminado", "==", false)
        .orderBy("fecha", "desc")
        .limit(3)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) =>
            this.responseParcelas.push(doc.data())
          );
        });
      this.parcelas = this.responseParcelas;
    },
    initialize(){
      this.getParcelas();
    }
  }
};
</script>