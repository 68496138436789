<template>
<div style="overflow: hidden;">
  <v-row
    justify="center"
    align="center"
    class="white--text text-center myBanner pa-4"
    :style="{
      'background-image': 'url(' + items[0].src + `)`,
    }"
  >
    <v-col cols="12" class="pt-lg-16">
      <h1 class="font-weight-bold titulo white--text secondOption">¿QUE ENCONTRARÁS AL REGISTRATE?</h1>
    </v-col>
    <v-col cols="12" sm="6" class="pa-lg-16 pt-lg-1 pa-sm-4">
      <img :src="items[1].src" alt="smartphone" />
      <h1 class="white--text">1. Costos y rendimientos de tu parcela</h1>
      <br />
      <p class="text-justify">
        ¿Quieres saber cuál es el costo de cultivar pepino en tu parcela? Nosotros te decimos cuánto te costaría, 
        los rendimientos aproximados y la ganancia que podrías obtener.  
        Incluso te decimos, según tu <u>geo-referencia</u>, cuál es el producto más rentable en tu localidad.
      </p>
    </v-col>
    <v-col cols="12" sm="6" class="pa-lg-16 pt-lg-1 pa-sm-4">
      <img :src="items[2].src" alt="smartphone" />
      <h1 class="white--text">2. Precios agrícolas</h1>
      <br />
      <p class="text-justify">
        Te brindamos información sobre los precios diarios desde la Central de Abastos de la CDMX, 
        de todos los cultivos y productos para que tomes las mejores decisiones. 
      </p>
    </v-col>
    <v-col cols="12" sm="6" class="pa-lg-16 pt-lg-1 pa-sm-4">
      <img :src="items[3].src" alt="smartphone" />
      <h1 class="white--text">3. Nuevos Clientes</h1>
      <br />
      <p class="text-justify">
        Busca clientes y proveedores nuevos y seguros. Estamos trabajando en una sección para que puedas evaluar a las empresas con las que has trabajado y las recomiendes. 
        Si quieres subirte como proveedor de alguna cadena productiva, llena <a href="https://forms.gle/U7RAZgtXrChm8Lwt6" target="_blank" style="color: #fff; font-weight: bold;">este formulario</a>.
      </p>
    </v-col>
    <v-col cols="12" sm="6" class="pa-lg-16 pt-lg-1 pa-sm-4">
      <img :src="items[2].src" alt="smartphone" />
      <h1 class="white--text">4. Listado de Apoyos Gubernamentales</h1>
      <br />
      <p class="text-justify">
        ¿Sabías que los apoyos gubernamentales cambian cada año? Nosotros te decimos a cuáles puedes aplicar según las características de tu negocio.

      </p>
    </v-col>
  </v-row>
</div>
</template>
<style scoped>
.titulo {
  font-size: 3rem;
}
.myBtn {
  background-color: #fff;
  border: solid 2px #df8e04;
  text-transform: none;
}
.negritas {
  margin-right: 4px;
  font-weight: bold;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
}
</style>
<script>
export default {
  name: "Encontraras",
  data: () => ({
    items: [
      {
        src: require("../../../assets/HOME-3.png"),
      },
      {
        src: require("../../../assets/Phone-icon.png"),
      },
      {
        src: require("../../../assets/Checklist-icon.png"),
      },
      {
        src: require("../../../assets/Smartphone-icon.png"),
      },
    ],
  }),
};
</script>