<template>
    <div>
        <v-col
            cols="12"
            sm="12"
            class="grey--text d-flex align-center justify-center py-6"
            >
            <center><h1>TÉRMINOS Y CONDICIONES PARA USUARIOS PROPIETARIOS <br> SOBRE EL USO DEL SITIO “AGRONOME”.</h1></center>
        </v-col>
        <v-col cols="12" sm="12" class="pa-10">
            <p>Este contrato describe los términos y condiciones generales (en adelante, los "Términos
                y Condiciones") aplicables al uso de los servicios ofrecidos dentro del sitio web
                www.AgronoMe.red (en adelante, el "Sitio") por Réntalo, Ahorra y Reduce, S.A.P.I. DE
                C.V. (en lo sucesivo “AgronoMe”), una sociedad mercantil constituida bajo las leyes de
                los Estados Unidos Mexicanos, con domicilio social en Homero 342, interior 601, Polanco
                IV Sección, Delegación Miguel Hidalgo, Código Postal 11550, Ciudad de México, y
                dirección de correo electrónico contacto@AgronoMe.mx. Cualquier persona que desee
                ingresar y/o usar el Sitio o los servicios ofrecidos por AgronoMe dentro de este (en
                adelante, los “Servicios”), podrá hacerlo sujetándose a los presentes Términos y
                Condiciones generales, junto con todas las demás políticas y principios que rigen el Sitio
                y que son incorporados al presente por referencia.</p>
            <p>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES
                TIENEN UN CARÁCTER OBLIGATORIO, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS
                SERVICIOS.</p>
            <p>El Usuario y/o usuario propietario debe leer, entender y aceptar todas las condiciones
                establecidas en los Términos y Condiciones y en el Aviso de Privacidad, así como en los
                demás documentos incorporados a estos por referencia, previo a su inscripción como
                Usuario y/o usuario propietario del Sitio.</p>
            <p>La utilización del Sitio y/o los Servicios por parte del Usuario y/o usuario propietario,
                implica su consentimiento expreso, libre, informado, específico e inequívoco de los
                presentes Términos y Condiciones y del Aviso de Privacidad de AgronoMe, el cual se
                encuentra disponible en www.AgronoMe.red. </p>
            <p>Mediante el acceso y uso del Sitio el Usuario y/o usuario propietario acuerda vincularse
                jurídicamente por estos Términos y Condiciones, que establecen una relación contractual
                entre el Usuario y/o usuario propietario y AgronoMe. Si usted no acepta estos Términos
                y Condiciones, no podrá ingresar ni utilizar los servicios disponibles en el Sitio. Estos
                Términos y Condiciones sustituyen expresamente los acuerdos o compromisos previos
                celebrados entre las partes. AgronoMe podrá poner fin de inmediato a estos Términos y
                Condiciones o cualquiera de los servicios disponibles en el Sitio respecto de usted o, en
                general, dejar de ofrecer o denegar el acceso a los servicios o parte de ellos, en cualquier
                momento y por cualquier motivo, mediante notificación a través del Sitio y/o correo
                electrónico.</p>
            <h2>I. Capacidad.</h2>
            <br>
            <p>Los Servicios sólo están disponibles para personas que tengan capacidad legal para
                contratar. No podrán utilizar los Servicios las personas que no tengan esa capacidad, los
                menores de edad, o los Usuarios Propietarios que hayan sido suspendidos
                temporalmente o inhabilitados definitivamente. Al ingresar o utilizar el Sitio, usted
                declara y garantiza que es mayor de edad y que tiene capacidad legal para contratar.
                </p>
            <p>En caso de que el representante de una persona moral desee registrarla como Usuario
                y/o usuario propietario, deberá tener la capacidad legal suficiente para contratar a su
                nombre y obligarla en los términos del presente contrato.</p>
            <h2>II. Registro</h2>
            <br>
            <p>Es obligatorio completar el formulario de registro en todos sus campos con datos válidos
                para poder utilizar los Servicios de AgronoMe. El Usuario y/o usuario propietario debe
                proporcionar información veraz, exacta, vigente y completa durante el proceso de
                registro y mantener actualizada en todo momento la información de la Cuenta de Usuario
                y/o usuario propietario. El Usuario y/o usuario propietario autoriza que AgronoMe utilice
                diversos medios para identificar sus datos personales, asumiendo el Usuario y/o usuario
                propietario la obligación de revisarlos, rectificarlos y mantenerlos actualizados.
                AgronoMe no se responsabiliza por la certeza de los datos personales de sus Usuarios
                Propietarios. El Usuario y/o usuario propietario deberá informar de inmediato a
                AgronoMe en caso de que tenga conocimiento o tenga motivos para sospechar que sus
                datos de validación han sido robados, estén perdidos, sean objeto de apropiación
                indebida o se vean amenazados de algún modo. Asimismo, deberá reportar el uso no
                autorizado de su Cuenta de Usuario y/o usuario propietario o, en su caso, la sospecha que
                tenga de algún uso indebido. El Usuario y/o usuario propietario será responsable por
                todas las actividades desarrolladas a través de su cuenta, a menos que dichas actividades
                no hayan sido autorizadas por el Usuario y/o usuario propietario y este no haya actuado
                negligentemente.</p>
            <p>AgronoMe se reserva el derecho de solicitar algún comprobante y/o dato adicional a
                efectos de corroborar la información del Usuario y/o usuario propietario, así como de
                suspender temporal o definitivamente a aquellos Usuarios Propietarios cuyos datos no
                hayan podido ser confirmados. </p>
            <p>Como parte del proceso para completar el formulario de inscripción para poder utilizar su
                cuenta en el Sitio, así como activar cualquier función habilitada en este o hacer uso de los
                Servicios, el Usuario y/o usuario propietario autoriza, como medios de autenticación,
                aquellos electrónicos, tales como: número de identificación personal, huella digital, firma
                electrónica simple y/o avanzada, o cualquier otro que determine AgronoMe.</p>
            <p>El Usuario y/o usuario propietario ingresará a su cuenta personal (la "Cuenta"), mediante
                el ingreso de su nombre de Usuario y/o usuario propietario o seudónimo, junto a la
                contraseña elegida. El Usuario y/o usuario propietario se obliga a mantener la
                confidencialidad de su contraseña.
                </p>
            <p>La Cuenta es personal, única e intransferible y está prohibido que un mismo Usuario y/o
                usuario propietario inscriba o posea más de una Cuenta. En caso de que AgronoMe
                detecte distintas Cuentas que contengan datos coincidentes o relacionados, este podrá
                cancelarlas, suspenderlas o inhabilitarlas.</p>
            <p>AgronoMe se reserva el derecho de rechazar cualquier solicitud de inscripción o de
                cancelar una inscripción previamente aceptada, sin que esté obligado a comunicar o
                exponer las razones de su decisión, y sin que ello genere algún derecho a indemnización
                o resarcimiento en favor del Usuario y/o usuario propietario.</p>
            <h2>III. Naturaleza jurídica y modificaciones del Contrato</h2>
            <br>
            <p>Los presentes Términos y Condiciones regulan propiamente la relación contractual que
                vincula a los Usuarios Propietarios que ingresan y/o usan el Sitio y a AgronoMe, por lo
                que se refiere a la autorización de uso que otorga este en favor de aquellos, como también
                al uso que los Usuarios Propietarios dan al Sitio. El Usuario y/o usuario propietario
                entiende y acepta que, ni los presentes Términos y Condiciones, ni los servicios
                disponibles en el Sitio, lo vinculan jurídicamente con AgronoMe en relación con los bienes
                y/o servicios que un Usuario contrate con él a través del Sitio, ya que dicha relación de
                arrendamiento se genera exclusivamente entre el Usuario y/o usuario propietario y el
                Usuario con el que aquel decide libremente contratar.</p>
            <p>AgronoMe podrá modificar los Términos y Condiciones en cualquier momento y publicará
                dicha modificación en el Sitio. Todos los términos modificados entrarán en vigor a los 10
                (diez) días naturales siguientes a la publicación. Dichas modificaciones serán comunicadas
                por AgronoMe a los Usuarios Propietarios que, en el apartado de Configuración de su
                Cuenta, hayan indicado que desean recibir notificaciones de los cambios en estos
                Términos y Condiciones. Todo Usuario y/o usuario propietario que no esté de acuerdo
                con las modificaciones efectuadas por AgronoMe, podrá solicitar la baja de la Cuenta.</p>
            <p>El acceso o uso del Sitio y/o sus Servicios implica la aceptación de estos Términos y
                Condiciones y sus modificaciones, así como la aceptación del Usuario y/o usuario
                propietario de vincularse por los mismos.</p>
            <h2>IV. Bienes y/o servicios publicados en el Sitio</h2>
            <br>
            <p>Al publicar un anuncio en el Sitio, el Usuario y/o usuario propietario consiente
                expresamente la utilización de las formas disponibles para el pago del uso del bien y/o
                servicio ofrecido. </p>
            <p>El Usuario y/o usuario propietario deberá ofrecer sus bienes y/o servicios en las categorías
                y subcategorías apropiadas. El Usuario y/o usuario propietario deberá llenar el formulario
                que se le solicite a través del Sitio, con la información, datos y/o descripción del bien y/o
                servicios que desee ofrecer, lo cual será revisado por AgronoMe, quien podrá aprobar,
                rechazar o pedir que se hagan modificaciones a la información o publicación.</p>
            <p>Las publicaciones podrán incluir textos descriptivos, gráficos, fotografías y otros
                contenidos y condiciones pertinentes para celebrar la operación correspondiente sobre
                el bien y/o servicio, siempre que no violen ninguna disposición de este contrato. El bien
                y/o servicio ofrecido por el Usuario y/o usuario propietario deberá cumplir exactamente
                con su descripción, en cuanto a sus condiciones y características relevantes. Se entiende
                y presume que, mediante la inclusión del bien y/o servicio en el Sitio, el Usuario y/o
                usuario propietario acepta que detenta el bien y/o servicio en su calidad de propietario,
                o está facultado para ello por su titular y lo tiene disponible para su entrega inmediata o
                para el plazo especificado en la publicación. </p>
            <p>Se establece que los Usuarios Propietarios tienen la obligación de expresar los precios de
                los bienes y/o servicios publicados, con el Impuesto al Valor Agregado (IVA) incluido,
                cuando corresponda su aplicación, y en moneda de curso legal. AgronoMe podrá remover
                cualquier publicación cuyo precio no sea expresado de esta forma. Se deja expresamente
                establecido que ninguna descripción podrá contener datos personales o de contacto. No
                podrán ofrecerse otros medios de pagos distintos de los enunciados por AgronoMe en la
                página de publicación de artículos. En caso de que se infrinja cualquiera de las
                disposiciones establecidas en esta cláusula, AgronoMe podrá editar el espacio, solicitar al
                Usuario y/o usuario propietario que lo edite, o dar de baja la publicación donde se
                encuentre la infracción.</p>
            <p>El Usuario y/o usuario propietario autoriza que AgronoMe utilice, publique, reproduzca
                y/o adapte las imágenes y fotografías incluidas en sus publicaciones, su nombre
                comercial, marcas, frases publicitarias, logos, diseños, dibujos, imágenes y todo otro signo
                distintivo que identifique al Usuario y/o usuario propietario y sus bienes y/o servicios e
                información de estos. El Usuario y/o usuario propietario deberá incluir las imágenes,
                fotografías y marca del bien y/o servicio ofertado en sus publicaciones, así como
                mantener actualizada la información del bien y/o servicio. </p>
            <p>El Usuario y/o usuario propietario declara y garantiza que es titular o licenciatario de los
                derechos necesarios sobre las imágenes, fotografías contenidas en sus publicaciones,
                marcas, así como sobre la Información del bien y/o servicio, y que cuenta con los derechos
                y facultades necesarias para conceder la autorización detallada en esta cláusula, siendo 
                responsable exclusivo por cualquier infracción a derechos de terceros o por las
                inconsistencias o inexactitud en la información del bien y/o servicio.</p>
            <p>AgronoMe podrá eliminar la publicación de las imágenes y fotografías, e incluso del bien
                y/o servicio, si interpretara, a su exclusivo criterio, que la imagen no cumple con los
                presentes Términos y Condiciones.</p>
            <p>Sólo podrán ser ofertados aquellos bienes y/o servicios cuya disposición no se encuentre
                tácita o expresamente prohibida por la legislación vigente aplicable.</p>
            <h2>V. Privacidad de la información.</h2>
            <br>
            <p>Para utilizar los servicios ofrecidos por AgronoMe, los Usuarios Propietarios deberán
                facilitar determinados datos de carácter confidencial. Asimismo, tienen la obligación de
                mantener actualizados, en todo momento, sus datos personales. Su información personal
                y confidencial se procesa y almacena en servidores o medios magnéticos que mantienen
                altos estándares de seguridad y protección tanto física como tecnológica. Para mayor
                información sobre la privacidad de los datos personales y casos en los que será revelada,
                podrá consultar nuestro Aviso de Privacidad en: www.AgronoMe.red.</p>
            <h2>VI. Obligaciones de los Usuarios.</h2>
            <br>
            <p>8.1. Obligaciones y derechos del Usuario</p>
            <p>Durante el plazo fijado por el Usuario y/o usuario propietario, los Usuarios interesados
                realizarán ofertas para celebrar la operación respectiva del bien y/o servicio. </p>
            <p>Al ofertar y aceptar, los Usuarios y los Usuarios Propietarios aceptan quedar obligados
                por las condiciones incluidas en la descripción del bien y/o servicio, en la medida en que
                estas no infrinjan la legislación vigente aplicable o los presentes Términos y Condiciones.
                La oferta es irrevocable salvo en circunstancias excepcionales, tales como: 1) que el
                Usuario y/o usuario propietario cambie sustancialmente la descripción del bien y/o
                servicio después de realizada alguna oferta, o 2) que exista un claro error tipográfico, o
                que no se pueda verificar la identidad del Usuario y/o usuario propietario. La cancelación
                injustificada de una operación impactará en la reputación del Usuario o Usuario y/o
                usuario propietario responsable.</p>
            <p>Contribuciones. Tal como lo establece la normativa fiscal vigente, el Usuario deberá exigir
                comprobante fiscal o ticket al Usuario y/o usuario propietario como comprobante de la operación, y este estará obligado a emitirlo conforme a lo dispuesto en las disposiciones
                legales aplicables.</p>
            <p>8.2. Obligaciones del Usuario y/o usuario propietario. </p>
            <p>El Usuario y/o usuario propietario deberá tener capacidad legal para disponer del bien
                y/o servicio objeto de su oferta. Asimismo, debe cumplir con todas las obligaciones
                regulatorias pertinentes y contar con los registros, habilitaciones, permisos y/o
                autorizaciones exigidos por la normativa aplicable a la disposición de dichos bienes. La
                cancelación de una operación por parte del Usuario y/o usuario propietario impactará en
                su reputación en el Sitio.</p>
            <p>Dado que el Sitio es un punto de encuentro entre Usuarios Propietarios y Usuarios y
                AgronoMe no participa de las operaciones que se realizan entre ellos, el Usuario y/o
                usuario propietario conoce y acepta que será responsable por todas las obligaciones y
                cargas impositivas que correspondan por los productos o servicios que ofrecen, sin que
                pudiera imputársele a AgronoMe algún tipo de responsabilidad por incumplimientos en
                tal sentido.</p>
            <p>Es obligación del Usuario y/o usuario propietario el mantener actualizada la descripción
                de los bienes publicados en el Sitio y la información o condiciones.</p>
            <p>Con la aceptación de los presentes Términos y Condiciones, el Usuario y/o usuario
                propietario se obliga a: (1) Suministrar información veraz y fidedigna al momento de crear
                su Cuenta; (2) Abstenerse de transferir sus datos de validación (nombre de usuario y
                contraseña) a terceros; (3) Abstenerse de ingresar y/o usar el Sitio para realizar actos
                contrarios a la moral, al orden público, a las buenas costumbres o realizar actos o hechos
                ilícitos en contra de AgronoMe, los Usuarios o terceros; (4) Realizar la entrega del bien o
                servicio en la forma y tiempo acordado con el Usuario, (5) Proporcionar al Usuario con el
                que celebre una operación, las instrucciones escritas de uso de los bienes, en su caso; (6)
                Pagar la comisión correspondiente a AgronoMe por cada operación concretada a través
                del Sitio, en su caso; (7) Informar inmediatamente a AgronoMe en caso de usurpación de
                los datos de validación; (8) Abstenerse de realizar cualquier acción que afecte el
                funcionamiento del Sitio; (9) Abstenerse de publicar u ofrecer cualquier bien y/o servicio
                cuya prestación sea prohibida por la legislación aplicable; (10) Abstenerse de suplantar la
                identidad de otros Usuarios Propietarios; (11) Abstenerse de descifrar o apropiarse de
                cualquier elemento del código del Sitio; (12) En general, cumplir o realizar todas aquellas
                conductas necesarias para la ejecución del negocio jurídico con los Usuarios, y (13)
                cualesquier otra prevista en estos Términos y Condiciones. Los Usuarios Propietarios aceptan que, toda comunicación realizada a través del Sitio con
                los Usuarios, referente a la oferta y/o aceptación de una operación de arrendamiento, o
                a cualquier acto relativo a la contratación a través del Sitio, se considerará válido y tendrá
                efectos legales conforme a lo previsto en los presentes Términos y Condiciones, en la
                medida en que lo permita la ley.</p>
            <h2>VII. Prohibiciones.</h2>
            <br>
            <p>Los Usuarios Propietarios no podrán: (a) modificar los precios de los bienes y/o servicios
                una vez recibida una oferta; (b) insultar o agredir a Usuarios o a otros Usuarios
                Propietarios, o (c) cualquier otro previsto en los presentes Términos y Condiciones.</p>
            <p>Este tipo de actividades serán investigadas por AgronoMe y el infractor podrá ser
                sancionado con la suspensión o cancelación de cualquier oferta e incluso de su inscripción
                como Usuario y/o usuario propietario y/o de cualquier otra forma que AgronoMe estime
                pertinente y que esté permitida por las disposiciones legales aplicables, sin perjuicio de
                las acciones legales a que pueda haber lugar por la configuración de delitos,
                contravenciones o los perjuicios civiles que pueda causar a los Usuarios.</p>
            <h2>VIII. Sanciones y suspensión de operaciones.</h2>
            <br>
            <p>Sin perjuicio de otras medidas, AgronoMe podrá (i) advertir, (ii) suspender en forma
                temporal, (iii) inhabilitar definitivamente la Cuenta de cualquier Usuario y/o usuario
                propietario, (iv) eliminar cualquier publicación, (v) aplicar una sanción que impacte
                negativamente en la reputación de un Usuario y/o usuario propietario, o (vi) iniciar las
                acciones que estime pertinentes y/o suspender la prestación de sus Servicios respecto de
                cualquier Usuario y/o usuario propietario si: (a) se quebrantara alguna ley o cualquiera
                de las estipulaciones de los Términos y Condiciones; (b) si incumpliera sus compromisos
                como Usuario y/o usuario propietario; (c) si se incurriera, a criterio de AgronoMe, en
                conductas o actos dolosos o fraudulentos; (d) no pudiera verificarse la identidad del
                Usuario y/o usuario propietario o cualquier información proporcionada por este fuere
                errónea; (e) AgronoMe entendiera que las publicaciones u otras acciones realizadas,
                pudieran ser causa de responsabilidad para el Usuario y/o usuario propietario que las
                publicó, para AgronoMe o, en general, para los demás Usuarios o Usuarios Propietarios.
                En el caso de la suspensión de un Usuario y/o usuario propietario, sea temporal o
                definitiva, todos los bienes que tuviera publicados serán removidos del sistema, sin
                ninguna responsabilidad para AgronoMe.
                </p>
            <h2>IX. Responsabilidad.</h2>
            <br>
            <p>El Usuario y/o usuario propietario manifiesta que es el legítimo propietario de los
                artículos o bienes ofrecidos por este a través del Sitio, o que está autorizado legalmente
                para dar servicios. AgronoMe no interviene en el perfeccionamiento de las operaciones
                realizadas entre los Usuarios Propietarios y los Usuarios, ni en las condiciones que
                estipulen entre ellos; por ello, AgronoMe no será responsable respecto de la existencia,
                calidad, cantidad, estado, idoneidad, integridad o legitimidad de los bienes y/o servicios
                ofrecidos por los Usuarios Propietarios. Asimismo, AgronoMe no será responsable de la
                capacidad de los Usuarios Propietarios y Usuarios para contratar o de la veracidad de los
                datos personales por ellos ingresados. El Usuario y/o usuario propietario reconoce y
                acepta que es el exclusivo responsable por los bienes y/o servicios que publica en el Sitio,
                mientras que el Usuario es responsable por las ofertas que realiza para tomar en
                arrendamiento dichos bienes y/o servicios.
                </p>
            <p>Debido a que AgronoMe no tiene ninguna participación durante todo el tiempo en que el
                bien y/o servicio se publica en el Sitio, ni en la posterior negociación y perfeccionamiento
                del contrato definitivo entre el Usuario y/o usuario propietario y el Usuario, AgronoMe
                no será responsable por el efectivo cumplimiento de las obligaciones asumidas por los
                Usuarios Propietarios y los Usuarios en el perfeccionamiento de la operación. El Usuario
                y/o usuario propietario conoce y acepta que, al realizar operaciones con Usuarios o
                terceros, lo hace bajo su propio riesgo. En ningún caso, AgronoMe será responsable por
                lucro cesante o por cualquier otro daño y/o perjuicio que haya podido sufrir o sufra el
                Usuario y/o usuario propietario debido a las operaciones realizadas o no realizadas por
                bienes y/o servicios publicados a través del Sitio.</p>
            <p>En caso de que uno o más Usuarios Propietarios, o algún tercero, inicien cualquier tipo de
                reclamo o acciones legales contra uno o varios Usuarios, todos los Usuarios Propietarios
                involucrados en dichos reclamos o acciones, eximen de toda responsabilidad a AgronoMe
                y a sus directores, gerentes, empleados, agentes, operarios, representantes y
                apoderados.</p>
            <h2>X. Alcance de los servicios de AgronoMe.</h2>
            <br>
            <p>Este contrato no crea ningún acuerdo de sociedad, de mandato, de franquicia, o relación
                laboral entre AgronoMe y el Usuario y/o usuario propietario. El Usuario y/o usuario
                propietario reconoce y acepta que AgronoMe no es parte en ninguna operación, ni tiene
                control alguno sobre la calidad, seguridad o legalidad de los bienes y/o servicios
                publicados, la veracidad o exactitud de los anuncios, ni de la capacidad de los Usuarios
                para celebrar los contratos correspondientes. AgronoMe no puede asegurar que un
                Usuario o Usuario y/o usuario propietario completará una operación ni podrá verificar la
                identidad o datos personales ingresados por ellos. </p>
            <h2>XI. Fallas en el sistema</h2>
            <br>
            <p>AgronoMe no se responsabiliza por ningún tipo de daño, perjuicio o pérdida causados al
                Usuario y/o usuario propietario por fallas en el sistema, en el servidor o en Internet.
                AgronoMe tampoco será responsable por cualquier virus que pudiera infectar el equipo
                del Usuario y/o usuario propietario como consecuencia del acceso, uso o examen de su
                Sitio o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio
                contenidos en el mismo. Los Usuarios Propietarios NO podrán imputarle a AgronoMe
                responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes
                de dificultades técnicas o fallas en los sistemas o en Internet. AgronoMe no garantiza el
                acceso y uso continuado o ininterrumpido del Sitio. El sistema puede eventualmente no
                estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra
                circunstancia ajena a AgronoMe; en tales casos, se procurará restablecerlo con la mayor
                celeridad posible, sin que por ello pueda imputársele algún tipo de responsabilidad.
                AgronoMe no será responsable por ningún error u omisión contenidos en su Sitio. </p>
            <h2>XII. Tarifas y facturación.</h2>
            <br>
            <p>La inscripción en el Sitio es gratuita para los Usuarios Propietarios.</p>
            <h2>XIII. Sistema de reputación.</h2>
            <br>
            <p>Debido a que la verificación de la identidad de los usuarios en Internet es difícil,
                AgronoMe no puede confirmar ni confirma la identidad pretendida de cada Usuario o
                Usuario y/o usuario propietario. Por ello, los Usuarios cuentan con un sistema de
                reputación que se actualiza periódicamente, con base en datos vinculados con su
                actividad en el Sitio. El Usuario y/o usuario propietario, además, podrá recibir
                comentarios u opiniones por parte del Usuario. Dichos comentarios serán incluidos en el
                perfil de Usuario y/o usuario propietario, bajo exclusiva responsabilidad de los Usuarios
                que los emitan.</p>
            <p>AgronoMe no asume obligación de verificar la veracidad o exactitud de los comentarios</p>
            <p>AgronoMe no se responsabiliza por los dichos allí vertidos, por la confianza depositada en
                la reputación de los Usuarios Propietarios o en cualquier comentario expresado sobre
                dicho Usuario y/o usuario propietario dentro del Sitio o a través de cualquier otro medio,
                o por las ofertas que los Usuarios realicen teniendo en cuenta dicha reputación o
                comentarios. AgronoMe se reserva el derecho de editar y/o eliminar aquellos
                comentarios que sean considerados inadecuados u ofensivos. AgronoMe mantiene el
                derecho de excluir a aquellos Usuarios Propietarios que sean objeto de comentarios
                negativos provenientes de fuentes distintas.</p>
            <h2>XIV. Propiedad intelectual, licencias y enlaces.</h2>
            <br>
            <p>AgronoMe se reserva todos los derechos, incluyendo aquellos de propiedad intelectual,
                asociados con sus servicios, su Sitio, los contenidos de sus pantallas, programas, bases de
                datos, redes, códigos, desarrollo, software, arquitectura, hardware, contenidos,
                información, tecnología, fases de integración, funcionalidades, dominios, archivos que
                permiten al Usuario y/o usuario propietario ingresar y crear su Cuenta, herramientas de
                venta, marcas, patentes, derechos de autor, diseños y modelos industriales, nombres
                comerciales, entre otros, y declara que están protegidos por leyes nacionales e
                internacionales vigentes.</p>
            <p>En ningún caso se entenderá que el Usuario y/o usuario propietario tendrá algún tipo de
                derecho sobre estos, excepto para utilizar el servicio del Sitio conforme a lo previsto en
                estos Términos y Condiciones. El uso indebido o contrario a la normativa vigente de los
                derechos de propiedad intelectual de AgronoMe, así como su reproducción total o
                parcial, queda prohibido, salvo autorización expresa y por escrito de AgronoMe.</p>
            <p>Los Usuarios Propietarios tampoco podrán comunicar que los productos o servicios que
                ofrecen son patrocinados, promovidos, producidos, ofrecidos y/o vendidos por
                AgronoMe, y deberán abstenerse de realizar cualquier acto que pudiera causar un daño,
                pérdida de reputación, o disminución del valor de los derechos de propiedad intelectual
                de AgronoMe.</p>
            <p>El Sitio puede contener enlaces a sitios web de terceros. En virtud de que AgronoMe no
                tiene control sobre tales sitios, no será responsable por los contenidos, materiales,
                acciones y/o servicios prestados por estos, ni por daños o pérdidas ocasionadas por la
                utilización de éstos, causados directa o indirectamente. La presencia de enlaces a otros
                sitios web no implica una sociedad, relación, aprobación, respaldo de AgronoMe a dichos
                sitios y sus contenidos.</p>
            <h2>XV. Indemnidad.</h2>
            <br>
            <p>El Usuario y/o usuario propietario mantendrá indemne a AgronoMe, así como a sus
                filiales, empresas controladas y/o controlantes, funcionarios, directivos, sucesores,
                administradores, representantes y trabajadores, por cualquier reclamo iniciado por otros
                Usuarios Propietarios, Usuarios, terceros o por cualquier organismo, relacionado con sus
                actividades en el Sitio, el cumplimiento y/o el incumplimiento de los Términos y
                Condiciones, así como respecto de cualquier violación de leyes o derechos de terceros.</p>
            <h2>XVI. Cesión</h2>
            <br>
            <p>El Usuario y/o usuario propietario no podrá ceder los derechos y obligaciones de los
                presentes Términos y Condiciones sin el previo consentimiento escrito de AgronoMe. Por
                su parte, AgronoMe podrá ceder, sin necesidad de recabar el consentimiento previo del
                Usuario y/o usuario propietario, los presentes Términos y Condiciones, a cualquier
                entidad comprendida dentro de su grupo de sociedades, así como a cualquier persona o
                entidad que le suceda en el ejercicio de su negocio por cualesquiera títulos.</p>
            <h2>XVII. Acuerdo íntegro y subsistencia</h2>
            <br>
            <p>Estos Términos y Condiciones constituyen el acuerdo íntegro entre AgronoMe y el Usuario
                y/o usuario propietario, y sustituye cualquier otro acuerdo o convenio celebrado con
                anterioridad. El Usuario y/o usuario propietario reconoce que, en la aceptación que hace
                de los presentes Términos y Condiciones, no existe dolo, error, mala fe o cualquier vicio
                del consentimiento que pudieren invalidarlos o nulificarlos, por lo que renuncia
                expresamente a invocarlos en cualquier tiempo.</p>
            <p>Cualquier cláusula o provisión del presente contrato, legalmente declarada inválida, será
                eliminada o modificada a elección de AgronoMe, con la finalidad de corregir su vicio o
                defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza,
                obligatoriedad y validez.</p>
            <h2>XVIII. Ley aplicable y jurisdicción.</h2>
            <br>
            <p>Los presentes Términos y Condiciones, así como la relación entre AgronoMe y el Usuario
                y/o usuario propietario, o entre este y un Usuario, se regirán e interpretarán con arreglo
                a las Leyes de los Estados Unidos Mexicanos. Las partes acuerdan someterse a la
                jurisdicción exclusiva de los Juzgados y Tribunales de la Ciudad de México, para la
                interpretación o controversia derivada de los presentes Términos y Condiciones de Uso o
                la relación entre las mismas.
            </p>
    </v-col>
    </div>
</template>
<script>

export default {
    data: () => {

    }
}
</script>