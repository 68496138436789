<style scoped>
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  padding: 4px;
}
.img-row {
  width: 50px;
  margin: 5px;
  display: inline-block;
  vertical-align: middle;
}
.errorImagenes {
  color: #a00;
}
</style>
<template>
  <div style="overflow-x: hidden;">
  <div class="pa-4">
    <v-snackbar 
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      color="green darken-1 white--text"
      absolute
      right
      top
    >
      <center>
        {{ snackbar.text }}
      </center>
    </v-snackbar>
    <v-row no-gutters style="min-height: 80vh">
      <v-col
        cols="12"
        sm="12"
        class="grey--text d-flex align-center justify-center py-6"
      >
        <h1>Parcelas en renta</h1>
      </v-col>
      <v-col
        cols="12"
        md="10"
      ></v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          to="/misParcelas"
          class="white--text place-btn outlined orange"
        >
          Ver mis parcelas
        </v-btn>
      </v-col>
      <br>
      <v-col cols="12" sm="2" class="centrar">
        <div
          v-for="(filter, ind) in filters"
          :key="`filter${ind}`"
          class="filtro"
        >
          {{ filter.name }}
          <v-btn
            icon
            color="#ff670a"
            @click="
              () => {
                filter.current += 1;
                sortParcelas(filter.attr, filter.current % 3);
              }
            "
          >
            <v-icon v-if="filter.current % 3 == 0">mdi-circle-small</v-icon>
            <v-icon v-if="filter.current % 3 == 1">mdi-chevron-up</v-icon>
            <v-icon v-if="filter.current % 3 == 2">mdi-chevron-down</v-icon>
          </v-btn>
        </div>
        <br />
        <v-row class="d-flex justify-end">
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              label="Precio mínimo"
              :min="0"
              :max="maxPrice"
              v-model="minPrice"
              @change="filterPriceParcelas"
              dense
            ></v-text-field>
            <v-text-field
              type="number"
              label="Precio máximo"
              :min="minPrice"
              v-model="maxPrice"
              @change="filterPriceParcelas"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="10">
        <v-row class="d-flex px-10" style="max-height: 80vh; overflow: scroll">
          <v-col
            v-for="(parcela, n) in parcelas"
            :key="n"
            class="d-flex child-flex"
            cols="12"
            sm="12"
            md="2"
            lg="2"
          >
            <v-card elevation="0" tile flat>
            <router-link :to="`/parcelaSeleccionada/${parcela.id}`">
              <v-img
                :src="`https://agenciaweb.mx/agronome/${parcela.imagenes[0].name}`"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
              <v-card-title>
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 200px; font-size: 14px"
                >
                  {{ parcela.parcela }}
                </span>
              </v-card-title>
              <v-card-subtitle>
                {{ parcela.estado }} <br /> 
                {{ parcela.vocacion }} <br />
                {{ parcela.area }} m<sup>2</sup><br />
                ${{ parcela.precio }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
    <Footer />
  </div>
</template>
<style scoped>
.test {
  border: solid 2px #f00;
}
.centrar {
  text-align: center;
}
.filtro {
  text-align: right;
  display: block;
}
.myImg {
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
}
.titulo {
  color: #ff670a;
}
.imgDiv {
  margin: 0;
  padding: 0;
}
.myBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
<script>
import firebase from "firebase/app";
import Footer from "../Footer.vue";
import { generateRandomId } from "../../tools/randomStrGus";
import { estados } from '../../tools/estados';
import axios from "axios";
import draggable from 'vuedraggable';
import moment from 'moment';
require("firebase/auth");
require("firebase/firestore");
export default {
  name: "Parcelas",
  components: {Footer, draggable,},
  data: () => ({
    valid: false,
    imagesError: false,
    isDisabled: false,
    dialog: false,
    idParcela: null,
    respuesta: null,
    enabled: true,
    list: [],
    dragging: false,
    parcela: {},
    responseParcelas: [],
    items: [
      {
        src: require("../../assets/FORMULARIO-TU-PLANTA-ENFERMA.png"),
      },
    ],
    snackbar: {
      open: false,
      text: "¡Texto copiado!",
      timeout: 2000,
    },
    filters: [
      {
        name: "UBICACIÓN",
        attr: "ubicacion",
        rule: function (a, b) {
          if (a.ubicacion < b.ubicacion) {
            return -1;
          }
          if (a.ubicacion > b.ubicacion) {
            return 1;
          }
          return 0;
        },
        current: 0,
        activo: 0,
      },
      {
        name: "TAMAÑO",
        attr: "tamano",
        rule: function (a, b) {
          if (a.tamano < b.tamano) {
            return -1;
          }
          if (a.tamano > b.tamano) {
            return 1;
          }
          return 0;
        },
        current: 0,
        activo: 0,
      },
      {
        name: "CULTIVO",
        attr: "cultivo",
        rule: function (a, b) {
          if (a.cultivo < b.cultivo) {
            return -1;
          }
          if (a.cultivo > b.cultivo) {
            return 1;
          }
          return 0;
        },
        current: 0,
        activo: 0,
      },
    ],
    minPrice: 0,
    maxPrice: 0,
    rules: {
      parcela: [
        (v) => !!v || "El titulo es requerido",
        (v) =>
          (v && v.length >= 10) ||
          "El título debe tener un mínimo de 10 caracteres",
      ],
      direccion: [
        (v) => !!v || "La dirección es requerida",
        (v) =>
          (v && v.length >= 30) ||
          "La dirección debe tener un mínimo de 30 caracteres",
      ],
      descripcion: [
        (v) => !!v || "La descripción es requerida",
        (v) =>
          (v && v.length >= 30) ||
          "La descripcion debe tener un mínimo de 30 caracteres",
      ],
      estado: [
        (v) => !!v || "El estado es requerido",
      ],
      area: [
        (v) => !!v || "El área es requerida",
      ],
      unidad: [
        (v) => !!v || "La unidad es requerida",
      ],
      precio: [
        (v) => !!v || "El precio es requerido",
      ],
      vocacion: [
        (v) => !!v || "La vocación del predio es requerida",
      ],
      tipoRiego: [
        (v) => !!v || "El tipo de riego es requerido",
      ],
      telefono: [
        (v) => !!v || "El teléfono es requerido",
        v => /(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/.test(v) || 'El teléfono no es válido, Ej. (999)-000-0000',
        
      ],
      correo: [
        (v) => !!v || "El correo es requerido",
        v => /.+@.+/.test(v) || 'El email no es válido, Ej. correo@dominio.com',
      ]
    },
    parcelas: [],
    parcelasBackup: [],
    nombresEstados: [],
    agrocostos: [],
    cultivosSinOrdenar: [],
    arrayImagenes: [],
    arrayImagenesGuardadas: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    userMail() {
      return localStorage.getItem("mail");
    },
    allow() {
      return localStorage.getItem("status");
    },
    coordenadas(){
      return (localStorage.getItem("latitude")) && (localStorage.getItem("longitude")) ? localStorage.getItem("latitude") + ", " + localStorage.getItem("longitude") : "sin datos";
    },
    cultivosOrdenados(){
      if(this.agrocostos.length > 0) {
        return this.cultivosSinOrdenar.sort();
      } else {
        return this.cultivosSinOrdenar;
      }
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    initialize() {
      this.nombresEstados = estados;
      this.getParcelas();
    },
    sortParcelas(attr, sentido) {
      if (sentido == 0) {
        this.parcelas = [...this.parcelasBackup];
        return;
      } else if (sentido == 1) {
        this.parcelasBackup = [...this.parcelas];
        this.parcelas.sort(function (a, b) {
          if (a[attr] < b[attr]) {
            return -1;
          }
          if (a[attr] > b[attr]) {
            return 1;
          }
          return 0;
        });
      } else if (sentido == 2) {
        this.parcelas.reverse();
        return;
      }
    },
    filterPriceParcelas() {
      if (parseInt(this.maxPrice) == 0) {
        this.parcelas = this.responseParcelas;
        return;
      }
      this.parcelas = this.responseParcelas.filter((parcela) => {
        if (
          parseInt(parcela.precio) >= parseInt(this.minPrice) &&
          parseInt(parcela.precio) <= parseInt(this.maxPrice)
        ) {
          return true;
        }
        return false;
      });
    },
    async getParcelas() {
      let db = firebase.firestore();
      await db
        .collection("parcelas")
        .where("eliminado", "==", false)
        .orderBy("fecha", "desc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) =>
            this.responseParcelas.push(doc.data())
          );
        });
      this.parcelas = this.responseParcelas;
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    openSnackbar(text, time) {
      this.snackbar.text = text;
      this.snackbar.time = time;
      this.snackbar.open = true;
    },
    checkMove: function(e) {
      //window.console.log("Future index: " + e.draggedContext.futureIndex);
    }
  },
};
</script>
