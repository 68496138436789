<template>
    <div>
        <v-col
            cols="12"
            sm="12"
            class="grey--text d-flex align-center justify-center py-6"
            >
            <center><h1>RÉNTALO, AHORRA Y REDUCE, S.A.P.I. DE C.V. <br/> AVISO DE PRIVACIDAD INTEGRAL</h1></center>
        </v-col>
        <v-col cols="12" sm="12" class="pa-10">
            <p>El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en
                la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y las
                disposiciones que emanan de ella o se relacionan con esta. Este Aviso de Privacidad aplica
                a la información personal recopilada sobre el Titular por <b>“RÉNTALO, AHORRA Y REDUCE,
                S.A.P.I. DE C.V.”</b> (en lo sucesivo <b>AgronoME</b>), en su carácter de Responsable, con domicilio
                ubicado en Homero 342, interior 601, Polanco IV Sección, Delegación Miguel Hidalgo,
                Código Postal 11550, Ciudad de México; el cual, a su vez, también servirá para oír y recibir
                notificaciones.
            </p>
            <p>El presente Aviso de Privacidad tiene los siguientes términos y condiciones:</p>
            <h2>1. DEFINICIONES:</h2>
            <br/>
            <p>Para los efectos de este Aviso y de acuerdo con la Ley Federal de Protección de Datos 
                Personales en Posesión de los Particulares, su Reglamento y los Lineamientos del 
                Aviso de Privacidad, se entenderá por:
            </p>
            <p><b>a) Aviso de Privacidad:</b> Documento físico y/o electrónico generado por el responsable,
                que es puesto a disposición del Titular, previo al tratamiento de sus datos personales.
            </p>
            <p><b>b) Datos Personales:</b> Cualquier información concerniente a una persona física
                identificada o identificable.</p>
            <p><b>c) Datos Personales Sensibles:</b> Son aquellos datos personales que afecten a la esfera más
                íntima de su Titular, o cuya utilización indebida pueda dar origen a discriminación o
                conlleve un riesgo grave para este. En particular, se consideran sensibles aquellos que
                puedan revelar aspectos de la persona, tales como: origen racial o étnico, estado de salud
                presente y futuro, información genética, creencias religiosas, filosóficas y morales,
                afiliación sindical, opiniones políticas, preferencia sexual, etc. </p>
            <p><b>d) Derechos ARCO:</b> Significa los derechos de Acceso, Rectificación, Cancelación y
                Oposición que, de conformidad con lo establecido por la Ley Federal de Protección de
                Datos Personales en Posesión de los Particulares y sujeto a las excepciones establecidas
                en esta y en este Aviso de Privacidad, cada Titular tiene, en relación con los Datos
                Personales recabados por el Responsable y/o sus Encargados y que se describen a continuación:</p>
            <p><b>Derecho de Acceso:</b> Es el derecho que tiene el Titular de conocer sobre los Datos
                Personales relacionados a su persona que obren en poder del Responsable de que se trate
                o de sus Encargados, asimismo a quién le han sido compartidos y con qué finalidad.
                </p>
            <p><b>Derecho a la Rectificación:</b> Cada Titular tiene derecho a que sus Datos Personales sean
               rectificados cuando estos sean inexactos o incompletos.</p>
            <p><b>Derecho de Cancelación:</b> Cada Titular tiene derecho a solicitar, en cualquier momento,
                que se supriman sus Datos Personales; lo cual, sucederá una vez que transcurra el periodo
                de bloqueo. El bloqueo implica la identificación y conservación de los Datos Personales
                una vez que se cumpla la finalidad para la cual fueron recabados y tiene como propósito
                determinar las posibles responsabilidades en relación con su tratamiento, hasta el plazo
                de prescripción legal o contractual de estas. Durante dicho periodo, sus Datos Personales
                no serán objeto de tratamiento y, una vez transcurrido este, se procederá con su
                cancelación en la base de datos o archivo correspondiente. Una vez cancelado el dato
                correspondiente, el Responsable notificará al Titular. En caso de que los Datos Personales
                hubiesen sido transmitidos con anterioridad a la fecha de rectificación o cancelación y
                sigan siendo tratados por terceros, el Responsable hará del conocimiento del tercero de
                que se trate, dicha solicitud de rectificación o cancelación, para que proceda a efectuarla
                también.</p>
            <p><b>Derecho de Oposición:</b> El Titular tiene en todo momento el derecho a solicitar, siempre
                que tenga una causa legítima, que el Responsable deje de tratar sus Datos Personales.</p>
            <p><b>e) Responsable:</b> Significa la persona física o moral que decide sobre el tratamiento de los
                Datos Personales del Titular, en este caso será <b>AgronoMe</b>.</p>
            <p><b>f) Encargado:</b> Significa la persona física o jurídica que sola o conjuntamente con otras
                trate Datos Personales por cuenta del Responsable.</p>
            <p><b>g) Titular:</b> Significa la persona física titular de los Datos Personales o autorizada para
                entregar Datos Personales de un tercero al Responsable conforme a las leyes aplicables.</p>
            <p><b>h) Transferencia:</b> Toda comunicación de datos realizada a persona distinta del
                Responsable o Encargado del tratamiento.</p>
            <p><b>i) Portal:</b> La referencia de un Portal en este documento, significa la referencia a
                www.agronome.red.</p>
            <p><b>j) Derechos ARCO:</b> Derechos de <b>A</b>cceso, <b>R</b>ectificación, <b>C</b>ancelación y <b>O</b>posición.</p>
            <p><b>k) Consentimiento Tácito:</b> Se entenderá que el Titular ha consentido en el tratamiento de
                sus datos, cuando habiéndose puesto a su disposición el Aviso de Privacidad, no
                manifieste su oposición.</p>
            <h2>2. CONSENTIMIENTO DEL TITULAR</h2>
            <br/>
            <p>Para efectos de lo dispuesto por el artículo 17 de la Ley Federal de Protección de Datos
                Personales en Posesión de los Particulares, el Titular manifiesta que: (i) el presente Aviso
                de Privacidad le ha sido dado a conocer por el Responsable, (ii) haber leído, entendido y
                acordado los términos expuestos en este Aviso de Privacidad, por lo que otorga su
                consentimiento respecto del tratamiento de sus Datos Personales para efectos de la Ley
                Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento
                y demás legislación aplicable. En caso de que los Datos Personales recopilados incluyan
                Datos Personales Sensibles o financieros, el Titular manifestará su consentimiento
                mediante la firma del contrato correspondiente, sea en formato impreso o utilizando
                medios electrónicos y sus correspondientes procesos para la formación del
                consentimiento, por ejemplo, a título enunciativo mas no limitativo: el suministro de
                Datos Personales a través de las ventanas de diálogo y la visualización y recorrido en
                pantalla de términos y condiciones. Estos actos constituyen el consentimiento expreso
                del Titular en términos del segundo párrafo del artículo 8 de la Ley Federal de Protección
                de Datos Personales en Posesión de los Particulares y demás legislación aplicable</p>
            <p>En caso de que el Titular no se oponga a los términos del presente Aviso de Privacidad
                dentro las siguientes 48 horas en que fue puesto a su disposición, se considerará acordado
                y consentido su contenido, en términos del tercer párrafo del artículo 8 de la Ley Federal
                de Protección de Datos Personales en Posesión de los Particulares. El consentimiento del
                Titular podrá ser revocado en cualquier momento por este sin que se le atribuyan efectos
                retroactivos, en los términos y conforme a los procedimientos establecidos más adelante
                para ello conforme a este Aviso de Privacidad.
                El Titular reconoce que no se requerirá de su consentimiento para el tratamiento de Datos
                Personales por parte del Responsable o de terceros en cualquiera de los casos señalados
                en el Artículo 10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
            <h2>3. OBJETO DEL AVISO DE PRIVACIDAD; INFORMACIÓN QUE SE RECABA Y FINALIDAD DE LOS DATOS PERSONALES.</h2>
            <br>
            <p>El presente Aviso de Privacidad tiene por objeto establecer los términos y condiciones en
                virtud de las cuales <b>AgronoMe</b> o el Encargado que designe, (i) recibirá y protegerá los
                Datos Personales del Titular, a efecto de proteger su privacidad y su derecho a la
                autodeterminación informativa, en cumplimiento de lo dispuesto en la Ley Federal de
                Protección de Datos Personales en Posesión de los Particulares y su Reglamento; (ii)
                utilizará los Datos Personales del Titular y (iii) realizará, en su caso, las transferencias de
                Datos Personales a terceros.</p>
            <p>El Responsable recolectará y tratará los Datos Personales del Titular; es decir, aquella
                información que puede identificarle de manera razonable a través de la recepción de
                documentos, sean en formato impreso y/o digital. </p>
            <h2>3.1. INFORMACIÓN QUE SE RECABA.</h2>
            <br/>
            <p>De manera enunciativa más no limitativa, entre de los datos que el Responsable puede
                recabar se encuentran: nombre y apellidos; imagen personal, fecha de nacimiento; edad,
                estado civil, nacionalidad, domicilio, sea particular, del trabajo, o fiscal; dirección de
                correo electrónico, personal o del trabajo; número telefónico, particular o del trabajo;
                número de teléfono celular; número de tarjeta de crédito, de débito o cuentas bancarias;
                clave del Registro Federal de Contribuyentes (RFC) y Clave Única de Registro de Población
                (CURP).</p>
            <p>La recolección de Datos Personales podrá efectuarse cuando: (i) el Titular se comunica vía
                telefónica con el Responsable o con sus Encargados; (ii) mediante la entrega directa al
                Responsable; (iii) a través de correos electrónicos; (iv) mediante la utilización de su sitio
                Web; (v) mediante el suministro voluntario de información a través de las ventanas de
                diálogo habilitadas en los sitios o (vi) mediante el uso de herramientas de captura
                automática de datos. Dichas herramientas le permiten recolectar la información que
                envía su navegador a dichos sitios Web, tales como el tipo de navegador que utiliza, el
                idioma de usuario, los tiempos de acceso, y la dirección de sitios Web que utilizó para
                acceder a los sitios del Responsable o Encargados. </p>
            <p>Dentro de la documentación que puede ser recolectada por <b>AgronoMe</b> para la 
                verificación de la identidad del Titular de los Datos Personales, se encuentran: (i) la
                credencial de elector; (ii) tarjeta de residencia temporal o permanente; (iii) cartilla de
                identidad del servicio militar nacional liberada; (iv) Constancia de Inscripción en el
                Registro Federal de Contribuyentes (RFC); (v) Clave Única de Registro de Población
                (CURP); (vi) comprobante de domicilio; (vii) boleta de pago de derechos de agua; (viii)
                boleta de pago del impuesto predial; (ix) boleta de pago del servicio de suministro de
                electricidad y (x) el informe especial de crédito emitido por una Sociedad de Información
                Crediticia. </p>
            <p>El Responsable también podrá recolectar Datos Personales de fuentes de acceso público
                y de otras fuentes disponibles en el mercado a las que el Titular pudo haber dado su
                consentimiento para compartir su información personal.
                </p>
            <h2>3.2. FINALIDAD DE LOS DATOS PERSONALES</h2>
            <br>
            <p>Los Datos Personales del Titular se recolectarán y tratarán con la finalidad de permitirle
                al Responsable y/o sus Encargados llevar a cabo las siguientes actividades y fines:</p>
            <p>a. La prestación de servicios de intermediación y mediación para la comercialización
                de bienes y servicios en plataformas virtuales. </p>
            <p>b. La prestación de servicios de intermediación, agencia, comisión y mediación en
                operaciones de comercio electrónico con el objetivo de facilitar la comunicación
                entre vendedores, compradores, donantes, donatarios, arrendador, arrendatario
                y proveedores de servicios de reparación de bienes muebles y proveedores de
                servicios de reciclaje.</p>
            <p>c. Organización de eventos, conferencias, exposiciones y ferias para la publicidad,
                promoción de los servicios ofrecidos y los demás relacionados en el ámbito
                tecnológico.</p>
            <p>d. Prestación de servicios de logística, mensajería, transporte y depósito de bienes.</p>
            <p>e. Integración y administración de bases de datos de nuestros clientes para cumplir
                con las actividades anteriores.</p>
            <p>Adicionalmente, se utilizará la información personal del Titular para los siguientes fines
                secundarios que no son necesarios para cumplir con la relación jurídica entre <b>AgronoME</b>
                y el Titular:</p>
            <li>Informes sobre nuestras actividades, servicios y programas.</li>
            <li>Informes sobre diversos temas de interés, tales como estudios internos sobre los intereses,
                comportamientos y demografía de los usuarios del Portal</li>
            <li>Para contactarlo vía correo electrónico o teléfono con el fin de compartirle
                noticias de interés respecto de <b>AgronoMe</b> (promociones, servicios, cursos,
                eventos, solicitud de productos, opiniones sobre los servicios y productos, etc.).</li>
            <br>
            <p>En caso de que el Titular no desee que sus datos personales se utilicen para los fines arriba
                mencionados, deberá indicarlo a continuación:
            </p>
            <li>Informes sobre nuestras actividades, servicios y programas. ( )</li>
            <li>Informes sobre diversos temas de interés. ( )</li>
            <li>Para contactarlo vía correo electrónico o teléfono con el fin de compartirle
                noticias de interés respecto de <b>AgronoMe</b> (promociones, servicios, cursos,
                eventos, etc.). ( )</li>
            <br>
            <p>La negativa para el uso de los datos personales para los fines anteriores no será motivo
                para negar al Usuario la prestación de los servicios y actividades que haya contratado con
                <b>AgronoMe</b>.</p>
            <p>De conformidad con el artículo 14, párrafo segundo de la Ley de Protección de Datos
                Personales en Posesión de los Particulares y el artículo 25, párrafo segundo de los
                Lineamientos del Aviso de Privacidad, en caso de que el presente Aviso de Privacidad no
                se haga del conocimiento del Titular de forma directa o personal, el mismo cuenta con 5
                días hábiles para que, de ser el caso, manifieste su negativa para el tratamiento de sus
                datos personales con respecto a los fines secundarios que no son necesarios para cumplir
                con la relación jurídica establecida con <b>AgronoME</b>.</p>
            <h2>4. USO DE COOKIES</h2>
            <br>
            <p>El correcto funcionamiento de los sitios de <b>AgronoME</b> y el de sus proveedores requieren
                de la habilitación de “cookies” en su navegador de Internet. Las "cookies" son pequeños
                archivos de datos transferidos por el sitio Web al disco duro de su computadora cuando
                navega por el sitio. Las cookies pueden ser de sesión o permanentes. Las cookies de sesión
                no se quedan en su computadora después de que usted cierra la sesión de su navegador,
                mientras que las cookies permanentes se quedan en las computadoras hasta que son
                eliminados o caducan. En la mayoría de los navegadores las cookies se aceptan
                automáticamente en virtud de su configuración predeterminada, usted puede ajustar las
                preferencias de su navegador para aceptar o rechazar las cookies. La desactivación de las 
                Última actualización: 11 de noviembre de 2021
                cookies puede inhabilitar diversas funciones de los sitios web de <b>AgronoME</b> o que no se
                muestren correctamente. En caso de que usted prefiera eliminar la información de las
                cookies enviadas por <b>AgronoME</b>, usted puede eliminar el archivo(s) al final de cada sesión
                del explorador. Información relevante puede ser consultada en los sitios de los principales
                navegadores de Internet. Uso de Web beacons (también conocidos como etiquetas de
                Internet, etiquetas de píxel y clear GIFs). <b>AgronoME</b> puede utilizar en sus sitios web y en
                sus correos electrónicos con formato HTML los Web beacons, solos o en combinación con
                las cookies, para recopilar información sobre el uso de los sitios web y su interacción con
                el correo electrónico. El Web beacon es una imagen electrónica, llamada de un solo píxel
                (1x1) o GIF que puede reconocer información que es procesada en su computadora, como
                el caso de las cookies, la hora y fecha en que el sitio y sus secciones son visualizados.
                Vínculos en los correos electrónicos de AgronMe. Los correos electrónicos que incluyen
                vínculos permiten a <b>AgronoME</b> saber si usted activó dicho vínculo y visitó la página web
                de destino, pudiendo esta información ser incluida en su perfil.</p>
            <p>Los Datos Personales que <b>AgronoME</b> obtiene de sus fuentes comerciales pueden
                utilizarse junto con los Datos Personales que recolecta a través de sus sitios web.</p>
            <h2>5. TRANSFERENCIA DE DATOS.</h2>
            <br>
            <p><b>AgronoME</b> no realiza transferencia de datos. No obstante, el Titular reconoce y acepta
                que el Responsable no requiere de autorización ni confirmación para realizar
                transferencias de Datos Personales nacionales o internacionales en los casos previstos en
                el Artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los
                Particulares o en cualquier otro caso de excepción previsto por la misma o la demás
                legislación aplicable</p>
            <h2>6. RESGUARDO Y SEGURIDAD DE LOS DATOS PERSONALES.</h2>
            <br>
            <p>El Responsable y/o sus Encargados conservarán los Datos Personales del Titular durante
                el tiempo que sea necesario para procesar sus solicitudes de información, productos y/o
                servicios, así como para mantener los registros contables, financieros y de auditoría en
                términos de la Ley Federal de Protección de Datos Personales en Posesión de los
                Particulares y de la legislación mercantil, fiscal y administrativa vigente. Los Datos
                Personales del Titular recolectados por el Responsable y/o sus Encargados se encontrarán
                protegidos por medidas de seguridad administrativas, técnicas y físicas adecuadas contra
                el daño, pérdida, alteración, destrucción o uso, acceso o tratamiento no autorizados, de 
                conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en
                Posesión de los Particulares y de la regulación administrativa derivada de la misma. No
                obstante lo señalado anteriormente, <b>AgronoME</b> no garantiza que terceros no autorizados
                no puedan tener acceso a los sistemas físicos o lógicos de los Titulares o del Responsable
                o en los documentos electrónicos y ficheros almacenados en sus sistemas. En
                consecuencia, <b>AgronoME</b> no será en ningún caso responsable de los daños y perjuicios
                que pudieran derivarse de dicho acceso no autorizado.</p>
            <h2>7. PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO.</h2>
            <br>
            <p>Para ejercer los Derechos ARCO, el Titular o su representante debe presentar una solicitud
                por escrito de acceso, rectificación, cancelación u oposición con la información y
                documentación siguiente:</p>
            <p>a) Nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su
                solicitud;</p>
            <p>b) Los documentos que acrediten su identidad (copia simple en formato impreso o
                electrónico de su credencial de elector, pasaporte o Tarjeta de residente temporal o
                permanente) o, en su caso, la representación legal del Titular (copia simple en formato
                impreso o electrónico de la carta poder simple con firma autógrafa del Titular, el
                mandatario y sus correspondientes identificaciones oficiales –(credencial de elector,
                pasaporte, Tarjeta de residente temporal o permanente);</p>
            <p>c) La descripción clara y precisa de los Datos Personales respecto de los que busca ejercer
                alguno de los Derechos ARCO, y</p>
            <p>d) Cualquier otro elemento o documento que facilite la localización de los Datos
                Personales del Titular.</p>
            <p>En el caso de las solicitudes de rectificación de Datos Personales, el Titular respectivo
                deberá también indicar las modificaciones a realizarse y aportar la documentación que
                sustente su petición.</p>
            <p>Para la recepción, registro y atención de las solicitudes para ejercer su derecho de acceso,
                rectificación, cancelación y oposición a sus Datos Personales, así como para limitar el uso
                o divulgación de sus datos, y los demás derechos previstos en la Ley Federal de Protección de 
                Datos Personales en Posesión de los Particulares contactar a:</p>
            <h2>Dirección de correo electrónico: hola@<b>AgronoME</b>.red.</h2>
            <br>
            <p>El Responsable o sus Encargados le responderán al Titular respectivo en un plazo máximo
                de veinte días hábiles, contados desde la fecha en que se recibió la solicitud de acceso,
                rectificación, cancelación u oposición, la determinación adoptada, a efecto de que, si
                resulta procedente, se haga efectiva la misma dentro de los quince días siguientes a la
                fecha en que se le comunica la respuesta al Titular. Tratándose de solicitudes de acceso a
                Datos Personales, el Responsable o sus Encargados procederán con su entrega previa
                acreditación de la identidad del solicitante o de su representante legal, según
                corresponda. Los plazos antes referidos podrán ser ampliados sólo en términos de la Ley
                Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
            <p>La entrega de los Datos Personales será gratuita, solamente le corresponderá cubrir los
                gastos justificados de envío o el costo de reproducción en copias u otros formatos.</p>
            <p>Para efectos de las solicitudes de cancelación de Datos Personales, además de lo
                dispuesto por el presente Aviso de Privacidad, se estará a lo dispuesto en el Artículo 26
                de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares,
                incluyendo los casos de excepción de cancelación de Datos Personales señalados.
                </p>
            <h2>8. CAMBIOS AL AVISO DE PRIVACIDAD.</h2>
            <br>
            <p><b>AgronoME</b>, se reserva el derecho de actualizar periódicamente el presente Aviso para
                reflejar los cambios de nuestras prácticas de información. Es responsabilidad del Titular
                revisar periódicamente el contenido del Aviso de Privacidad en el sitio
                www.<b>agronome</b>.red. El Responsable, entenderá que de no expresar lo contrario, significa
                que el Titular ha leído, entendido y acordado los términos expuestos, lo que constituye
                su consentimiento a los cambios establecidos en dichas actualizaciones respecto al
                tratamiento de sus Datos Personales para efectos de la Ley Federal de Protección de
                Datos Personales en Posesión de los Particulares y demás legislaciones aplicables</p>
        </v-col>
    </div>
</template>
<script>

export default {
    data: () => {

    }
}
</script>
